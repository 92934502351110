import RemediationDisabledEvent from 'domains/security/component/RemediationDisabledEvent';
import RemediationRestoredEvent from 'domains/security/component/RemediationRestoredEvent';
import RemediationPartialEvent from 'domains/security/component/RemediationPartialEvent';
import AccountWithRemediationPartial from 'domains/security/component/AccountWithRemediationPartial';
import AccountWithRemediationOff from 'domains/security/component/AccountWithRemediationOff';
import AccountWithRemediationOn from 'domains/security/component/AccountWithRemediationOn';

export const remediationEventRenderers = {
	disable_remediation_for_account: RemediationDisabledEvent,
	restore_remediation_for_account: RemediationRestoredEvent,
	partial_remediation_for_account: RemediationPartialEvent
};

export const accountRemediationCardRenderers = {
	PARTIAL: AccountWithRemediationPartial,
	OFF: AccountWithRemediationOff,
	ON: AccountWithRemediationOn
};
