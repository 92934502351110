import React from 'react';

const Toolbar = ({pageIcon, pageTitle, actions}) => {
	return <div style={{'width': '100%'}}>
		<h3 className='toolbar-header'>
			{pageIcon}
			{pageTitle}
		</h3>
		<div className='toolbar-action'>
			{actions}
		</div>
	</div>;
};

export default Toolbar;
