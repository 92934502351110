import axios from 'axios';
import moment from 'moment';
import {logout} from '../../../store/UserStore';
import aws4 from 'aws4';
import posthog from 'posthog-js';

const host = process.env.REACT_APP_API_HOST;
const apiUrl = `https://${host}`;

export const EXPIRATION_DATE_KEY = 'expirationDate';

class LambdaService {
	getAllAccountsRemediation() {
		return this._makeRequest({
			path: '/v1/remediationcontrol/accounts'
		});
	};

	restoreRemediation(awsAccountId) {
		posthog.capture("Change Account Remediation Strategy", {
			type: "RESTORE",
			awsAccountId
		})
		return this._makeRequest({
			path: `/v1/remediationcontrol/accounts/${awsAccountId}`,
			method: 'DELETE'
		});
	}

	partialRemediation(awsAccountId, compliancesRulesId, clientRequest, securityRational) {
		posthog.capture("Change Account Remediation Strategy", {
			type: "PARTIAL",
			awsAccountId,
			compliancesRulesId,
			clientRequest,
			securityRational
		})
		return this._makeRequest({
			path: `/v1/remediationcontrol/accounts/${awsAccountId}/rules`,
			method: 'POST',
			data: {aws_account_id: awsAccountId, rules_id: JSON.stringify(compliancesRulesId), client_request: clientRequest, security_rational: securityRational}
		});
	}

	disableRemediation(awsAccountId, clientRequest, securityRational) {
		posthog.capture("Change Account Remediation Strategy", {
			type: "DISABLE",
			clientRequest,
			securityRational
		})
		return this._makeRequest({
			path: `/v1/remediationcontrol/accounts/${awsAccountId}`,
			method: 'POST',
			data: {aws_account_id: awsAccountId, client_request: clientRequest, security_rational: securityRational}
		});
	}

	getAllEventsForAccount(awsAccountId) {
		return this._makeRequest({
			path: `/v1/events?service=compliance-exception-for-account&search=${awsAccountId}`,
		});
	}


	async _makeRequest(request) {
		await this._checkSession();

		if (request.method === 'POST' || request.method === 'PUT') {
			request.body = JSON.stringify(request.data);
			request.headers = {
				'content-type': 'application/json',
			};
		}

		const signedRequest = this._sign(request);
		return axios(signedRequest);
	}

	_sign(request) {
		if (!request.method) {
			request.method = 'GET';
		}
		if (!request.url) {
			request.url = `${apiUrl}${request.path}`;
		}

		request.region = 'eu-west-1';
		request.service = 'execute-api';
		request.host = host;

		const credentials = JSON.parse(localStorage.getItem('credentials'));
		let signedRequest = aws4.sign(request, {
			accessKeyId: credentials.accessKeyId,
			secretAccessKey: credentials.secretKey,
			sessionToken: credentials.sessionToken,
		});

		delete signedRequest.headers.Host;
		delete signedRequest.headers['Content-Length'];

		return signedRequest;
	}

	async _checkSession() {
		const expirationDateStr = localStorage.getItem(EXPIRATION_DATE_KEY);
		let needAuthentication = true;
		if (expirationDateStr) {
			needAuthentication = moment().isAfter(expirationDateStr);
		}

		if (needAuthentication) {
			logout();
		}
	}

}

const service = new LambdaService();
export {service};

