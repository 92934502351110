import React from "react";
import { Banner } from "horizon-components-react";
import "./newPortalBanner.scss";

const route =
  process.env.REACT_APP_URL_V2 ??
  "https://int.pcp-portal-public.1z21-cloudplatf-nonprod.aws.airbus-v.cloud/";

const navigateToPcpExplanationPage = () => {
  window.open(`${route}`, "_blank");
};

const NewPortalBanner = () => (
  <Banner status="info" title="New Portal is Live" closable={false}>
    <p>
      Some features have been disabled in this version of the Portal. Please go
      to the&nbsp;
      <button
        type="button"
        className="newPortalBannerButton"
        onClick={() => navigateToPcpExplanationPage()}
      >
        new Portal
      </button>
      &nbsp;to access them.
    </p>
  </Banner>
);

export default NewPortalBanner;
