import {Switch} from 'react-router-dom';
import React from 'react';
import PrivateRoute from 'components/shared/PrivateRoute';
import {Authorities} from 'utils/Authorities';
import AwsRolesPage from "../pages/AwsRolesPage";

const RoutesForProfile = () => {
    console.log('Mount RoutesForAwsRoles')
    return (
        <Switch>
            <PrivateRoute
                exact
                path="/profile/awsroles"
                component={AwsRolesPage}
                hasAnyAuthorities={[
                    Authorities.ROLE_ADMIN,
                    Authorities.ROLE_ADMIN_READONLY,
                    Authorities.ROLE_SECURITY,
                    Authorities.ROLE_USER
                ]}
            />
        </Switch>
    );
};

export default RoutesForProfile;
