import Grid from '@material-ui/core/Grid/index';
import React from 'react';
import RoutesForCompliance from './RoutesForCompliance';


const LayoutForCompliance = () => {
	return (
		<Grid container spacing={0}>
			<RoutesForCompliance/>
		</Grid>
	);
};

export default LayoutForCompliance;
