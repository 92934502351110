import React, {Component} from 'react';
import api from '../../../../services/ApiServices';
import ApiTable from '../../../../components/tables/ApiTable';
import {Link} from 'react-router-dom';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import {withContext} from '../../../../store/ContextStore';
import {getColumnFilterDirection} from 'utils/Utils';

class AccountApplications extends Component {


	constructor(props) {
		super(props);
		this.state = {
			identifier: props.match.params.accountId
		};
		this.apiTable = React.createRef();
		this.load = this.load.bind(this);
		this.columns = [
			{
				name: 'Aspire Code',
				key: 'aspireCode',
				filter: true,
				sort: getColumnFilterDirection('aspireCode', 'accounts-application-grid')
			},
			{
				name: 'Application name',
				key: 'name',
				cellRenderer: (applicationName, column, data) => {
					const aspireCode = encodeURIComponent(data.aspireCode);
					return (
						<Link to={`/applications/${aspireCode}`} onClick={() => {
							this.props.setAppId(aspireCode);
						}}>
							{applicationName}
						</Link>
					);
				},
				filter: true,
				sort: getColumnFilterDirection('name', 'accounts-application-grid')
			}
		];
	}

	async load() {
		return await api.getApplicationsForAccount(this.state.identifier);
	}

	render() {
		return (
			<>
				<h3>
					<ViewQuiltIcon className="icon-page"/>
                    Applications
				</h3>
				<hr/>
				<ApiTable
					id="accounts-application-grid"
					ref={this.apiTable}
					columns={this.columns}
					load={this.load}
				/>
			</>
		);
	}
}

export default withContext(AccountApplications);
