import {Select, TextArea, TextInput, Typeahead} from 'horizon-components-react';
import React from 'react';
import CreatableSelect from 'react-select/creatable';
import CheckboxWithChangingLabel from '../../../domains/administration/components/application/CheckboxWithChangingLabel';

export const DefaultTextInputAdapter = ({input, meta, ...rest}) => (
	<TextInput {...input} {...rest} value={input.value}/>
);

export const DefaultTextAreaAdapter = ({input, meta, ...rest}) => (
	<TextArea {...input} {...rest} value={input.value}/>
);

export const DefaultSelectAdapter = ({input, meta, ...rest}) => (
	<Select
		{...input}
		{...rest}
		handleChange={e => {
			input.onChange(e.currentTarget.value);
		}}
	/>
);

export const DefaultTypeaheadAdapter = ({
	input,
	meta,
	onChange,
	disabled,
	...rest
}) => (
	<Typeahead
		isDisabled={disabled}
		{...input}
		{...rest}
		onChange={e => {
			input.onChange(e);
			if (onChange) {
				onChange(e);
			}
		}}
	/>
);

export const DefaultCreateTypeaheadAdapter = ({
	input,
	meta,
	onChange,
	...rest
}) => (
	<CreatableSelect
		{...input}
		{...rest}
		onChange={e => {
			input.onChange(e);
			if (onChange) {
				onChange(e);
			}
		}}
	/>
);

export const DefaultCheckboxWithChangingLabelAdapter = ({
	input,
	...rest
}) => (
	<CheckboxWithChangingLabel
		{...input}
		{...rest}
		checked={input.value}
		handleChange={(e, checked) => {
			input.onChange(checked);
		}}
	/>
);