import { TableCell } from 'horizon-components-react';
import React, { Component } from 'react';

class EmptyFilter extends Component {
	render() {
		return <TableCell className="cell_filter" />;
	}
}

export default EmptyFilter;
