import { Menu, MenuItem, Tooltip } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import { Button, Dialog, DialogActions, DialogContent } from 'horizon-components-react';
import React, { Component } from 'react';
import { USER_KEY } from '../../services/ApiServices';
import { logout } from '../../store/UserStore';
import './Header.scss';


class UserHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			anchorEl: null,
			modalConfirmOpen: false,
		};

		this.handleMenu = this.handleMenu.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.toggleModalConfirm = this.toggleModalConfirm.bind(this);
	}

	handleMenu(event) {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose() {
		this.setState({ anchorEl: null });
	};

	toggleModalConfirm() {
		this.setState({
			modalConfirmOpen: !this.state.modalConfirmOpen,
			anchorEl: null
		});
	}

	render() {
		let user = localStorage.getItem(USER_KEY);
		if (user) {
			user = JSON.parse(user);
		}
		const { anchorEl } = this.state;
		const open = Boolean(anchorEl);
		return <>
            <Tooltip title={user.email} size={20}>
            	<PersonIcon fontSize="large" onClick={this.handleMenu} className="pcp-header-user" />
            </Tooltip>
            <Menu
            	id="menu-appbar"
            	anchorEl={anchorEl}
            	anchorOrigin={{
            		vertical: 'top',
            		horizontal: 'right',
            	}}
            	transformOrigin={{
            		vertical: 'top',
            		horizontal: 'right',
            	}}
            	open={open}
            	onClose={this.handleClose}
            >
            	<MenuItem onClick={this.toggleModalConfirm}>
            		<LogoutIcon />
                    Sign out
            	</MenuItem>
            </Menu>
            <Dialog
            	title="Sign out ?"
            	open={this.state.modalConfirmOpen}
            	onClose={this.toggleModalConfirm}
            	className={this.props.className}
            >
            	<DialogContent>Are you sure you want to leave Public Cloud Platform ?</DialogContent>
            	<DialogActions>
            		<Button onClick={this.toggleModalConfirm}>
            			<CancelIcon />
            			<span>Cancel</span>
            		</Button>
            		<Button
            			primary
            			onClick={logout}>
            			<LogoutIcon />
            			<span>Confirm</span>
            		</Button>
            	</DialogActions>
            </Dialog>
        </>;
	}
}
export default UserHeader;
