import {Route, Switch} from 'react-router-dom';
import React from 'react';
import PrivateRoute from 'components/shared/PrivateRoute';
import AccountPage from 'domains/account/pages/AccountPage';
import {Authorities} from 'utils/Authorities';
import AmisPage from 'domains/account/pages/AmisPage';
import ComplianceOverview from 'domains/account/pages/ComplianceOverview';
import ComplianceRulesStatusPage from 'domains/account/pages/ComplianceRulesStatusPage';
import NotFound from 'components/shared/NotFound';
import NotAllowed, {NotAllowedUrl} from 'components/shared/NotAllowed';
import MachinesSecurityMaintenanceConfigurationPage from 'domains/account/pages/MachinesSecurityMaintenanceConfigurationPage';
import AccountPlannedEventsPage from '../pages/AccountPlannedEventsPage';

function RoutesForAccount() {
	return (
		<Switch>
			<PrivateRoute
				exact
				path="/applications/:id/accounts/:accountName"
				component={AccountPage}
				hasAnyAuthorities={[
					Authorities.ROLE_USER,
					Authorities.ROLE_ADMIN,
					Authorities.ROLE_ADMIN_READONLY
				]}
			/>
			<PrivateRoute
				exact
				path="/applications/:id/accounts/:accountName/planned-events"
				component={AccountPlannedEventsPage}
				hasAnyAuthorities={[
					Authorities.ROLE_USER,
					Authorities.ROLE_ADMIN,
					Authorities.ROLE_ADMIN_READONLY
				]}
			/>
			<PrivateRoute
				exact
				path="/applications/:id/accounts/:accountName/amis"
				component={AmisPage}
				hasAnyAuthorities={[
					Authorities.ROLE_USER,
					Authorities.ROLE_ADMIN,
					Authorities.ROLE_ADMIN_READONLY
				]}
			/>
			<PrivateRoute
				exact
				path="/applications/:id/accounts/:accountName/compliance"
				component={ComplianceOverview}
				hasAnyAuthorities={[
					Authorities.ROLE_USER,
					Authorities.ROLE_ADMIN,
					Authorities.ROLE_ADMIN_READONLY
				]}
			/>
			<PrivateRoute
				exact
				path="/applications/:id/accounts/:accountName/compliance-detail"
				component={ComplianceRulesStatusPage}
				hasAnyAuthorities={[
					Authorities.ROLE_USER,
					Authorities.ROLE_ADMIN,
					Authorities.ROLE_ADMIN_READONLY
				]}
			/>
			<PrivateRoute
				exact
				path="/applications/:id/accounts/:accountName/maintenance-configuration"
				component={MachinesSecurityMaintenanceConfigurationPage}
				hasAnyAuthorities={[
					Authorities.ROLE_USER,
					Authorities.ROLE_ADMIN,
					Authorities.ROLE_ADMIN_READONLY
				]}
			/>
			<Route exact path={NotAllowedUrl} component={NotAllowed}/>
			<Route component={NotFound}/>
		</Switch>
	);
}

export default RoutesForAccount;
