import PeopleIcon from "@material-ui/icons/People";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Button } from "horizon-components-react";
import React, { Component } from "react";
import ApiTable from "../../../components/tables/ApiTable";
import api from "../../../services/ApiServices";
import { hasAnyAuthority } from "../../../store/UserStore";
import { Authorities } from "../../../utils/Authorities";
import { Link } from "react-router-dom";
import CreateTeam from "../components/team/CreateTeam";
import { getColumnFilterDirection } from "utils/Utils";

class AdminTeamsPage extends Component {
  constructor(props) {
    super(props);
    this.apiTable = React.createRef();

    this.columns = [
      {
        name: "Team name",
        key: "name",
        filter: true,
        cellRenderer: (teamName, column, team) => {
          const teamIdUrlEncoded = encodeURIComponent(team.id);
          return <Link to={`/teams/${teamIdUrlEncoded}`}>{teamName}</Link>;
        },
        sort: getColumnFilterDirection("name", "admin-teams-grid"),
      },
      {
        name: "Description",
        key: "description",
        filter: true,
        sort: getColumnFilterDirection("description", "admin-teams-grid"),
      },
    ];

    this.load = this.load.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  async load() {
    return await api.getAllTeams();
  }

  async delete(team) {
    return await api.deleteTeam(team.id);
  }

  refresh() {
    this.apiTable.current.load();
  }

  render() {
    const readOnly = hasAnyAuthority(Authorities.ROLE_ADMIN_READONLY);

    return (
      <>
        <div className="float-right">
          {!readOnly && (
            <CreateTeam onConfirm={this.refresh} className="mr-1" />
          )}
          <Button primary size="small" withIcon="true" onClick={this.refresh}>
            <RefreshIcon /> Refresh
          </Button>
        </div>
        <h2>
          <PeopleIcon className="icon-page" /> Teams Management
        </h2>
        <hr />
        <ApiTable
          id="admin-teams-grid"
          ref={this.apiTable}
          columns={this.columns}
          actionsWidth="100px"
          decommissioned={false}
          load={this.load}
          delete={this.delete}
        >
          {!readOnly && <CreateTeam onConfirm={this.refresh} />}
        </ApiTable>
      </>
    );
  }
}

export default AdminTeamsPage;
