import React from 'react';
import RoutesForTeam from './RoutesForTeam';

const LayoutForTeam = () => {
	return (
		<RoutesForTeam/>
	);
};

export default LayoutForTeam;
