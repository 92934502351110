import React, {Component} from 'react';
import api from '../../../../services/ApiServices';
import ApiTable from '../../../../components/tables/ApiTable';
import {withContext} from '../../../../store/ContextStore';
import {getColumnFilterDirection} from 'utils/Utils';
import Filter1Icon from '@material-ui/icons/Filter1';
import {Button, Loading} from "horizon-components-react";
import BackIcon from "@material-ui/icons/Reply";
import BuildIcon from '@material-ui/icons/Build';

class UserOneLoginIamRoles extends Component {

    constructor(props) {
        super(props);
        this.state = {fixOnGoing: false};
        this.apiTable = React.createRef();
        this.load = this.load.bind(this);

        this.columns = [
            {
                name: 'Iam Role',
                key: 'iamRole',
                filter: true,
                sort: getColumnFilterDirection('name', 'user-awsroles-grid')
            }
        ];
        this.fixOneLoginConfiguration = this.fixOneLoginConfiguration.bind(this)
    }

    async fixOneLoginConfiguration() {
        this.setState({fixOnGoing: true})
        await api.fixUserOneloginIamRoles(this.props.match.params.userId)
        this.setState({fixOnGoing: false})
        this.apiTable.current.load();
    }

    async load() {
        const userId = this.props.match.params.userId;
        return api.getUserOneloginIamRoles(userId);
    }


    render() {
        return (
            <>
                <div className="float-right">
                        <Button disabled={this.state.fixOnGoing} primary onClick={this.fixOneLoginConfiguration} className="mr-1" size="small">
                            <BuildIcon/>
                            Fix Platform
                        </Button>
                        <Button primary size="small" onClick={()=>{this.props.history.push('/admin/users')}}>
                            <BackIcon/>
                        </Button>
                </div>
                <h2>
                    <Filter1Icon className="icon-page"/>
                    Onelogin configuration
                </h2>
                <hr/>
                <ApiTable
                    id="user-role-grid"
                    ref={this.apiTable}
                    columns={this.columns}
                    load={this.load}
                />
            </>
        );
    }
}

export default withContext(UserOneLoginIamRoles);
