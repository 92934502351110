import React, { Component } from 'react';
import Scheduler, {SchedulerData, ViewTypes, DATE_FORMAT} from 'react-big-scheduler'
import withDragDropContext from './withDndContext';
import 'react-big-scheduler/lib/css/style.css'
import moment from 'moment'
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';

class PlannedEventsCalendar extends Component {
	constructor(props) {
		super(props);

		// Prepate the scheduler
		const scheduler = new SchedulerData(new moment().tz('UTC').format(DATE_FORMAT), ViewTypes.Month, false, false, {
			startResizable: false,
			endResizable: false,
			movable: false,
			creatable: false,
			schedulerWidth: '79%',
			besidesWidth: 0,
			resourceName: props.resourceName,
			views: [
				{viewName: 'Week', viewType: ViewTypes.Week, showAgenda: false, isEventPerspective: false},
				{viewName: 'Month', viewType: ViewTypes.Month, showAgenda: false, isEventPerspective: false}
			],

			monthResourceTableWidth: 230,
			monthCellWidth: 100,
			yearCellWidth: 100,

			nonAgendaOtherCellHeaderFormat: 'ddd D MMM'

		}, moment.locale('en-GB'));
		scheduler.localeMoment.locale('en');
		scheduler.setEvents(props.events);
		scheduler.setResources(props.resources);

		this.state = {
			events: props.events,
			resources: props.resources,
			loading: props.loading,
			scheduler
		};
	}

	componentDidUpdate(prevProps) {
		if(prevProps.loading !== this.props.loading) {
			this.setState({loading: this.props.loading});
		}
		if(prevProps.events !== this.props.events) {
			this.state.scheduler.setEvents(this.props.events);
			this.setState({events: this.props.events});
		}
		if(prevProps.resources !== this.props.resources) {
			this.state.scheduler.setResources(this.props.resources);
			this.setState({resources: this.props.resources});
		}
	}

	previousClick = (schedulerData)=> {
		schedulerData.prev();
		schedulerData.setEvents(this.state.events);
		this.setState({
			scheduler: schedulerData
		})
	}

	nextClick = (schedulerData)=> {
		schedulerData.next();
		schedulerData.setEvents(this.state.events);
		this.setState({
			scheduler: schedulerData
		})
	}

	onViewChange = (schedulerData, view) => {
		schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
		schedulerData.setEvents(this.state.events);
		this.setState({
			scheduler: schedulerData
		})
	}

	onSelectDate = (schedulerData, date) => {
		schedulerData.setDate(date);
		schedulerData.setEvents(this.state.events);
		this.setState({
			scheduler: schedulerData
		})
	}



	render() {
		return <>

				{this.state.loading &&
					<div style={{textAlign: 'center'}}><CircularProgress/></div>
				}
				{!this.state.loading &&
					<Scheduler schedulerData={this.state.scheduler}
							   prevClick={this.previousClick}
							   nextClick={this.nextClick}
							   onSelectDate={this.onSelectDate}
							   onViewChange={this.onViewChange}
							   eventItemPopoverTemplateResolver={(schedulerData, eventItem, title, start, end) => {
								   return (
									   <div style={{width: '500px'}}>
										   <h5>{title}</h5>
										   <p><b>{start.format("YYYY/MM/DD")} -> {end.format("YYYY/MM/DD")}</b></p>
										   <p>{eventItem.description}</p>
										   {eventItem.displayInfo.Tags && eventItem.displayInfo.Tags.map((value) => {
											   return <Chip size="small" color='primary' style={{backgroundColor:value.Color}} label={value.DisplayName} />
										   })}
									   </div>
								   );
							   }}/>
				}
		</>;
	}
}

export default withDragDropContext(PlannedEventsCalendar);
