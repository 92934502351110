import { TableCell, TableRow } from 'horizon-components-react';
import React from 'react';

const empty = customMsg => {
	let msg = 'No result';
	if (customMsg) {
		msg = customMsg;
	}
	return msg;
};

export const emptyTable = (customMsg, colSpan) => (
	<TableRow key="empty">
		<TableCell colSpan={colSpan}>
			<div className="m-2 text-center">{empty(customMsg)}</div>
		</TableCell>
	</TableRow>
);
