import React from 'react';
import {Link} from 'react-router-dom';
import './AccountCard.scss';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import Tooltip from '@material-ui/core/Tooltip';
import {hasAnyAuthority} from 'store/UserStore';
import {Authorities} from 'utils/Authorities';
import {withContext} from 'store/ContextStore';
import DetachAccountFromApplication from 'domains/application/components/account/DetachAccountFromApplication';
import ThumbUp from '@material-ui/icons/ThumbUp';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbsUpDown from '@material-ui/icons/ThumbsUpDown';
import {Strategy} from 'domains/security/component/RemediationStrategies/RemediationStrategySelector';

function AccountCard(props) {

	const aspireCode = props.appId;
	const accountName = props.name;
	const isAdmin = hasAnyAuthority(Authorities.ROLE_ADMIN);

	const putAccountNameIntoContext = () => {
		props.setAccountType(props.type);
		props.setAccountName(props.name);
	};

	const getCorrectThumb = remediationStrategy => {
		if (remediationStrategy === Strategy.ON) {
			return <Tooltip title={'Account fully secured by PCP'}><ThumbUp style={{color: 'green'}}/></Tooltip>;
		} else if (remediationStrategy === Strategy.OFF) {
			return <Tooltip title={'Account not secured by PCP'}><ThumbDown
				style={{color: 'red'}}/></Tooltip>;
		} else if (remediationStrategy === Strategy.PARTIAL) {
			return <Tooltip title={'Account partially secured by PCP'}><ThumbsUpDown
				style={{color: 'yellow'}}/></Tooltip>;
		}
	};

	const detach = isAdmin
		? (
			<DetachAccountFromApplication onConfirm={props.onConfirm} accountSelected={accountName}/>
		) : null

	return (
		<Card>
			<Button className='accountButton'>
				<Link to={`/applications/${aspireCode}/accounts/${accountName}`}
					onClick={putAccountNameIntoContext}>
					<CardContent className="text-center">
						<h5 className='accountCardContent'>
							<div className='accountCardName'>{accountName}</div>
							<div className='text-muted accountCardType'>{props.type}</div>
						</h5>
					</CardContent>
				</Link>
			</Button>
			<CardActions style={{display: 'contents'}}>
				<Tooltip title={'AWS account id'}><span
					style={{paddingLeft: '10px', fontFamily: 'monospace'}}>{props.accountId}</span></Tooltip>
				<span style={{marginLeft: '75px'}}>{getCorrectThumb(props.remediation_strategy)}</span>
				{detach}
			</CardActions>
		</Card>
	);
}

export default withContext(AccountCard);
