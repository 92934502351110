import {Route, Switch} from 'react-router-dom';
import React from 'react';
import PrivateRoute from 'components/shared/PrivateRoute';
import RulesPage from 'domains/compliance/pages/RulesPage';
import {Authorities} from 'utils/Authorities';
import NotAllowed, {NotAllowedUrl} from 'components/shared/NotAllowed';
import NotFound from 'components/shared/NotFound';
import LoadComplianceRulePageWithRouter from 'domains/compliance/pages/RulePageWithoutContext';

const RoutesForCompliance = () => {
	console.log('Mount RoutesForCompliance')
	return (
		<Switch>
			<PrivateRoute
				exact
				path="/compliancerules"
				component={() => <RulesPage/>}
				hasAnyAuthorities={[
					Authorities.ROLE_ADMIN,
					Authorities.ROLE_ADMIN_READONLY,
					Authorities.ROLE_SECURITY,
					Authorities.ROLE_USER
				]}
			/>
			<PrivateRoute
				exact
				path="/compliancerules/:id"
				component={() => <LoadComplianceRulePageWithRouter context='technical'/>}
				hasAnyAuthorities={[
					Authorities.ROLE_ADMIN,
					Authorities.ROLE_ADMIN_READONLY,
					Authorities.ROLE_SECURITY,
					Authorities.ROLE_USER
				]}
			/>
			<PrivateRoute
				exact
				path="/compliancerules/rule_id/:id"
				component={() => <LoadComplianceRulePageWithRouter context='business'/>}
				hasAnyAuthorities={[
					Authorities.ROLE_ADMIN,
					Authorities.ROLE_ADMIN_READONLY,
					Authorities.ROLE_SECURITY,
					Authorities.ROLE_USER
				]}
			/>
			<Route exact path={NotAllowedUrl} component={NotAllowed}/>
			<Route component={NotFound}/>
		</Switch>
	);
};

export default RoutesForCompliance;
