import {GridCol, GridContainer, GridRow, Loading} from 'horizon-components-react';
import React, {Component} from 'react';
import api from '../../../services/ApiServices';
import {withContext} from '../../../store/ContextStore';
import TeamCard from '../components/team/TeamCard';

class MyTeamsPage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			teams: [],
			loading: true
		};
	}

	async componentDidMount() {
		this.setState({
			loading: true
		});
		try {
			const teams = (await api.getCurrentUserTeams()).data;
			this.setState({
				teams: teams,
				loading: false
			});
		} catch (e) {
			console.error(e);
			this.setState({
				loading: false
			});
		}
	}

	renderTeam(team) {
		return (
			<GridCol key={team.id} xs={12} md={6} lg={4}>
				<TeamCard team={team}/>
			</GridCol>
		);
	}

	renderTeams() {
		const userHasTeams = this.state.teams && this.state.teams.length > 0;
		if(userHasTeams) {
			return this.state.teams.map(a => {
				return this.renderTeam(a);
			});
		} else {
			return <p style={{fontWeight: 'bolder', fontSize: 'larger'}}>You are not managing any team.</p>;
		}
	}

	render() {
		if (this.state.loading) {
			return <Loading>Loading teams...</Loading>;
		}

		return (
			<GridContainer>
				<GridRow justify="center">{this.renderTeams()}</GridRow>
			</GridContainer>
		);
	}
}

export default withContext(MyTeamsPage);
