import Grid from '@material-ui/core/Grid/index';
import React from 'react';
import RoutesForProfile from './RoutesForProfile';
import {Card, CardContent} from "horizon-components-react";
import ProfileMenu from "./ProfileMenu";


const LayoutForProfile = () => {
return (
		<Grid container spacing={0}>
			<Grid item xs={12} md={3} lg={2}>
				<ProfileMenu/>
			</Grid>
			<Grid item xs={12} md={9} lg={10}>
				<Card>
					<CardContent>
						<RoutesForProfile/>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};

export default LayoutForProfile;
