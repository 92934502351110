import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './AppCard.scss';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import AppIcon from '@material-ui/icons/Web';
import {withContext} from '../../../../store/ContextStore';

class AppCard extends Component {

	constructor(props) {
		super(props);
		this.putAspireCodeIntoContext = this.putAspireCodeIntoContext.bind(this);
	}


	putAspireCodeIntoContext() {
		this.props.setAppId(this.props.app.aspireCode);
	}

	render() {
		const encodedAspireCode = encodeURIComponent(this.props.app.aspireCode);
		return (
			<Card>
				<Link to={`/applications/${encodedAspireCode}`} onClick={this.putAspireCodeIntoContext}>
					<Button className='appButton'>
						<AppIcon className='appIcon'/>
						<CardContent className="text-center">
							<div className='appContent'>
								<div className='appName'>{this.props.app.name}</div>
								<div className='text-muted appAspireCode'>{this.props.app.aspireCode}</div>
							</div>
						</CardContent>
					</Button>
				</Link>
			</Card>
		);
	}
}

export default withContext(AppCard);
