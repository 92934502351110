import RulesIcon from "@material-ui/icons/AssignmentTurnedIn";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./RulesPage.scss";
import { getColumnFilterDirection } from "utils/Utils";
import Toolbar from "domains/compliance/components/Toolbar";
import { Authorities } from "utils/Authorities";
import { hasAnyAuthority } from "store/UserStore";
import api from "services/ApiServices";
import CreateRule from "domains/compliance/components/rule/CreateRule";
import ApiTable from "components/tables/ApiTable";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Checkbox } from "horizon-components-react";

const LIST_IDENTIFIER = "compliance-rules";
const PERMISSION = {
  toDeleteRule: [Authorities.ROLE_ADMIN, Authorities.ROLE_SECURITY],
  toCreateRule: [Authorities.ROLE_ADMIN, Authorities.ROLE_SECURITY],
};

function RulesPage() {
  const hasNoRightToCreateRule = !hasAnyAuthority(PERMISSION.toCreateRule);
  const apiTable = React.createRef();
  const columns = [
    {
      name: "ID",
      key: "complianceId",
      filter: true,
      cellRenderer: (undefinedVariable, column, data) => {
        const complianceId = encodeURIComponent(data.id);
        const to = { pathname: `/compliancerules/${complianceId}` };
        return <Link to={to}>{data.complianceId}</Link>;
      },
      sort: getColumnFilterDirection("complianceId", LIST_IDENTIFIER),
    },
    {
      name: "Service",
      key: "service",
      filter: true,
      filterType: "checkedList",
      sort: getColumnFilterDirection("service", LIST_IDENTIFIER),
    },
    {
      name: "Status",
      key: "status",
      filter: true,
      filterType: "checkedList",
      sort: getColumnFilterDirection("status", LIST_IDENTIFIER),
    },
    {
      name: "Severity",
      key: "severity",
      addClass: true,
      filter: true,
      filterType: "checkedList",
      sort: getColumnFilterDirection("severity", LIST_IDENTIFIER),
    },
    {
      name: "Description",
      key: "description",
      filter: true,
      sort: getColumnFilterDirection("description", LIST_IDENTIFIER),
    },
  ];

  const [isFirstRun, setIsFirstRun] = useState(true);
  const [
    onlyShowRulesWithAutomatedRemediation,
    setOnlyShowRulesWithAutomatedRemediation,
  ] = useState(false);
  const [showAllRules, setShowAllRules] = useState(false);
  React.useEffect(() => {
    if (!isFirstRun) {
      refreshRules();
    } else {
      setIsFirstRun(false);
    }
  }, [showAllRules, onlyShowRulesWithAutomatedRemediation]);

  const deleteRule = async (rule) =>
    await api.ComplianceRules.deleteComplianceRule(rule);
  const refreshRules = () => apiTable.current.load();
  const loadRules = async () => {
    const response = await api.ComplianceRules.getAllComplianceRules();
    let newItems = response.data;
    if (onlyShowRulesWithAutomatedRemediation) {
      newItems = newItems.filter((row) => row.hasAutomatedRemediation);
    }
    if (!showAllRules) {
      newItems = newItems.filter(
        (row) => row.status === "implemented" || row.status === "validated"
      );
    }
    response.data = newItems;
    return response;
  };

  return (
    <>
      <Toolbar
        pageIcon={<RulesIcon />}
        pageTitle={"Compliance rules"}
        actions={
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={onlyShowRulesWithAutomatedRemediation}
                  onChange={(e) =>
                    setOnlyShowRulesWithAutomatedRemediation(e.target.checked)
                  }
                  name="only-show-rules-with-auto-remediation"
                />
              }
              label="Only show rules with automated remediation"
              style={{ paddingLeft: "12px" }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showAllRules}
                  onChange={(e) => setShowAllRules(e.target.checked)}
                  name="show-all-rules"
                />
              }
              label="Show all rules (rejected...)"
              style={{ paddingLeft: "12px" }}
            />
            <CreateRule
              disabled={hasNoRightToCreateRule}
              onConfirm={refreshRules}
            />
          </>
        }
      />
      <div className="compliance-rule-api-table">
        <ApiTable
          id={LIST_IDENTIFIER}
          ref={apiTable}
          decommissioned={false}
          columns={columns}
          load={loadRules}
          delete={deleteRule}
          isAllowedToDelete={hasAnyAuthority(PERMISSION.toDeleteRule)}
        ></ApiTable>
      </div>
    </>
  );
}

export default RulesPage;
