import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import {Form} from 'react-final-form';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Send';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import {changeStrategyModalClosed, loadAllRemediationAccounts} from 'state/actions';
import {useDispatch} from 'react-redux';
import {REMEDIATION_STRATEGY_MODIFIED, REMEDIATION_STRATEGY_MODIFYING} from 'state/actions-types';
import {service} from 'domains/security/api/LambdaService';

export function OnValidation({account}) {
	const dispatch = useDispatch();

	const handleOnSubmit = async () => {
		dispatch({type: REMEDIATION_STRATEGY_MODIFYING});
		await service.restoreRemediation(account.aws_account_id)
			.then(() => dispatch({type: REMEDIATION_STRATEGY_MODIFIED}))
			.then(() => dispatch(changeStrategyModalClosed()))
			.then(() => dispatch(loadAllRemediationAccounts()));
	};
	return <>
		<Alert icon={<CheckCircleOutlinedIcon/>} variant='filled' severity='error'>
			<Typography>
                You are about to <strong>RESTORE</strong> remediation for the account {account.aws_account_name}.<br/>
                An account with remediation can have some of his AWS resources MODIFIED or DELETED
			</Typography>
		</Alert>
		<br/>
		<Form
			onSubmit={handleOnSubmit}
			render={({
				handleSubmit,
				submitting
			}) => (
				<form onSubmit={handleSubmit}>
					<Button
						size={'large'}
						type='submit'
						disabled={submitting}
						variant="contained"
						color="primary"
						startIcon={<SaveIcon/>}>
                        Save
					</Button>
				</form>
			)}
		/>
	</>;
}
