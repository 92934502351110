import React, {useEffect, useState} from 'react';
import { withContext } from 'store/ContextStore';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {MuiPickersUtilsProvider, TimePicker} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Button } from 'horizon-components-react';
import { hasAnyAuthority } from 'store/UserStore';
import { Authorities } from 'utils/Authorities';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment-timezone';
import api from '../../../services/ApiServices';
import MaintenanceCampaignIcon from '@material-ui/icons/Event';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';

const timezoneOptions = moment.tz.names().map((timezone) => {
    return timezone
});

const MachinesSecurityMaintenanceConfigurationPage = ({appId, accountName}) => {
    const [configuration, setConfiguration] = useState({
        'start_day': 'THURSDAY',
        'start_hour': 22,
        'timezone': 'Europe/Paris'
    });

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const readOnly = hasAnyAuthority(Authorities.ROLE_ADMIN_READONLY);

    useEffect(() => {
        loadConfiguration();
    }, []);
    const loadConfiguration = () => api.MachinesSecurityMaintenance.getMachineSecurityMaintenanceConfigurationForAccountName(appId, accountName).then(r => r.data)
        .then(conf => {
            setConfiguration(conf);
        }).finally(() => {
            setLoading(false);
        });

    const saveConfiguration = () => {
        setSaving(true);
        api.MachinesSecurityMaintenance.saveMachineSecurityMaintenanceConfigurationForAccountName(appId, accountName, configuration).finally(() => {
            setSaving(false);
        });
    }

    return <>
        <div className="float-right">
            {!readOnly && (
                <Button primary size="medium" onClick={saveConfiguration} disabled={saving}>
                    {saving && <CircularProgress size={14} />}
                    {!saving && 'Save'}
                </Button>
            )}
        </div>

        <h2>
            <MaintenanceCampaignIcon className="icon-page"/> Machines security maintenance
        </h2>
        <hr/>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Alert severity='warning' variant='outlined'>
                    <AlertTitle><b>Disclaimer</b></AlertTitle>
                    <p>
                        In the context of security compliance, PCP provides an automated patching feature of your EC2 instances. <br/>
                        Performing OS/package updates may have side-effects on your running software, that's why we let you define a specific application time.<br/>
                        If the default configuration does not fit your business needs, you can customise below the time and day of the week when we are allowed to perform actions on your EC2 instances.<br/>
                        It includes:
                        <ul>
                            <li>OS critical security patches applied on your EC2 instances</li>
                            <li>Snapshots of all EC2 instances in your account, 24h before the action</li>
                        </ul>
                    </p>
                </Alert>
            </Grid>

            {loading &&
            <Grid item xs={12}>
                <div style={{textAlign: 'center'}}> <CircularProgress /></div>
            </Grid>
            }

            {!loading &&
            <>
                <Grid item xs={12} sm={4}>
                    <FormControl style={{width: '100%'}}>
                        <InputLabel>Day of the week *</InputLabel>
                        <Select
                            required
                            disabled={readOnly}
                            value={configuration.start_day}
                            onChange={event => {
                                setConfiguration({ ...configuration, start_day: event.target.value })
                            }}
                        >
                            <MenuItem value={'MONDAY'}>Monday</MenuItem>
                            <MenuItem value={'TUESDAY'}>Tuesday</MenuItem>
                            <MenuItem value={'WEDNESDAY'}>Wednesday</MenuItem>
                            <MenuItem value={'THURSDAY'}>Thursday</MenuItem>
                            <MenuItem value={'FRIDAY'}>Friday</MenuItem>
                            <MenuItem value={'SATURDAY'}>Saturday</MenuItem>
                            <MenuItem value={'SUNDAY'}>Sunday</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <MuiPickersUtilsProvider utils={MomentUtils} >
                        <TimePicker
                            autoOk
                            fullWidth
                            required
                            disabled={readOnly}
                            value={moment({'hour': configuration.start_hour, 'minute': 0, 'second': 0})}
                            views={['hours']}
                            label={"Time of the day (" + configuration.timezone + ")"}
                            ampm={false}
                            onChange={(value) => {
                                setConfiguration({ ...configuration, start_hour: value.hours() });
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Autocomplete
                        options={timezoneOptions}
                        onChange={(event, newValue) => setConfiguration({ ...configuration, timezone: newValue })}
                        required
                        style={{ width: '100%' }}
                        disabled={readOnly}
                        renderInput={(params) => <TextField {...params} label="Timezone *" />}
                        value={configuration.timezone}
                        disableClearable
                    />
                </Grid>

            </>
            }
        </Grid>
    </>;

};

export default withContext(MachinesSecurityMaintenanceConfigurationPage);
