import React, {Component} from 'react';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import LoginSystem from 'components/login/LoginSystem';
import {CREDENTIALS_KEY} from 'services/ApiServices';
import moment from 'moment';
import {logout} from 'store/UserStore';
import PCPPortalApp from './pages/PCPPortalApp';

class App extends Component {

	tokenRemindersTimeout = null;

	constructor(props) {
		super(props);
		this.state = {
			isLoggedIn: this.isLoggedIn(),
			displayAlert: false
		};
		this.refreshLoginState = this.refreshLoginState.bind(this);
	}

	isLoggedIn() {
		const isLoggedIn = localStorage.getItem(CREDENTIALS_KEY) != null;
		if (!isLoggedIn) {
			localStorage.clear();
		}
		return isLoggedIn;
	}

	refreshLoginState() {
		this.setState({
			isLoggedIn: this.isLoggedIn()
		});
	}

	static tokenReminders() {
		const now = moment.utc();
		const expirationDateInUnix = localStorage.getItem('expirationDate');
		const remainingTime = moment(expirationDateInUnix).diff(now, 'minutes');
		if (remainingTime <= 0) {
			logout();
		}
	}

	componentDidMount() {
		// Check every seconds if we are still logged in
		this.tokenRemindersTimeout = setInterval(() => App.tokenReminders(), 3000)
		// And perform an initial check
		App.tokenReminders()
	}

	componentWillUnmount() {
		clearInterval(this.tokenRemindersTimeout);
	}

	render() {
		let appVersion = process.env.REACT_APP_VERSION;

		if (!this.state.isLoggedIn) {
			return (<>
				<div className='login-pages'>
					<ToastContainer/>
					<div className="connexion-app">
						<LoginSystem refreshLoginState={this.refreshLoginState}/>
					</div>
					<div style={{position: 'absolute', bottom: 0, fontSize: 10}}>{appVersion}</div>
				</div>
			</>
			);
		}
		return <PCPPortalApp/>;
	}
}

export default App;
