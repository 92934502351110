import React, {useEffect, useState} from 'react';
import api from 'services/ApiServices';
import Grid from '@material-ui/core/Grid';
import TableWithSelectionAndFiltering from '../components/ec2-instances-manual-patching/TableWithSelectionAndFiltering';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Button} from '@material-ui/core';
import HealingIcon from '@material-ui/icons/Healing';
import RefreshIcon from '@material-ui/icons/Refresh';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import awsRegions from 'aws-regions';
import {withConfirmationPopup} from '../../../components/shared/ConfirmationPopup';
import posthog from 'posthog-js';

const ApplicationTableHeaders = [
    { id: 'name', numeric: false, defaultOrderBy: true, disablePadding: false, label: 'Name' },
    { id: 'aspireCode', isIdentifier: true, numeric: false, disablePadding: false, label: 'Code' },
    { id: 'category', numeric: false, disablePadding: false, label: 'Category' }
];

const AccountsTableHeaders = [
    { id: 'name', numeric: false, defaultOrderBy: true, disablePadding: false, label: 'Name' },
    { id: 'accountId', isIdentifier: true, numeric: false, disablePadding: false, label: 'AWS ID' }
];

const EC2InstancesTableHeaders = [
    { id: 'instanceId', isIdentifier: true, numeric: false, disablePadding: false, label: 'Instance ID' },
    { id: 'awsAccountId', numeric: false, defaultOrderBy: true, disablePadding: false, label: 'AWS Account ID' },
    { id: 'region', numeric: false, disablePadding: false, label: 'Region' },
    { id: 'state', numeric: false, disablePadding: false, label: 'State' },
    { id: 'platform', numeric: false, disablePadding: false, label: 'Platform' },
    { id: 'platform-details', numeric: false, disablePadding: false, label: 'Platform details' }
];

const EC2_PATCHING_ASSESSMENT_RULE_ID = "PCPCOMP-EC2-020";
const EC2_INSTANCE_CERBAIR_TYPE = "AWS::EC2::InstanceOS";

const ButtonWithConfirmation = withConfirmationPopup(Button);

const AdminManualEC2InstancePatchingPage = () => {
    const [init] = useState(true);

    const [region, setRegion] = useState("eu-west-1");

    const [applications, setApplications] = useState([]);
    const [loadingApplications, setLoadingApplications] = useState(false);

    const [fullAWSAccountList, setFullAWSAccountList] = useState([]);
    const [applicationAWSAccountList, setApplicationAWSAccountList] = useState([]);
    const [loadingAWSAccounts, setLoadingAWSAccounts] = useState(false);

    // Patch sentences
    const [patchSentences, setPatchSetences]= useState([]);
    const [selectedPatchSentence, setSelectedPatchSentence]= useState(null);
    const [loadingPatchSentences, setLoadingPatchSentences] = useState(false);

    // EC2 Loading state
    const [ec2Instances, setEC2Instances] = useState([]);
    const [loadingEC2Instances, setLoadingEC2Instances] = useState(false);
    const [refreshingEC2Instances, setRefreshingEC2Instances] = useState(false);
    const [lastSelectionLoaded, setLastSelectionLoaded] = useState({"accounts": [], "application": null})

    // Selection for loading
    const [selectedApplication, setSelectedApplication] = useState(null);
    const [selectedAccounts, setSelectedAccounts] = useState([]);

    // Selection for patching
    const [ec2InstancesSelectedForPatching, setEC2InstancesSelectedForPatching] = useState([]);
    const [isStartingEC2InstancesPatching, setIsStartingEC2InstancesPatching] = useState(false);

    const convertEC2InstancesToTableDataModel = (ec2Instance) => {
        return {
            instanceId: ec2Instance.instance_id,
            awsAccountId: ec2Instance.aws_account_id,
            region: ec2Instance.region,
            state: ec2Instance.state.Name,
            platform: ec2Instance.platform_type,
            "platform-details": `${ec2Instance.platform_name} / ${ec2Instance.platform_version}`
        }
    }

    const fetchEC2Instances = (accounts, application, region) => {
        if (accounts && accounts.length > 0) {
            const promises = [];
            accounts.forEach(awsAccountId => {
                promises.push(api.MachinesSecurityMaintenance.listEC2InstancesForAWSAccount(awsAccountId, region).then(r => r.data));
            });
            return Promise.all(promises).then(results => {
                let instances = [];
                results.forEach(page => {
                    instances = instances.concat(page);
                });
                setEC2Instances(instances.map(instance => {
                    return convertEC2InstancesToTableDataModel(instance);
                }));
            })
        } else if (application) {
            return api.MachinesSecurityMaintenance.listEC2InstancesForApplication(application, region).then(r => r.data).then(instances => {
                setEC2Instances(instances.map(instance => {
                    return convertEC2InstancesToTableDataModel(instance);
                }));
            });
        } else {
            return new Promise(resolve => {
                setEC2Instances([]);
                resolve([]);
            });
        }
    }

    const fetchEC2InstancesForSelection = () => {
        // Save the selection fto display the title
        setLastSelectionLoaded({
            "accounts": selectedAccounts,
            "application": selectedApplication,
            region
        });

        // Reset the selection
        setEC2InstancesSelectedForPatching([]);

        setLoadingEC2Instances(true);
        fetchEC2Instances(selectedAccounts, selectedApplication, region)
            .finally(() => setLoadingEC2Instances(false));
    }

    const refreshEC2Instances = () => {
        setRefreshingEC2Instances(true);
        fetchEC2Instances(lastSelectionLoaded.accounts, lastSelectionLoaded.application, lastSelectionLoaded.region)
            .finally(() => setRefreshingEC2Instances(false));
    }

    const fetchApplications = () => {
        setLoadingApplications(true);
        api.getAllApplications().then((r) => r.data).then((applications) => {
            setApplications(applications);
        }).finally(() => setLoadingApplications(false))
    }

    const fetchAllAWSAccounts = () => {
        setLoadingAWSAccounts(true);
        api.getAllAccounts().then((r) => r.data).then((accounts) => {
            setFullAWSAccountList(accounts);
        }).finally(() => setLoadingAWSAccounts(false))
    }

    const fetchAccountsForApplication = (aspireCode) => {
        if (aspireCode) {
            setLoadingAWSAccounts(true);
            api.getAccounts(aspireCode)
                .then((accounts) => {
                    setApplicationAWSAccountList(accounts);
                    // Clear the selection
                    // setSelectedAccounts([]);
                }).finally(() => setLoadingAWSAccounts(false))
        }
    }

    const fetchSentencesForPatch = () => {
        setLoadingPatchSentences(true);
        api.ComplianceRemediationExecution.listSentencesForRule(EC2_PATCHING_ASSESSMENT_RULE_ID)
            .then(r => r.data).then(sentences => setPatchSetences(sentences))
            .finally(() => setLoadingPatchSentences(false));
    }

    const lookupEC2InstanceWithEC2InstanceId = (ec2InstanceId) => {
        const retainedEC2Instances = ec2Instances.filter((ec2Instance) => {
            return ec2Instance.instanceId === ec2InstanceId;
        })
        return (retainedEC2Instances.length > 0) ? retainedEC2Instances[0] : null;
    }

    const launchEC2InstancesPatching = () => {
        
        if (selectedPatchSentence) {
            setIsStartingEC2InstancesPatching(true);
            const promises = [];
            ec2InstancesSelectedForPatching.forEach(ec2InstanceId => {
                const ec2Instance = lookupEC2InstanceWithEC2InstanceId(ec2InstanceId);
                if (ec2Instance) {
                    posthog.capture("Launch EC2 Instance Patching", ec2Instance)

                    promises.push(
                        api.ComplianceRemediationExecution.launchResourceRemediation(
                            ec2Instance.awsAccountId,
                            ec2Instance.region,
                            ec2Instance.instanceId,
                            EC2_INSTANCE_CERBAIR_TYPE,
                            EC2_PATCHING_ASSESSMENT_RULE_ID,
                            selectedPatchSentence)
                    )
                }
            });
            Promise.all(promises)
                .then(() => refreshEC2Instances())
                .finally(() => setIsStartingEC2InstancesPatching(false));
        }
    }

    const handleApplicationSelectionChange = (selectionAspireCode) => {
        if (selectionAspireCode && selectionAspireCode.length > 0) {
            setSelectedApplication(selectionAspireCode[0]);
            fetchAccountsForApplication(selectionAspireCode[0]);
        } else {
            setSelectedApplication(null);
        }
    };

    const handleAccountsSelectionChange = (selectionAccountIds) => {
        if (selectionAccountIds && selectionAccountIds.length > 0) {
            setSelectedAccounts(selectionAccountIds);
        } else {
            setSelectedAccounts([]);
        }
    }

    const handleEC2InstancesSelectionChange = (selectionEC2Instances) => {
        if (selectionEC2Instances && selectionEC2Instances.length > 0) {
            setEC2InstancesSelectedForPatching(selectionEC2Instances);
        }  else {
            setEC2InstancesSelectedForPatching([]);
        }
    }

    const lookupApplicationWithAspireCode = (aspireCode) => {
        const retainedApps = applications.filter((application) => {
            return application.aspireCode === aspireCode;
        })
        return (retainedApps.length > 0) ? retainedApps[0] : null;
    }

    const lookupAccountWithAccountId = (accountId) => {
        const retainedAccounts = fullAWSAccountList.filter((account) => {
            return account.accountId === accountId;
        })
        return (retainedAccounts.length > 0) ? retainedAccounts[0] : null;
    }

    useEffect(() => {
        fetchApplications();
        fetchAllAWSAccounts();
        fetchSentencesForPatch();
    }, [init])

    return <>
        <h2>
            <HealingIcon className="icon-page"/> EC2 Instances manual patching
        </h2>
        <hr/>
        <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
                <h4 style={{marginBottom: '1rem'}}>Region</h4>
                <Select value={region} onChange={event => setRegion(event.target.value)} style={{width: '50%'}}>
                    {awsRegions.list({public: true}).map(region => {
                        return <MenuItem value={region.code}>{region.full_name} ({region.code})</MenuItem>
                    })}
                </Select>
            </Grid>
            <Grid item md={6} xs={12}>
                <h4 style={{marginBottom: '1rem'}}>Applications</h4>
                {loadingApplications && <div style={{textAlign: 'center'}}><CircularProgress /></div>}
                {!loadingApplications &&
                    <TableWithSelectionAndFiltering headers={ApplicationTableHeaders} multiple={false}
                                                    rows={applications} maxHeight={'400px'}
                                                    onSelectionChange={(selection) => handleApplicationSelectionChange(selection)}
                                                    disabled={loadingApplications || loadingAWSAccounts}/>
                }
            </Grid>
            <Grid item md={6} xs={12}>
                <h4 style={{marginBottom: '1rem'}}>Accounts {selectedApplication ? <span>(for application {lookupApplicationWithAspireCode(selectedApplication).name})</span> : ''}</h4>
                {loadingAWSAccounts && <div style={{textAlign: 'center'}}><CircularProgress /></div>}
                {!loadingAWSAccounts &&
                    <TableWithSelectionAndFiltering headers={AccountsTableHeaders} multiple={true}
                                                    maxHeight={'400px'}
                                                    onSelectionChange={(selection) => handleAccountsSelectionChange(selection)}
                                                    disabled={loadingApplications || loadingAWSAccounts}
                                                    rows={(selectedApplication) ? applicationAWSAccountList : fullAWSAccountList}/>
                }
            </Grid>
            <Grid item md={12} xs={12}>
                <h5 style={{marginBottom: '1rem'}}>
                    {selectedAccounts.length === 0 && selectedApplication &&
                        <span>You have selected the application {lookupApplicationWithAspireCode(selectedApplication).name} ({region})</span>
                    }
                    {selectedAccounts.length > 0 && selectedAccounts.length <= 5 &&
                    <span>You have selected the AWS accounts {selectedAccounts.map(accountId => lookupAccountWithAccountId(accountId).name).join(', ')} ({region})</span>
                    }
                    {selectedAccounts.length > 5 &&
                    <span> You have selected {selectedAccounts.length} AWS accounts ({region})</span>
                    }
                    {!selectedApplication && selectedAccounts.length === 0 &&
                    <span>You have not selected anything</span>
                    }
                </h5>
                <Button variant={"contained"}
                        onClick={() => fetchEC2InstancesForSelection()}
                        disabled={!selectedApplication && selectedAccounts.length === 0}>
                    Load EC2 instances for selection
                </Button>
            </Grid>
            {loadingEC2Instances &&
            <Grid item md={12} xs={12}>
                <div style={{textAlign: 'center'}}><CircularProgress /></div>
            </Grid>}
            {!loadingEC2Instances && ec2Instances && ec2Instances.length > 0 &&
                <>
                    <Grid item md={12} xs={12}>
                        <>
                            <Button style={{float: 'right'}}
                                    onClick={() => refreshEC2Instances()}
                                    disabled={refreshingEC2Instances}>
                                {refreshingEC2Instances &&
                                    <CircularProgress size={20} />
                                }
                                {!refreshingEC2Instances &&
                                    <RefreshIcon />
                                }
                            </Button>
                            <h4 style={{marginBottom: '1rem'}}>Showing EC2 instances
                                {lastSelectionLoaded.accounts.length === 0 && lastSelectionLoaded.application &&
                                <span> for application {lookupApplicationWithAspireCode(lastSelectionLoaded.application).name} ({lastSelectionLoaded.region})</span>
                                }
                                {lastSelectionLoaded.accounts.length > 0 && lastSelectionLoaded.accounts.length <= 5 &&
                                <span> for accounts {lastSelectionLoaded.accounts.map(accountId => lookupAccountWithAccountId(accountId).name).join(', ')} ({lastSelectionLoaded.region})</span>
                                }
                                {lastSelectionLoaded.accounts.length > 5 &&
                                <span> for {lastSelectionLoaded.accounts.length} accounts ({lastSelectionLoaded.region})</span>
                                }
                                {!lastSelectionLoaded.application && lastSelectionLoaded.accounts.length === 0 &&
                                <span> (no selection)</span>
                                }
                            </h4>
                            <TableWithSelectionAndFiltering headers={EC2InstancesTableHeaders}
                                                            multiple={true}
                                                            rows={ec2Instances}
                                                            onSelectionChange={(selection) => handleEC2InstancesSelectionChange(selection)}
                                                            disabledRows={(row) => row.state !== "running"}
                                                            disabledRowsMessage={"Only running instances can be patched"}
                                                            disabled={loadingEC2Instances}
                                                            maxHeight={'800px'}/>
                        </>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        {loadingPatchSentences &&
                            <div style={{textAlign: 'center'}}><CircularProgress /></div>
                        }
                        {!loadingPatchSentences &&
                            <Select value={selectedPatchSentence}
                                    style={{width: '100%'}}
                                    onChange={event => setSelectedPatchSentence(event.target.value)}>
                            {patchSentences.map(patchSentence => {
                                return <MenuItem value={patchSentence.sentenceId}>{patchSentence.description}</MenuItem>
                            })}
                            </Select>
                        }
                    </Grid>
                    <Grid item md={6} xs={12}>
                        {isStartingEC2InstancesPatching &&
                        <Button variant={"contained"} disabled>
                            Launching patching for {ec2InstancesSelectedForPatching.length} EC2 instances... <CircularProgress size={20}/>
                        </Button>
                        }

                        {!isStartingEC2InstancesPatching && ec2InstancesSelectedForPatching && ec2InstancesSelectedForPatching.length > 0 && selectedPatchSentence &&
                            <ButtonWithConfirmation
                                popupTitle={"Confirm operation"}
                                popupMessageMarkdown={"This operation could lead to machine restart and possible system unavailability. " +
                                "Please confirm that you accept this risk."}
                                onAccept={() => launchEC2InstancesPatching()}
                                variant={"contained"}>
                                Patch {ec2InstancesSelectedForPatching.length} EC2 instances
                            </ButtonWithConfirmation>
                        }

                        {!isStartingEC2InstancesPatching && (!ec2InstancesSelectedForPatching || ec2InstancesSelectedForPatching.length <= 0 || !selectedPatchSentence) &&
                        <Button variant={"contained"} disabled>
                            <p>Please select instances and the sentence to apply</p>
                        </Button>
                        }
                    </Grid>
                </>
            }
        </Grid>
    </>
};

export default AdminManualEC2InstancePatchingPage;
