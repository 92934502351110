import React, {Component} from 'react';
import api from 'services/ApiServices';
import AssignmentIcon from '@material-ui/icons/Assignment';
import {hasAnyAuthority, hasAnyAuthorityOnThisApplication} from 'store/UserStore';
import {Authorities} from 'utils/Authorities';
import ApiTable from 'components/tables/ApiTable';
import {withContext} from 'store/ContextStore';
import AttachRoleToUser from 'domains/application/components/user/AttachRoleToUser';
import DetachAwsRoleFromUser from 'domains/application/components/user/DetachAwsRoleFromUser';
import {getColumnFilterDirection} from 'utils/Utils';

class UsersRoles extends Component {


	constructor(props) {
		super(props);

		this.state = {userEmail: null};
		this.apiTable = React.createRef();
		this.columns = [

			{
				name: '',
				key: '',
				cellRenderer: (undefinedParameter, column, data) => {
					return (
						<DetachAwsRoleFromUser onConfirm={this.refresh} arn={data.arn} appId={this.props.appId}
							userId={this.props.match.params.userId}/>
					);
				}
			},
			{
				name: 'Arn',
				key: 'arn',
				filter: true,
				sort: getColumnFilterDirection('arn', 'users-grid')
			}
		];

		this.load = this.load.bind(this);
		this.refresh = this.refresh.bind(this);
	}


	async load() {
		return await api.getAwsRolesOfUser(this.props.appId, this.props.match.params.userId);
	}

	async componentDidMount() {
		let response = await api.getUser(this.props.appId, this.props.match.params.userId);
		this.setState({userEmail: response.data.user.email});
	}

	refresh() {
		this.apiTable.current.load();
	}

	render() {
		const isAdmin = hasAnyAuthority(Authorities.ROLE_ADMIN);
		const isAppOwner = this.props.authorities.includes(Authorities.ROLE_APP_OWNER);

		return (
			<>
				<div className="float-right">
					{(isAdmin || isAppOwner) && (
						<>
							<AttachRoleToUser userId={this.props.match.params.userId} appId={this.props.appId}
								className="mr-1" onConfirm={this.refresh} onSubmit={this.onSubmit}/>
						</>
					)}
				</div>
				<h3>
					<AssignmentIcon className="icon-page"/>
                    Roles for user : <strong>{this.state.userEmail}</strong>
				</h3>
				<hr/>

				<ApiTable
					id="users-grid"
					ref={this.apiTable}
					columns={this.columns}
					load={this.load}
				>
				</ApiTable>
			</>);
	}
}

export default withContext(UsersRoles);
