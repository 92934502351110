import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ReactMarkdown from 'react-markdown';
import KMSIcon from '@material-ui/icons/VpnKey';
import RolesIcon from '@material-ui/icons/Assignment';

const kmsMarkdown = '# AWS KMS Management has changed!\n' +
    'You can now manage your AWS KMS keys on your own. You can use the deployment tool of your choice to provision roles using the AWS API (AWS CDK, AWS CloudFormation, Hashicorp Terraform, AWS Console, …).\n' +
    '\n' +
    '**NB: We strongly recommend you to define a dedicated CloudFormation template for your KMS Keys as Key deletion is forbidden.**\n' +
    '\n' +
    '## How to create a KMS Key\n' +
    '\n' +
    '```\n' +
    'AWSTemplateFormatVersion: 2010-09-09\n' +
    'Description: Template used to define an KMS Key\n' +
    '\n' +
    'Resources:\n' +
    '   KMSKey:\n' +
    '   Type: AWS::KMS::Key\n' +
    '   Properties:\n' +
    '      Description: Sample KMS Key\n' +
    '      EnableKeyRotation: true\n' +
    '      KeyPolicy:\n' +
    '        Version: "2012-10-17"\n' +
    '        Id: keypolicy\n' +
    '        Statement:\n' +
    '          - Sid: keyAdmin\n' +
    '            Effect: Allow\n' +
    '            Principal:\n' +
    '              AWS:\n' +
    '                Fn::Sub: arn:aws:iam::$' +
    '{AWS::AccountId}:root\n' +
    '            Action:\n' +
    '              - kms:*\n' +
    '            Resource: "*"\n' +
    '          - Sid: S3CryptDecrypt\n' +
    '            Effect: Allow\n' +
    '            Principal:\n' +
    '              Service: s3.amazonaws.com\n' +
    '            Action:\n' +
    '              - kms:Encrypt\n' +
    '              - kms:Decrypt\n' +
    '              - kms:ReEncrypt*\n' +
    '              - kms:GenerateDataKey*\n' +
    '            Resource: "*"\n' +
    '          - Sid: LambdaCryptDecrypt\n' +
    '            Effect: Allow\n' +
    '            Principal:\n' +
    '              Service: lambda.amazonaws.com\n' +
    '            Action:\n' +
    '              - kms:Encrypt\n' +
    '              - kms:Decrypt\n' +
    '              - kms:ReEncrypt*\n' +
    '              - kms:GenerateDataKey*\n' +
    '              - kms:DescribeKey\n' +
    '            Resource: "*"\n' +
    '          - Sid: EventsCryptDecrypt\n' +
    '            Effect: Allow\n' +
    '            Principal:\n' +
    '              Service: events.amazonaws.com\n' +
    '            Action:\n' +
    '              - kms:Encrypt\n' +
    '              - kms:Decrypt\n' +
    '              - kms:ReEncrypt*\n' +
    '              - kms:GenerateDataKey*\n' +
    '              - kms:DescribeKey\n' +
    '            Resource: "*"\n' +
    '\n' +
    '   KMSAlias:\n' +
    '      Type: AWS::KMS::Alias\n' +
    '      Properties:\n' +
    '         AliasName: alias/kms-sample\n' +
    '         TargetKeyId:\n' +
    '            Ref: KMSKey\n' +
    '      DependsOn: KMSKey\n' +
    '```';
const awsRolesMarkdown = '# AWS IAM Role Management has changed!\n' +
    '\n' +
    'You can now manage your AWS IAM role on your own. You can use the deployment tool of your choice to provision roles using the AWS API (AWS CDK, AWS CloudFormation, Hashicorp Terraform, AWS Console, …).\n\n' +
    '\n' +
    '### How to create an IAM Role assumable by a user through One login\n' +
    '\n' +
    '```\n' +
    'AWSTemplateFormatVersion: 2010-09-09\n' +
    'Description: Template used to define an IAM Role\n' +
    '\n' +
    'Resources:\n' +
    '  RoleUserAdministrator:\n' +
    '    Type: AWS::IAM::Role\n' +
    '    Properties:\n' +
    '      RoleName: role-user-administrator\n' +
    '      AssumeRolePolicyDocument:\n' +
    '        Version: \'2012-10-17\'\n' +
    '        Statement:\n' +
    '          - Effect: Allow\n' +
    '            Principal:\n' +
    '              AWS:\n' +
    '                - "arn:aws:iam::949309538421:root"\n' +
    '              Federated:\n' +
    '                - !Join [ "", ["arn:aws:iam::", !Ref \'AWS::AccountId\', ":saml-provider/idp-admin-idaasproduction"]]\n' +
    '            Action:\n' +
    '              - \'sts:AssumeRole\'\n' +
    '              - \'sts:AssumeRoleWithSAML\'\n' +
    '      Path: /\n' +
    '      ManagedPolicyArns:\n' +
    '        - arn:aws:iam::aws:policy/AdministratorAccess\n' +
    '```\n' +
    'The assumerole policy must be written as is :\n' +
    '* It trusts the airbus jump account to assume this role\n' +
    '* It trusts the identity provider deployed by the pcp platform in your AWS account.\n' +
    '\n' +
    '### How to create an IAM role for an AWS service \n' +
    '(the example below uses lambda)\n' +
    '```\n' +
    'AWSTemplateFormatVersion: 2010-09-09\n' +
    'Description: Template used to define an IAM Role\n' +
    '\n' +
    'Resources:\n' +
    '  LambdaExecutionRole:\n' +
    '    Type: "AWS::IAM::Role"\n' +
    '    Properties:\n' +
    '      AssumeRolePolicyDocument:\n' +
    '        Version: "2012-10-17"\n' +
    '        Statement:\n' +
    '          -\n' +
    '            Effect: "Allow"\n' +
    '            Principal:\n' +
    '              Service:\n' +
    '                - "lambda.amazonaws.com"\n' +
    '            Action:\n' +
    '              - "sts:AssumeRole"\n' +
    '      ManagedPolicyArns:\n' +
    '        - arn:aws:iam::aws:policy/service-role/AWSLambdaVPCAccessExecutionRole\n' +
    '```';

function BlueprintsPage() {
	const [value, setValue] = React.useState(0);
	const handleChange = (event, newValue) => setValue(newValue);

	return <>
        <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="icon label tabs example">
        	<Tab icon={<KMSIcon/>} label="KMS" value={0}/>
        	<Tab icon={<RolesIcon/>} label="ROLES" value={1}/>
        </Tabs>
        <TabPanel value={value} index={0}>
        	<ReactMarkdown source={kmsMarkdown}/>
        </TabPanel>
        <TabPanel value={value} index={1}>
        	<ReactMarkdown source={awsRolesMarkdown}/>
        </TabPanel>
    </>;
}

function TabPanel(props) {
	const {children, value, index} = props;
	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
		>
			<Box p={3}>{children}</Box>
		</Typography>
	);
}

export default BlueprintsPage;
