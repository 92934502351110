import 'react-app-polyfill/ie11';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-mde/lib/styles/css/react-mde-all.css';
import {toast} from 'react-toastify';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {store} from 'state/store';
import {Provider} from 'react-redux';
import { PostHogProvider} from 'posthog-js/react'

const options = {
	api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
	capture_pageview: false
	
}
axios.interceptors.response.use(null, function (error) {

	let msg = error.message;
	if (error.response) {
		const response = error.response;
		if (response.data && response.data.message) {
			msg = response.data.message;
		}
	} else if (error.request && axios.skip504) {
		// there are some request that are too long and does not return.
		axios.skip504 = false;
		return Promise.resolve();
	}

	toast.error(msg);
	return Promise.reject(error);
});

ReactDOM.render(<Provider store={store}>
	<PostHogProvider  apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
	<App/>
	</PostHogProvider></Provider>, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
