import AddIcon from '@material-ui/icons/Add';
import classnames from 'classnames';
import {Loading, Button} from 'horizon-components-react';
import React, {Component} from 'react';
import {Field} from 'react-final-form';
import api from '../../../../services/ApiServices';
import {withContext} from '../../../../store/ContextStore';
import DialogForm from '../../../../components/shared/DialogForm';
import {DefaultCreateTypeaheadAdapter} from '../../../../components/shared/form/DefaultAdapter';
import './TeamAddUser.scss';

class TeamAddUser extends Component {

	constructor(props) {
		super(props);

		this.formDialog = React.createRef();

		this.state = {
			usersOptions: null,
			model: {},
			loading: true,
			saving: false
		};

		this.toggle = this.toggle.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleEmailChange = this.handleEmailChange.bind(this);
	}

	toggle(event) {
		event.preventDefault();
		this.formDialog.current.open();
		this.loadUsers();
	}

	async loadUsers() {
		this.setState({
			loading: true
		});
		try {
			const response = await api.getAllUsers();

			let usersOptions = [];
			response.data.forEach(user => {
				const option = {
					label: user.email,
					value: user.id
				};
				usersOptions.push(option);
			});

			this.setState({
				usersOptions: usersOptions,
				loading: false,
			});
		} catch (e) {
			this.setState({
				loading: false,
			});
		}
	}

	handleEmailChange(option) {
		const model = this.state.model;
		model.email = option.label;

		if (option.__isNew__) {
			this.createUser = true;
		} else {
			this.createUser = false;
			model.id = option.value;
		}

		this.setState({
			model: model
		});
	}

	async handleSubmit(values) {
		try {
			this.setState({
				saving: true
			});

			let userId = null;
			if (this.createUser) {
				const response = await api.createUser({
					email: this.state.model.email
				});
				let user = response.data;
				userId = user.id
			} else {
				userId = this.state.model.id
			}

			if (this.props.manipulatedListType === 'owners') {
				await api.addTeamOwner(this.props.teamId, userId);
			} else {
				await api.addTeamMember(this.props.teamId, userId);
			}
		} catch (e) {
			console.error(e);
		} finally {
			this.setState({
				saving: false
			});
		}
	}

	render() {
		let buttonLabel;
		if (this.props.manipulatedListType === 'owners') {
			buttonLabel = 'Owner';
		} else {
			buttonLabel = 'Member';
		}

		return (
			<span className={classnames(this.props.className)}>
				<Button primary size="small" withIcon="true" onClick={this.toggle}>
					<AddIcon /> {buttonLabel}
				</Button>

				<DialogForm
					className="create-edit-dialog dialog-with-select-input"
					ref={this.formDialog}
					model={this.state.model}
					dialogTitle={'Add a new ' + buttonLabel}
					onSubmit={this.handleSubmit}
					onConfirm={this.props.onConfirm}
				>
					{this.state.loading ? (
						<Loading>Loading&nbsp;users...</Loading>
					) : (
						<div className="create-or-edit-user-dialog-content">
							<div className="mb-4">
								<label>Email of the new {buttonLabel}</label>
								<Field
									name="email"
									placeholder="Select user by email"
									required={true}
									errorText="Please select an existing user"
									disabled={this.state.saving}
									options={this.state.usersOptions}
									onChange={this.handleEmailChange}
									component={DefaultCreateTypeaheadAdapter}
									autoFocus
								/>
							</div>
						</div>
					)}
				</DialogForm>
  		    </span>
		);
	}
}

export default withContext(TeamAddUser);
