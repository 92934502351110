import React, {Component} from 'react';
import './AwsRolesPage.scss';
import {getColumnFilterDirection} from 'utils/Utils';
import api from 'services/ApiServices';
import ApiTable from 'components/tables/ApiTable';
import {withContext} from "../../../store/ContextStore";
import {Button} from "horizon-components-react";
import SwitchCameraIcon from "@material-ui/icons/SwitchCamera";


class Arn {

    static parse(s) {
        if (typeof s !== 'string') {
            return null;
        }

        const [scheme, partition, service, region, accountId, ...resourceParts] = s.split(':');
        const resourcePart = resourceParts.join(':');

        if (!resourcePart) {
            return null;
        }

        return {
            arn: s,
            service: service,
            accountId: accountId,
            role_name: resourcePart.split('role/')[1]
        };
    }
}

class AwsRolesPage
    extends Component {

    constructor(props) {
        super(props);

        this.apiTable = React.createRef();
        this.load = this.load.bind(this);
        this.columns = [
            {
                name: 'Arn',
                key: 'arn',
                filter: true,
                sort: getColumnFilterDirection('name', 'user-awsroles-grid')
            },
            {
                name: 'Account',
                key: 'accountId',
                filter: true,
                sort: getColumnFilterDirection('account_id', 'user-awsroles-grid')
            },
            {
                name: 'Role',
                key: 'role_name',
                filter: true,
                sort: getColumnFilterDirection('role_name', 'user-awsroles-grid')
            },
            {
                name: '',
                key: '',
                cellRenderer: (undefinedVariable, colums, data) => {
                    let switch_role_to = `https://signin.aws.amazon.com/switchrole?roleName=${data.role_name}&account=${data.accountId}`
                    return (
                        <a href={switch_role_to} target="_blank" rel="noopener noreferrer"><Button primary size="small" onClick={this.refresh}>
                                    <SwitchCameraIcon/>
                                    Switch Role
                                </Button></a>
                    );
                }
            }
        ];
    }

    async load() {
        let response = await api.getAllAwsRolesCurrentUser()
        let arns = {data:response.data.map(arn => Arn.parse(arn.arn))}
        return arns
    }


    render() {
        return (
            <ApiTable
                id="user-role-grid"
                ref={this.apiTable}
                columns={this.columns}
                load={this.load}
            />
        );
    }
}

export default withContext(AwsRolesPage);
