import {hasAnyAuthority} from 'store/UserStore';
import {Authorities} from 'utils/Authorities';
import {
	CHANGE_STRATEGY_MODAL_CLOSED, CHANGE_STRATEGY_MODAL_OPENED,
	COMPLIANCE_RULES_LOADED,
	LOGBOOK_ERROR,
	LOGBOOK_LOADED,
	LOGBOOK_DELETING_LOGENTRY,
	LOGBOOK_DELETED_LOGENTRY,
	LOGBOOK_LOADING,
	ALL_REMEDIATION_ACCOUNTS_LOADING, 
	REMEDIATION_STRATEGY_MODIFIED,
	REMEDIATION_STRATEGY_MODIFYING,
	ALL_REMEDIATION_ACCOUNTS_LOADED
} from 'state/actions-types';

const initialState = {
	logbook: {
		logEntries: [],
		isLoading: false,
		isOnError: false,
		hasPermissionToEnterLogEntries: false
	},
	remediationControl: {
		rules: [],
		isLoading: false,
		changeStrategyIsOpen: false,
		reloadRemediationControlPage: true,
		accountsRemediationControl :[]
	}
};

export const rootReducer = (state = initialState, action) => {
	if (action.type === ALL_REMEDIATION_ACCOUNTS_LOADED) {
		return {
			...state,
			remediationControl: {
				...state.remediationControl,
				reloadRemediationControlPage: false,
				accountsRemediationControl: action.payload
			}
		};
	}

	if (action.type === ALL_REMEDIATION_ACCOUNTS_LOADING) {
		return {
			...state,
			remediationControl: {
				...state.remediationControl,
				reloadRemediationControlPage: true
			}
		};
	}
	if (action.type === CHANGE_STRATEGY_MODAL_CLOSED) {
		return {
			...state,
			remediationControl: {
				...state.remediationControl,
				changeStrategyIsOpen: false
			}
		};
	}
	if (action.type === CHANGE_STRATEGY_MODAL_OPENED) {
		return {
			...state,
			remediationControl: {
				...state.remediationControl,
				changeStrategyIsOpen: true
			}
		};
	}
	if (action.type === REMEDIATION_STRATEGY_MODIFYING) {
		return {
			...state,
			remediationControl: {
				...state.remediationControl,
				isLoading: true
			}
		};
	}

	if (action.type === REMEDIATION_STRATEGY_MODIFIED) {
		return {
			...state,
			remediationControl: {
				...state.remediationControl,
				isLoading: false
			}
		};
	}
	if (action.type === COMPLIANCE_RULES_LOADED) {
		return {
			...state,
			remediationControl: {
				...state.remediationControl,
				rules: action.payload.rules
			}
		};
	}
	if (action.type === LOGBOOK_LOADING) {
		return {
			...state,
			logbook: {
				...state.logbook,
				isLoading: true,
				hasPermissionToEnterLogEntries: hasAnyAuthority(Authorities.ROLE_ADMIN)

			}
		};
	}
	if (action.type === LOGBOOK_ERROR) {
		return {
			...state,
			logbook: {
				...state.logbook,
				isOnError: true,
				isLoading: false,

			}
		};
	}
	if (action.type === LOGBOOK_LOADED) {
		return {
			...state,
			logbook: {
				...state.logbook,
				logEntries: action.payload.logEntries.map(logentry => {
					return {
						...logentry,
						isDeleting: false
					}
				}),
				isLoading: false,
				isOnError: false,
			}
		};
	}

	if (action.type === LOGBOOK_DELETING_LOGENTRY) {
		return {
			...state,
			logbook: {
				...state.logbook,
				logEntries: state.logbook.logEntries.map(logentry => {
					if (logentry.id === action.payload.id) {
						return {
							...logentry,
							isDeleting: true
						}
					} else {
						return logentry
					}
				})
			}
		}
	}

	if (action.type === LOGBOOK_DELETED_LOGENTRY) {
		return {
			...state,
			logbook: {
				...state.logbook,
				logEntries: state.logbook.logEntries.filter(logEntry => {
					return logEntry.id !== action.payload.id
				})
			}
		}
	}
	return state;
};
