import { Banner } from 'horizon-components-react';
import React from 'react';

const NotFound = ({ location }) => (
	<div className="not-found">
		<Banner status="error" title="Not found">
      Page {location.pathname} cannot be found.
		</Banner>
	</div>
);

export default NotFound;
