import React, {Component} from 'react';
import {Button} from 'horizon-components-react';
import classnames from 'classnames';
import ContactMail from '@material-ui/icons/ContactMail';
import {toast} from 'react-toastify';
import api from '../../../../services/ApiServices';
import {Tooltip} from '@material-ui/core';


class ContactAllUsers extends Component {

	constructor(props) {
		super(props);
		this.state = {
			usersEmails: []
		};

		this.copyUsersEmailsIntoClipboard = this.copyUsersEmailsIntoClipboard.bind(this);
	}

	componentDidMount() {
		api.getAllUsers()
			.then(users => {return users.data.map(user => user.email);})
		 .then(usersEmails => this.setState({usersEmails: usersEmails}));
	}

	copyUsersEmailsIntoClipboard() {
		navigator.clipboard.writeText(this.state.usersEmails);
		toast.info('All users emails of PCP are into your clipboard');
	}

	render() {
		return (
			<>
				<Tooltip title={'Copy all users emails into your clipboard'}>
					<Button onClick={this.copyUsersEmailsIntoClipboard} className={classnames(this.props.className)}
						primary
						size="small">
						<ContactMail/>
					</Button>
				</Tooltip>
			</>
		);
	}
}

export default ContactAllUsers;
