import Grid from '@material-ui/core/Grid/index';
import React from 'react';
import {Card, CardContent} from 'horizon-components-react';
import AdministrationMenu from './AdministrationMenu';
import RoutesForAdministration from './RoutesForAdministration';


const LayoutForAdministration = () => {
	return (
		<Grid container spacing={0}>
			<Grid item xs={12} md={3} lg={2}>
				<AdministrationMenu/>
			</Grid>
			<Grid item xs={12} md={9} lg={10}>
				<Card>
					<CardContent>
						<RoutesForAdministration/>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};

export default LayoutForAdministration;
