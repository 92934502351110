import React from 'react';
import {
    Card, CardContent, Loading
} from 'horizon-components-react';
import ComplianceIcon from '@material-ui/icons/DoneAll';
import {withContext} from '../../../store/ContextStore';
import api from '../../../services/ApiServices';
import {random, uniq} from 'lodash';
import ProgressBar from '../../../components/shared/progress/ProgressBar';
import MetricsOfCompliance from '../components/compliance/MetricsOfCompliance';
import Grid from '@material-ui/core/Grid';

const getProgressBarColorType = percentage => {
    return (
        percentage < 5 ? 'critical' :
            percentage >= 5 && percentage < 50 ? 'danger' :
                percentage >= 50 && percentage < 100 ? 'warning' :
                    percentage === 100 ? 'success' :
                        'primary'
    );
};

class ComplianceOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            percentageOfComplianceForApplication: 0,
            metrics: [],
            loading: false,
            loadingNumberOfServices: 0,
            loadingLoadedServices: 0
        };
    }

    async loadData(appId, accountName) {
        console.info(appId)
        console.info(accountName)
        this.setState({loading: true, loadingLoadedServices: 0});
        // Load the AWS Services
        const loadPromise = api.ComplianceResults.getComplianceOverviewResults(appId, accountName)
            .then(r => r.data)
            .finally(() => this.setState({loading: false}));
        console.info(loadPromise)
        return this.loadDataFromPromiseInState(loadPromise);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.appId !== this.props.appId || nextProps.accountName !== this.props.accountName) {
            this.loadData(this.props.appId, nextProps.accountName);
        }
    }

    componentDidMount() {
        this.loadData(this.props.appId, this.props.accountName);
    }

    loadDataFromPromiseInState(promise) {

        promise.then((stats) => {
            const statsPerService = {};
            let total = 0
            let total_not_compliant = 0
            for (let service in stats) {
                total += stats[service].Total
                let status = stats[service].Status
                console.info(status)
                let nb_fail = status['Fail'] || 0
                let nb_compliant = stats[service].Total - nb_fail
                total_not_compliant += nb_fail
                statsPerService[service] = stats[service];
                statsPerService[service]['percentage'] = 100 * nb_compliant / stats[service].Total
                statsPerService[service]['fail'] = nb_fail
            }


            const metrics = [
                {label: 'AWS Services', value: Object.keys(stats).length},
                {label: 'Nb Assessments', value: total},
                {label: 'Nb fail assessments', value: total_not_compliant}
            ];
            console.info(statsPerService)
            console.info(metrics)
            this.setState({
                data: statsPerService,
                percentageOfComplianceForApplication: (100 * (total - total_not_compliant) / total).toFixed(2),
                metrics: metrics,
                loading: false
            });
        });

    }

    render() {
        return (
            <Grid container>
                <Grid item xs={12} md={12}>
                    <h3>
                        <ComplianceIcon className="icon-page"/>
                        <span>Compliance overview</span>
                    </h3>
                    <hr/>
                </Grid>
                <Grid item xs={12} md={12}>
                    {!this.state.loading ?
                        (
                            <>
                                <ProgressBar
                                    percentage={this.state.percentageOfComplianceForApplication}
                                    color={getProgressBarColorType(this.state.percentageOfComplianceForApplication)}
                                    height="60"
                                    justify={'center'}
                                />
                                <br/>
                                <MetricsOfCompliance metrics={this.state.metrics}/>
                                <hr/>
                                {
                                    Object.keys(this.state.data).map(service => {
                                        const stats = this.state.data[service];
                                        let percentage = Number(stats.percentage.toFixed(2));

                                        const uniqKey = (new Date()).getTime() * random(1, 999999);
                                        return (
                                            <>
                                                <div id={uniqKey} key={uniqKey}>
                                                    <CardContent>
                                                        <Card>
                                                            <ProgressBar
                                                                percentage={percentage}
                                                                color={getProgressBarColorType(percentage)}
                                                                height="30"
                                                            >
                                                                <div>{service}</div>
                                                            </ProgressBar>

                                                            <CardContent>
                                                                Total assessment: {stats.Total} <br/>
                                                                <><span>Non compliant: {stats.fail}</span> <br/></>
                                                            </CardContent>
                                                        </Card>
                                                    </CardContent>
                                                </div>
                                            </>
                                        );
                                    })
                                }
                            </>
                        ) : (
                            <>
                                <Loading/>
                                <p style={{textAlign: 'center'}}>
                                    <span>Loading compliance results...</span>
                                    {this.state.loadingNumberOfServices > 0 &&
                                    <span> ({this.state.loadingLoadedServices}/{this.state.loadingNumberOfServices} services)</span>
                                    }
                                </p>
                            </>
                        )
                    }
                </Grid>
            </Grid>
        );
    }
}

export default withContext(ComplianceOverview);
