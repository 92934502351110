export const LOGBOOK_LOADED = 'LOGBOOK_LOADED';
export const LOGBOOK_LOADING = 'LOGBOOK_LOADING';
export const LOGBOOK_ERROR = 'LOGBOOK_ERROR';
export const LOGBOOK_DELETE_LOGENTRY = 'LOGBOOK_DELETE_LOGENTRY'
export const LOGBOOK_DELETING_LOGENTRY = 'LOGBOOK_DELETING_LOGENTRY'
export const LOGBOOK_DELETED_LOGENTRY = 'LOGENTRY_DELETED_LOGENTRY'
export const COMPLIANCE_RULES_LOADED = 'COMPLIANCE_RULES_LOADED';
export const REMEDIATION_STRATEGY_MODIFYING = 'REMEDIATION_STRATEGY_MODIFYING';
export const REMEDIATION_STRATEGY_MODIFIED = 'REMEDIATION_STRATEGY_MODIFIED';
export const CHANGE_STRATEGY_MODAL_OPENED = 'CHANGE_STRATEGY_MODAL_OPENED';
export const CHANGE_STRATEGY_MODAL_CLOSED = 'CHANGE_STRATEGY_MODAL_CLOSED';
export const ALL_REMEDIATION_ACCOUNTS_LOADED = 'ALL_REMEDIATION_ACCOUNTS_LOADED';
export const ALL_REMEDIATION_ACCOUNTS_LOADING = 'ALL_REMEDIATION_ACCOUNTS_LOADING';