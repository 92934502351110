import {ReactComponent as Logo} from 'logo_blue.svg';
import React from 'react';
import './Login.scss';

function LoginHolder({children}) {
	const appName = process.env.REACT_APP_NAME;

	return <>
        <h3 className="login-title">
        	<Logo className="login-logo"/>
        	<div>
        		{appName}
        	</div>
        </h3>
        {children}
    </>;

}

export default LoginHolder;
