import axios from 'axios';
import moment from 'moment';
import AbstractApiClient, { apiUrl } from './AbstractApiClient';
import { CREDENTIALS_KEY, EXPIRATION_DATE_KEY, USER_KEY } from './ApiServices';
import posthog from 'posthog-js';


class Account extends AbstractApiClient {

    getAccountDetailsForName(awsAccountName) {
        return this._makeRequest({
            path: `/v1/accounts/${awsAccountName}`,
            method: 'GET'
        });
    }
}

class ComplianceRemediationExecution extends AbstractApiClient {

    listSentencesForRule(cerbairRuleId) {
        return this._makeRequest({
            path: `/v1/remediation/sentences/rule/${cerbairRuleId}`,
            method: 'GET'
        });
    }

    launchResourceRemediation(awsAccountId, region, resourceIdentifier, resourceType, cerbairRuleId, cerbairSentenceId) {

        return this._makeRequest({
            path: `/v1/remediation/remediate/resource`,
            method: 'POST',
            data: {
                awsAccountId,
                region,
                resourceIdentifier,
                resourceType,
                cerbairRuleId,
                cerbairSentenceId
            }
        });
    }
}

class ComplianceResults extends AbstractApiClient {

    /**
     * Get compliance result
     *
     * @param {*} aspireCode code of the application
     * @param {*} accountName name of the partner account
     * @param {*} service optionnal the name of the AWS service
     */
    getComplianceResults(aspireCode, accountName, service = null) {
        let path = `/v1/applications/${aspireCode}/accounts/${accountName}/complianceresults`
        if (service) {
            path = path + `?service=${service}`
        }

        return this._makeRequest({
            method: 'GET',
            path: path
        });
    }

    /**
     * Get compliance result
     *
     * @param {*} awsAccountId the aws account id
     * @param {*} service (optionnal) the name of the AWS service
     */
    getComplianceResultsForAccountId(awsAccountId, service = null) {
        let path = `/v1/complianceresults/accounts/${awsAccountId}`
        if (service) {
            path = path + `?service=${service}`
        }

        return this._makeRequest({
            method: 'GET',
            path: path
        });
    }

    getComplianceOverviewResults(aspireCode, accountName) {
        let path = `/v1/applications/${aspireCode}/accounts/${accountName}/compliance/overview/stats`

        return this._makeRequest({
            method: 'GET',
            path: path
        });
    }
}

class ComplianceRules extends AbstractApiClient {

    /**
     * Get all compliances rules
     */
    getAllComplianceRules() {
        return this._makeRequest({
            path: '/v1/compliancerules'
        });
    }

    /**
     * Get the compliance rule with id past in parameter
     *
     * @param {*} ruleId
     * @param {*} contextId
     */
    getComplianceRule(ruleId, contextId) {
        return this._makeRequest({
            path: (contextId === 'technical') ? `/v1/compliancerules/${ruleId}` : `/v1/compliancerules/ruleId/${ruleId}`
        });
    }

    /**
     * Create a compliance rule
     *
     * @param {*} rule
     */
    createComplianceRule(rule) {
        posthog.capture("Create Compliance Rule", rule)

        return this._makeRequest({
            method: 'POST',
            path: '/v1/compliancerules',
            data: rule
        });
    }

    /**
     * Update a compliance rule
     *
     * @param {*} rule to update
     */
    updateComplianceRule(rule) {
        posthog.capture("Update Compliance Rule", rule)

        return this._makeRequest({
            method: 'PUT',
            path: `/v1/compliancerules/${rule.id}`,
            data: rule
        });
    }

    /**
     * Delete compliance rule
     *
     * @param {*} ruleId The rule to delete
     */
    deleteComplianceRule(rule) {
        posthog.capture("Delete Compliance Rule", {rule})

        return this._makeRequest({
            method: 'DELETE',
            path: `/v1/compliancerules/${rule.id}`
        });
    }

    listAvailableServicesInComplianceRules() {
        return this._makeRequest({
            path: '/v1/complianceservices'
        });
    }
}


class PlannedEvents extends AbstractApiClient {
    getAccountPlannedEvents(aspireCode, accountName) {
        return this._makeRequest({
            path: `/v1/applications/${aspireCode}/accounts/${accountName}/planned-events`,
            method: 'GET'
        });
    }

    getApplicationPlannedEvents(aspireCode) {
        return this._makeRequest({
            path: `/v1/applications/${aspireCode}/planned-events`,
            method: 'GET'
        });
    }
}


class MachinesSecurityMaintenance extends AbstractApiClient {

    createMachinesSecurityMaintenanceCampaign(isEmergency, isDryRun) {
        posthog.capture("Create Machines Security Maintenance Campaign", {isEmergency, isDryRun})

        const body = {
            emergency: isEmergency,
            dry_run: isDryRun
        };
        return this._makeRequest({
            path: '/v1/machines-security-maintenance-campaigns',
            method: 'POST',
            data: body
        });
    }

    listAllMachinesSecurityMaintenanceCampaigns() {
        return this._makeRequest({
            path: '/v1/machines-security-maintenance-campaigns/current',
            method: 'GET'
        });
    }

    saveMaintenanceConfiguration(configuration) {
        const data = {
            PROD: { ...configuration['PROD'] },
            'NON_PROD': { ...configuration['NON_PROD'] },
            UNDEFINED: { ...configuration['UNDEFINED'] }
        };

        posthog.capture("Save Global Maintenance Configuration", data)

        return this._makeRequest({
            path: '/v1/maintenance-configuration',
            method: 'POST',
            data
        });
    }

    getMaintenanceConfiguration() {
        return this._makeRequest({
            path: '/v1/maintenance-configuration'
        });
    }

    saveMachineSecurityMaintenanceConfigurationForAccountName(aspireCode, awsAccountName, configuration) {
        posthog.capture("Save Account Maintenance Configuration", {aspireCode, awsAccountName, configuration})
        return this._makeRequest({
            path: `/v1/applications/${aspireCode}/accounts/${awsAccountName}/maintenance-configuration`,
            method: 'POST',
            data: configuration
        });
    }

    getMachineSecurityMaintenanceConfigurationForAccountName(aspireCode, awsAccountName) {
        return this._makeRequest({
            path: `/v1/applications/${aspireCode}/accounts/${awsAccountName}/maintenance-configuration`,
        });
    }

    listEC2InstancesForAWSAccount(awsAccountId, region) {
        return this._makeRequest({
            path: `/v1/machines-security-maintenance/manual-execution/ec2-instances/account/${awsAccountId}/region/${region}`
        });
    }

    listEC2InstancesForApplication(aspireCode, region) {
        return this._makeRequest({
            path: `/v1/machines-security-maintenance/manual-execution/ec2-instances/application/${aspireCode}/region/${region}`
        });
    }

}


class ExportControlDisclaimer extends AbstractApiClient {

    isCurrentUserInExportControlApplication() {
        return this._makeRequest({
            path: '/v1/users/current/applications/in-export-control'
        });
    }

    getLastDisclaimerRecordForCurrentUser() {
        return this._makeRequest({
            path: '/v1/users/current/disclaimer-agreements/last'
        })
    }

    recordDisclaimerRecordForCurrentUser(isAccepted) {
        return this._makeRequest({
            path: `/v1/users/current/disclaimer-agreement`,
            method: 'POST',
            data: {
                accepted: isAccepted
            }
        });
    }

}


class ComplianceExceptions extends AbstractApiClient {

    listNonComplianceBeingRemediatedWithoutFullException(complianceRuleId) {

        if (!complianceRuleId) return new Promise((resolve) => { resolve({ data: [] }) });
        return this._makeRequest({
            path: `/v1/compliance/overview/rules/${complianceRuleId}`,
            method: 'GET'
        })
            .then(response => {
                response.data = response.data.filter((compliance) => {
                    return compliance.pcpRemediation === 'ON' || (compliance.pcpRemediation === 'PARTIAL' && !compliance.pcpRemediationRules.includes(complianceRuleId))
                })
                return response
            })
            ;
    }

    getRemediationExceptionsForAccount(awsAccountId) {
        return this._makeRequest({
            path: `/v1/remediationcontrol/accounts/${awsAccountId}`,
            method: 'GET'
        });
    }

    savePartialRemediationConfiguration(awsAccountId, compliancesRulesIds, clientRequest, securityRational) {
        posthog.capture("Save Partial Remediation Configuration", {awsAccountId, compliancesRulesIds, clientRequest, securityRational})

        return this._makeRequest({
            path: `/v1/remediationcontrol/accounts/${awsAccountId}/rules`,
            method: 'POST',
            data: { aws_account_id: awsAccountId, rules_id: JSON.stringify(compliancesRulesIds), client_request: clientRequest, security_rational: securityRational }
        });
    }
}


/**
 * Provides services of public cloud platform api.
 */
export class ApiServicesClient extends AbstractApiClient {
    MachinesSecurityMaintenance = new MachinesSecurityMaintenance()
    PlannedEvents = new PlannedEvents()
    ExportControlDisclaimer = new ExportControlDisclaimer()
    ComplianceRemediationExecution = new ComplianceRemediationExecution()
    ComplianceRules = new ComplianceRules()
    ComplianceResults = new ComplianceResults()
    ComplianceExceptions = new ComplianceExceptions()
    Account = new Account()

    async getLogbook() {
        const response = await this._makeRequest({
            path: '/v1/logbook'
        })

        return response.data.map(logentry => {
            return {
                ...JSON.parse(logentry.payload),
                id: logentry.id
            }
        })
    };

    addLogEntry(logEntry) {
        return this._makeRequest({
            path: '/v1/logbook',
            method: 'POST',
            data: logEntry
        });
    };

    deleteLogEntry(logEntry) {
        return this._makeRequest({
            path: `/v1/logbook/${logEntry.id}`,
            method: 'DELETE',
        })
    }

    /**
     * Authenticate with login and password past in parameter
     * @param {*} login
     * @param {*} password
     */
    async authenticateWithEmailPassword(login, password) {
        const res = await axios.post(`${apiUrl}/v1/authenticate`, {
            login,
            password,
            type: 'API'
        });
        const responsePayload = res.data;
        const user = responsePayload.data.user;
        if (user) {
            localStorage.setItem(USER_KEY, JSON.stringify(user));
        }

        const credentials = responsePayload.credentials;
        if (credentials) {
            localStorage.setItem(CREDENTIALS_KEY, JSON.stringify(credentials));
        }

        return { devices: responsePayload.data.devices, stateToken: responsePayload.data.stateToken };
    }

    async authenticateWithMFA(deviceId, otpToken, stateToken) {
        let user = JSON.parse(localStorage.getItem(USER_KEY));
        const email = user.email;

        const res = await axios.post(`${apiUrl}/v1/authenticate/mfa`, {
            user: {
                email
            },
            device: {
                id: deviceId
            },
            stateToken,
            otpToken,
            type: 'API'
        });
        const responsePayload = res.data;

        user = responsePayload.data.user;
        if (user) {
            localStorage.setItem(USER_KEY, JSON.stringify(user));
        }

        const credentials = responsePayload.credentials;
        if (credentials) {
            localStorage.setItem(CREDENTIALS_KEY, JSON.stringify(credentials));
        }

        const expirationDate = moment.utc().add(1, 'hours');
        localStorage.setItem(EXPIRATION_DATE_KEY, expirationDate.format());

        return credentials;
    }

    /**
     * Get all applications
     */
    getAllApplications() {
        return this._makeRequest({
            path: '/v1/applications'
        });
    }

    /**
     * Get current user applications
     */
    getCurrentUserApplications() {
        return this._makeRequest({
            path: '/v1/users/current/applications'
        });
    }

    /**
     * Get All users
     */
    getAllUsers() {
        return this._makeRequest({
            path: '/v1/users'
        });
    }

    /**
     * Get all accounts
     */
    getAllAccounts() {
        return this._makeRequest({
            path: '/v1/accounts'
        });
    }

    /**
     * Get all amis
     */
    getAllAmis() {
        return this._makeRequest({
            path: '/v1/amis'
        });
    }

    /**
     * Delete an ami
     *
     * @param {*} amiId id of the ami to delete
     */
    deleteAmi(amiId) {
        return this._makeRequest({
            method: 'DELETE',
            path: `/v1/amis/${amiId}`
        });
    }

    /**
     * Get application information
     *
     * @param {*} aspireCode code of the application to get
     */
    getApplication(aspireCode) {
        aspireCode = decodeURIComponent(aspireCode);

        const request = {
            path: `/v1/applications/${aspireCode}`
        };

        return this._makeRequest(request);
    }

    /**
     * Get application information from alfabet
     *
     * @param {*} aspireCode code of the application to get
     */

    getApplicationsInfo(aspireCode) {
        aspireCode = decodeURIComponent(aspireCode);

        const request = {
            path: `/v1/alfabet/applications/${aspireCode}`
        }

        return this._makeRequest(request);
    }

    /**
     * Get applications for specific user
     *
     * @param {*} identifier
     */
    getApplicationsForUser(identifier) {
        identifier = decodeURIComponent(identifier);

        const request = {
            path: `/v1/users/${identifier}/applications`
        };

        return this._makeRequest(request);
    }

    /**
     * Get applications for specific account
     *
     * @param {*} identifier
     */
    getApplicationsForAccount(identifier) {
        identifier = decodeURIComponent(identifier);

        const request = {
            path: `/v1/accounts/${identifier}/applications`
        };

        return this._makeRequest(request);
    }

    /**
     * Delete application with the aspire code past in parameter
     * @param {*} aspireCode code of the application to delete
     */
    deleteApplication(aspireCode) {
        return this._makeRequest({
            path: `/v1/applications/${aspireCode}`,
            method: 'DELETE'
        });
    }

    /**
     * Get all user of an application
     *
     * @param {*} aspireCode code of the application
     */
    getUsers(aspireCode) {
        aspireCode = decodeURIComponent(aspireCode);

        const request = {
            path: `/v1/applications/${aspireCode}/users`
        };
        return this._makeRequest(request);
    }

    /**
     * Get user information
     *
     * @param {*} appId id of the app
     * @param {*} userId id of the user
     */
    getUser(appId, userId) {
        const request = {
            path: `/v1/applications/${appId}/users/${userId}`
        };
        return this._makeRequest(request);
    }

    /**
     * Get all accounts of an application
     *
     * @param {*} aspireCode code of the application
     */
    getAccounts(aspireCode) {
        aspireCode = decodeURIComponent(aspireCode);
        const request = {
            path: `/v1/applications/${aspireCode}/accounts`
        };
        return this._makeRequest(request).then(
            promise => promise.data.map(data => {
                return {
                    has_cerbair_remediation: data.account.has_cerbair_remediation,
                    accountId: data.account.accountId,
                    name: data.account.name,
                    type: data.type
                };
            })
        );
    }

    /**
     * Get all user roles for application past in parameter.
     * @param {*} aspireCode
     * @param {*} userId
     */
    getAwsRolesOfUser(aspireCode, userId) {
        const request = {
            path: `/v1/applications/${aspireCode}/users/${userId}/awsroles`
        };
        return this._makeRequest(request);
    }

    getAllAwsRolesOfUser(userId) {
        const request = {
            path: `/v1/users/${userId}/awsroles`
        };
        return this._makeRequest(request);
    }

    getUserOneloginIamRoles(userId) {
        const request = {
            path: `/v1/users/${userId}/onelogin/iamroles`
        };
        return this._makeRequest(request);
    }

    fixUserOneloginIamRoles(userId) {
        const request = {
            path: `/v1/users/${userId}/onelogin/iamroles/platformroles`,
            method: 'POST',
            data: {}
        };
        return this._makeRequest(request);
    }

    getAllAwsRolesCurrentUser() {
        const request = {
            path: `/v1/users/current/awsroles`
        };
        return this._makeRequest(request);
    }

    /**
     * Create an application
     * @param {*} application to create
     */
    createApplication(application) {
        return this._makeRequest({
            method: 'POST',
            path: '/v1/applications',
            data: application
        });
    }

    /**
     * Update an application
     * @param {*} application to update
     */
    updateApplication(application) {
        return this._makeRequest({
            method: 'PUT',
            path: `/v1/applications/${application.aspireCode}`,
            data: application
        });
    }

    /**
     * Create user past in parameter
     * @param {*} user to create
     */
    createUser(user) {
        const request = {
            method: 'POST',
            path: '/v1/users',
            data: user
        };
        return this._makeRequest(request);
    }

    updateUser(userId, user) {
        return this._makeRequest({
            path: `/v1/users/${userId}`,
            method: 'PUT',
            data: user
        });
    }

    deleteUser(userId) {
        return this._makeRequest({
            path: `/v1/users/${userId}`,
            method: 'DELETE'
        });
    }

    /**
     * Attach an user to an application
     *
     * @param {*} aspireCode code of the application
     * @param {*} userId id of the user to attach
     * @param {*} authorities array of authorities like [{"name":"ROLE_APP_DEVELOPER"}]
     */
    attachApplicationToUser(aspireCode, userId, authorities, send_notification = 'false') {
        const request = {
            method: 'POST',
            path: `/v1/applications/${aspireCode}/users`,
            data: {
                user: {
                    id: userId
                },
                authorities,
                send_notification
            }
        };
        return this._makeRequest(request);
    }

    /**
     * Attach an application to an account
     *
     * @param {*} aspireCode code of the application
     * @param {*} accountName name of the account
     * @param {*} type PROD or NON_PROD
     */
    attachApplicationToAccount(aspireCode, accountName, type) {
        return this._makeRequest({
            method: 'POST',
            path: `/v1/applications/${aspireCode}/accounts`,
            data: {
                account: {
                    name: accountName
                },
                type
            }
        });
    }

    /**
     * Detach an application from an account
     *
     * @param {*} aspireCode code of the application
     * @param {*} accountName code of the account
     */
    detachAccountFromApplication(aspireCode, accountName) {
        return this._makeRequest({
            method: 'DELETE',
            path: `/v1/applications/${aspireCode}/accounts/${accountName}`
        });
    }

    /**
     * Import an account into an application
     * @param {*} account object containing all account information
     */
    importAccountIntoApplication(account) {
        return this._makeRequest({
            method: 'POST',
            path: '/v1/accounts/import',
            data: account
        });
    }

    /**
     * Attach a user to an application user
     *
     * @param {*} aspireCode code of the application
     * @param {*} userId id of the user
     * @param {*} arn the arn of the role
     */
    attachAwsRoleToUserInApplication(aspireCode, userId, arn) {
        return this._makeRequest({
            method: 'POST',
            path: `/v1/applications/${aspireCode}/users/${userId}/awsroles`,
            data: { arn: arn }
        });
    }

    /**
     * Detach a user from an application user.
     */
    detachAwsRoleFromUserInApplication(aspireCode, userId, arn) {
        return this._makeRequest({
            method: 'DELETE',
            path: `/v1/applications/${aspireCode}/users/${userId}/awsroles/${encodeURIComponent(arn)}`
        });
    }

    /**
     * Update application user
     *
     * @param {*} aspireCode code of the application
     * @param {*} userId user id
     * @param {*} authorities authorities to update
     */
    updateApplicationUser(aspireCode, userId, authorities, send_notification = 'false') {
        const request = {
            method: 'PUT',
            path: `/v1/applications/${aspireCode}/users/${userId}`,
            data: {
                authorities,
                send_notification
            }
        };
        return this._makeRequest(request);
    }

    getApplicationAccountAwsRoles(aspireCode, accountName) {
        const request = {
            path: `/v1/applications/${aspireCode}/accounts/${accountName}/awsroles`
        };
        return this._makeRequest(request);
    }


    /**
     * Get all AWS Services
     */
    getAWSServices() {
        return this._makeRequest({
            path: '/v1/awsservices'
        });
    }

    /**
     * Get all Organizations
     */
    getOrganizations() {
        return this._makeRequest({
            path: '/v1/organizations'
        });
    }

    /**
     * Create an organization
     */
    createOrganization(name, awsid) {
        return this._makeRequest({
            method: 'POST',
            path: '/v1/organizations',
            data: {
                name,
                awsid
            }
        });
    }

    /**
     * Update an organization
     */
    updateOrganization(id, name, awsid) {
        return this._makeRequest({
            method: 'PUT',
            path: `/v1/organizations/${id}`,
            data: {
                name,
                awsid
            }
        });
    }

    /**
     * Delete an organization
     */
    deleteOrganization(id) {
        return this._makeRequest({
            method: 'DELETE',
            path: `/v1/organizations/${id}`
        });
    }


    /**
     * Detach an user from an application
     * @param {*} aspireCode code of the application
     * @param {*} userId id of the user
     */
    detachAnUserFromAnApplication(aspireCode, userId) {
        const request = {
            method: 'DELETE',
            path: `/v1/applications/${aspireCode}/users/${userId}`
        };
        return this._makeRequest(request);
    }


    /**
     * Create a platform with the account name past in parameter
     *
     * @param {*} accountName account name
     */
    createPlatform(accountName) {
        // this request is too long to handle (receive timeout http code 504)
        axios.skip504 = true;
        return this._makeRequest({
            method: 'POST',
            path: '/v1/accounts/platform',
            data: {
                account_name: accountName
            }
        });
    }



    /** Add an account name to an application
     *
     * @param {*} aspireCode code of the application
     * @param {*} accountName name of the partner account
     */
    addPartner(aspireCode, accountName) {
        // this request is too long to handle (receive timeout http code 504)
        axios.skip504 = true;
        return this._makeRequest({
            method: 'POST',
            path: '/v1/accounts/partners',
            data: {
                account_name: accountName,
                aspire_code: aspireCode
            }
        });
    }


    //
    //
    //  TEAMS MANAGEMENT
    //
    //

    /**
     * Get current user teams
     */
    getCurrentUserTeams() {
        return this._makeRequest({
            path: '/v1/users/current/teams'
        });
    }


    /**
     * Get all the teams
     */
    getAllTeams() {
        return this._makeRequest({
            path: '/v1/teams'
        });
    }

    /**
     * Get a team
     *
     * @param {*} teamId the team id to get
     */
    getTeam(teamId) {
        return this._makeRequest({
            path: '/v1/teams/' + teamId
        });
    }

    /** Create a team
     *
     * @param {*} team the team to create
     */
    createTeam(team) {
        posthog.capture("Create Team", team)

        return this._makeRequest({
            path: '/v1/teams',
            method: 'POST',
            data: team
        });
    }

    /** Update a team
     *
     * @param {*} team the updated team object
     */
    updateTeam(team) {
        posthog.capture("Update Team", team)

        return this._makeRequest({
            path: '/v1/teams/' + team.id,
            method: 'PUT',
            data: team
        });
    }

    /** Delete a team
     *
     * @param {*} teamId the teamId to be delete
     */
    deleteTeam(teamId) {
        posthog.capture("Remove Team", {teamId})

        return this._makeRequest({
            path: '/v1/teams/' + teamId,
            method: 'DELETE'
        });
    }

    /** Add a user in the owners of the team
     *
     * @param {*} teamId the id of the team to delete
     * @param {*} userId the id of the user that should be add in the team owners
     */
    addTeamOwner(teamId, userId) {
        posthog.capture("Add Team Owner", {teamId, userId})

        return this._makeRequest({
            path: '/v1/teams/' + teamId + '/owners/' + userId,
            method: 'POST',
            data: {}
        });
    }

    /** Remove a user from the owners of the team
     *
     * @param {*} teamId the id of the team to delete
     * @param {*} userId the id of the user that should be removed from the team owners
     */
    removeTeamOwner(teamId, userId) {
        posthog.capture("Remove Team Owner", {teamId, userId})

        return this._makeRequest({
            path: '/v1/teams/' + teamId + '/owners/' + userId,
            method: 'DELETE'
        });
    }

    /** Add a user as in the members of the team
     *
     * @param {*} teamId the id of the team to delete
     * @param {*} userId the id of the user that should be add in the members of the team
     */
    addTeamMember(teamId, userId) {
        posthog.capture("Add Team Member", {teamId, userId})

        return this._makeRequest({
            path: '/v1/teams/' + teamId + '/members/' + userId,
            method: 'POST',
            data: {}
        });
    }

    /** Remove a user from the members of the team
     *
     * @param {*} teamId the id of the team to delete
     * @param {*} userId the id of the user that should be removed from the members of the team
     */
    removeTeamMember(teamId, userId) {
        posthog.capture("Remove Team Member", {teamId, userId})

        return this._makeRequest({
            path: '/v1/teams/' + teamId + '/members/' + userId,
            method: 'DELETE'
        });
    }
}


