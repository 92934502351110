import React, {Component} from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Checkbox} from 'horizon-components-react';

class CheckboxWithChangingLabel extends Component {
	constructor(props) {
		super(props);

		this.state = {
			checked: props.checked,
			disabled: props.disabled
		};

		if (props.checked) {
			this.state.label = props.enabledLabel;
		} else {
			this.state.label = props.disabledLabel;
		}
	}

	handleToggleChange(event, checked) {
		this.updateLabelWithCheckStatus(checked);
		if (this.props.onChange) {
			this.props.onChange(event, checked);
		}
	}

	updateLabelWithCheckStatus(checked) {
		let newSwitchLabel = this.props.disabledLabel;
		if (checked) {
			newSwitchLabel = this.props.enabledLabel;
		}
		this.setState({
			label: newSwitchLabel,
			checked: checked
		});
	}

	render() {
		return <FormControlLabel
			control={<Checkbox checked={this.state.checked} disabled={this.state.disabled} onChange={(event) => { this.handleToggleChange(event, event.target.checked) }} />}
			label={this.state.label}/>
	}
}

export default CheckboxWithChangingLabel;