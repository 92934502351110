import React, {useState} from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import StepButton from '@material-ui/core/StepButton';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import {ListItemAvatar, StepLabel} from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import OffValidation from 'domains/security/component/RemediationStrategies/OffValidation';
import PartialValidation from 'domains/security/component/RemediationStrategies/PartialValidation';
import SelectRulesStrategy from 'domains/security/component/RemediationStrategies/SelectRulesStrategy';
import {OnValidation} from 'domains/security/component/RemediationStrategies/OnValidation';
import {useSelector} from 'react-redux';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

export const Strategy = {
	ON: 'ON',
	OFF: 'OFF',
	PARTIAL: 'PARTIAL'
};


export default function RemediationStrategySelector({account}) {
	const rules = useSelector(state => state.remediationControl.rules);
	const alreadySelectedRulesEnhancedWithDescription = rules.filter(rule => account.rules_id.includes(rule.complianceId));

	const [activeStep, setActiveStep] = useState(0);
	const [strategy, setStrategy] = useState(account.remediation_strategy);
	const [rulesValidated, setRulesValidated] = useState(false);
	const [selectedRules, setSelectedRules] = useState([...alreadySelectedRulesEnhancedWithDescription]);
	const selectedRulesAreExactlyTheSameAsPrevious = selectedRules.map(r => r.complianceId).sort().join(',') === account.rules_id.sort().join(',');
	const chooseStrategy = (strategy) => {
		setStrategy(strategy);
		setActiveStep(1);

		if (strategy !== Strategy.PARTIAL) {
			setRulesValidated(false);
		}
	};
	const choices = [
		{strategy: Strategy.ON, color: 'green', icon: <CheckCircleOutlinedIcon/>, text: 'Remediation fully active'},
		{strategy: Strategy.OFF, color: 'red', icon: <ErrorOutlineOutlinedIcon/>, text: 'Remediation inactive'},
		{strategy: Strategy.PARTIAL, color: 'orange', icon: <ReportProblemOutlinedIcon/>, text: 'Remediation inactive on certain rules'}];
	return (
		<div>
			<Stepper activeStep={activeStep} orientation="vertical">
				<Step key='Choose remediation strategy'>
					<StepButton onClick={() => setActiveStep(0)}>Choose remediation strategy</StepButton>
					<StepContent>
						<List>
							{choices
								.map(choice =>
									<ListItem disabled={choice.strategy === account.remediation_strategy && account.remediation_strategy !== Strategy.PARTIAL} button
										selected={strategy === choice.strategy}
										onClick={() => chooseStrategy(choice.strategy)}>
										<ListItemAvatar style={{color: choice.color, textAlign: 'center'}}>
											{choice.icon}
											<Typography style={{fontWeight: 'bold'}}>{choice.strategy}</Typography>
										</ListItemAvatar>
										<ListItemText>
											{choice.text}
										</ListItemText>
									</ListItem>
								)}
						</List>
					</StepContent>
				</Step>
				{strategy === Strategy.PARTIAL &&
                <Step completed={rulesValidated} key='Select rules'>
                	<StepButton onClick={() => setActiveStep(1)} disabled={strategy !== Strategy.PARTIAL}>Select rules</StepButton>
                	<StepContent>
                		<SelectRulesStrategy
                			disabledValidationButton={(selectedRulesAreExactlyTheSameAsPrevious && account.remediation_strategy === Strategy.PARTIAL) || selectedRules.length === 0}
                			onValidationButtonClick={() => {
                				setRulesValidated(true);
                				setActiveStep(2);
                			}}
                			currentStrategy={account.remediation_strategy}
                			alreadySelectedRules={account.rules_id}
                			selectedRules={selectedRules}
                			onSelectedRulesChanged={newSetOfRules => setSelectedRules([...newSetOfRules])}/>
                	</StepContent>
                </Step>
				}
				<Step key='Validation'>
					<StepLabel>Validation</StepLabel>
					<StepContent>
						{strategy === Strategy.ON && <OnValidation account={account}/>}
						{strategy === Strategy.OFF && <OffValidation account={account}/>}
						{rulesValidated && <PartialValidation selectedRulesAreExactlyTheSameAsPrevious={selectedRulesAreExactlyTheSameAsPrevious} rules={selectedRules} account={account}/>}
					</StepContent>
				</Step>
			</Stepper>
		</div>
	);
}
