import React, {useEffect} from 'react';
import {Grid} from '@material-ui/core';
import {Loading} from 'horizon-components-react';
import AccountsSearchList from '../component/AccountsSearchList';
import {useDispatch, useSelector} from 'react-redux';
import {loadAllRemediationAccounts, loadComplianceRules} from 'state/actions';
function AccountsRemediationControlPage() {
	const dispatch = useDispatch();
	const loading = useSelector(state => state.remediationControl.reloadRemediationControlPage);
	const accounts = useSelector(state => state.remediationControl.accountsRemediationControl);

	useEffect(() => {
		dispatch(loadComplianceRules());
		dispatch(loadAllRemediationAccounts());
	}, [dispatch]);

	return (
		<>
			<h3>Control accounts remediation</h3>
			<hr/>
			{loading ?
				(<>
					<Grid container
						direction="row"
						justify="center"
					>
						<Loading>Loading</Loading>
					</Grid></>) :
				(
					<>
						<Grid container
							direction="row"
							justify="center">
							<Grid item lg={11}>
								<AccountsSearchList accounts={accounts}/>
							</Grid>
						</Grid>
					</>
				)
			}
		</>);
}

export default AccountsRemediationControlPage;
