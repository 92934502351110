import { Toolbar } from '@material-ui/core';
import { AppBar } from 'horizon-components-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ReactComponent as Logo } from '../../logo.svg';
import { withContext } from '../../store/ContextStore';
import './Header.scss';
import UserHeader from './UserHeader';

class Header extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: false
		};
		this.toggle = this.toggle.bind(this);
		this.goHome = this.goHome.bind(this);
	}

	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}

	goHome() {
		this.props.history.push('');
	}

	render() {
		return (
			<AppBar static={true} className="pcp-header">
				<Toolbar>
					<h5 className="pcp-header-title" onClick={this.goHome}>
						<Logo className="logo"/>
						<span>{process.env.REACT_APP_NAME}</span>
                        &nbsp;
						<small className="version">{process.env.REACT_APP_VERSION}</small>
					</h5>
					<div className="pcp-header-tools">
						<UserHeader/>
					</div>
				</Toolbar>
			</AppBar>
		);
	}
}

export default withRouter(withContext(Header));
