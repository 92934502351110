import React, {useState} from 'react';
import {Button} from 'horizon-components-react';
import {TextField} from '@material-ui/core';
import api from 'services/ApiServices';
import {Form} from 'react-final-form';

function LoginWithEmailPassword({whenAuthenticated}) {
	const [loading, setLoading] = useState(false);
	const [login, setLogin] = useState('');
	const [password, setPassword] = useState('');

	function onSubmit() {
		setLoading(true);
		api.authenticateWithEmailPassword(login, password)
			.then(({devices, stateToken}) => whenAuthenticated(devices, stateToken))
			.catch(() => setLoading(false));
	}

	return <>
        <Form
        	onSubmit={onSubmit}
        	render={({
        		handleSubmit,
        		form,
        		submitting,
        		pristine,
        		values
        	}) => (
        		<form onSubmit={handleSubmit}>
        			<TextField
        				value={login}
        				onChange={(event) => setLogin(event.target.value)}
        				inputProps={{style: {fontSize: 20, width: 500, textAlign: 'center'}}}
        				name="login"
        				variant="outlined"
        				label="Email"
        				placeholder="john.doe@airbus.com"
        				autoFocus
        			/>
        			<br/>
        			<br/>
        			<TextField
        				value={password}
        				type='password'
        				onChange={(event) => setPassword(event.target.value)}
        				inputProps={{style: {fontSize: 20, width: 500, textAlign: 'center'}}}
        				name="password"
        				variant="outlined"
        				label="Password"
        			/>
        			<br/>
        			<br/>
        			<Button submit primary style={{fontSize: 20, width: 528}} disabled={loading || !(login.length !== 0 && password.length !== 0)}>
                        Login
        			</Button>
        		</form>
        	)}
        />
    </>;

}

export default LoginWithEmailPassword;
