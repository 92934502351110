import {GridCol, GridContainer, GridRow} from 'horizon-components-react';
import React, {Component} from 'react';
import EditRule from './EditRule';
import Chip from '@material-ui/core/Chip';
import {Redirect} from 'react-router-dom';

const ruleStatusOptions = [
	{label: 'Draft', value: 'draft'},
	{label: 'Created', value: 'created'},
	{label: 'Rejected', value: 'rejected'},
	{label: 'In production', value: 'implemented'},
	{label: 'Validated', value: 'validated'},
	{label: 'Not applicable', value: 'not_applicable'}
];
const colorsForRisk = [
	{risk: 'LOW', color: '#004b7c'},
	{risk: 'MEDIUM', color: '#dc9135'},
	{risk: 'HIGH', color: '#e4002b'},
	{risk: 'CRITICAL', color: '#212529'}
];

class ReadOnly extends Component {


	constructor(props) {
		super(props);

		this.state = {
			status: '',
			colorForRisk: ''
		};

		this.getIconForAwsService = this.getIconForAwsService.bind(this);
	}

	componentDidMount() {
		if (this.props.rule.severity !== undefined) {
			let colorForRisk = colorsForRisk.find(
				option => option.risk === this.props.rule.severity
			);

			this.setState({
				colorForRisk: colorForRisk.color
			});
		}

		if (this.props.rule.status) {
			let status = ruleStatusOptions.find(
				option => option.value === this.props.rule.status
			);

			this.setState({
				status: status.label
			});
		}
	}

	getIconForAwsService() {
		try {
			return require('../../../../awsicons/' + this.props.rule.service.toLowerCase() + '.png');
		} catch (e) {
			return require('../../../../awsicons/aws.png');
		}
	}


	render() {
		return (
			<GridContainer>
				<GridRow>
					<GridCol xl="2" md="12">
						<GridRow>
							<div style={{display: 'inline-block', textAlign: 'center'}}>

								<img src={this.getIconForAwsService()} alt={this.props.rule.service}/>
								<span style={{
									display: 'block',
									fontSize: '20px',
									color: 'white',
									backgroundColor: 'black'
								}}>{this.props.rule.service}</span>
							</div>
						</GridRow>
					</GridCol>
					<GridCol xl="10" md="12">
						<GridRow>
							<GridCol xl="9">
								<strong>{this.props.rule.complianceId}</strong>
							</GridCol>
							<GridCol xl="3">
								{this.state.status !== '' &&
                                <Chip label={this.state.status}/>
								}
								<Chip label={this.props.rule.severity}
									style={{color: 'white', backgroundColor: this.state.colorForRisk}}/>
							</GridCol>
						</GridRow>
						<br/>
						<div style={{fontSize: '18px'}}>
							<GridCol>
								<GridRow>
									{this.props.rule.riskType !== null ? (
										<p>
											<span style={{fontSize: '13px', color: '#9aa5b8'}}>Risk type</span>
											<b> {this.props.rule.riskType} </b> <span
												style={{fontSize: '13px', color: '#9aa5b8'}}>on this resource</span>
											<b> {this.props.rule.resourceType}</b>
										</p>
									) : (this.props.rule.resourceType)
									}
								</GridRow>
								<br/>
								<GridRow>
									{this.props.rule.description}
								</GridRow>
								<br/>
								<GridRow>
									<div style={{color: '#757575'}}>
										{this.props.rule.rationale}
									</div>
								</GridRow>
								<br/>
								<hr/>

								{this.props.rule.hasAutomatedRemediation &&
									<GridRow>
										<p>
											<b style={{color: '#b52900'}}>Automated remediation enabled</b>
										</p>
									</GridRow>
								}
								<GridRow>
									<p style={{color: '#757575'}}>
										<b>Remediation procedure</b>
										<br/>
										{this.props.rule.remediation}
									</p>
								</GridRow>
								<br/>
								<hr/>
								<div style={{color: '#00235e', display: 'block', textAlign: 'center'}}>
									{this.props.rule.remarks}
								</div>

							</GridCol>
						</div>
					</GridCol>
				</GridRow>
			</GridContainer>
		);
	}
};

class RuleCard
	extends Component {
	constructor(props) {
		super(props);
		this.state = {
			goBackToRules: false,
			underEdition: false
		};
		this.toggleEdition = this.toggleEdition.bind(this);
		this.goBackToRules = this.goBackToRules.bind(this);
	}


	toggleEdition(callback) {
		const newEditionState = !this.state.underEdition;
		this.setState(
			{
				underEdition: newEditionState
			},
			callback
		);
	}


	goBackToRules() {
		this.toggleEdition();
		this.setState({goBackToRules: true});
	}

	render() {
		const rule = this.props.rule;
		if (this.state.underEdition) {
			return (
				<EditRule
					rule={rule}
					cancel={this.toggleEdition}
					reload={this.goBackToRules}
				/>
			);
		}
		if (this.state.goBackToRules) {
			return <Redirect
				to={{
					pathname: '/compliancerules'
				}}
			/>;
		}
		return <ReadOnly rule={rule}/>;
	}
}

const RuleCardWithRef = React.forwardRef((props, ref) => (
	<RuleCard ref={ref} {...props} />
));

export default RuleCardWithRef;
