import LoginHolder from 'components/login/LoginHolder';
import LoginWithEmailPassword from 'components/login/LoginWithEmailPassword';
import LoginWithMfa from 'components/login/LoginWithMfa';
import React, { useState } from 'react';
import Countdown from 'react-countdown';
import { PcpTimeEvents } from 'utils/PcpTimeEvents';


function LoginSystem({refreshLoginState}) {
	const [devices, setDevices] = useState([]);
	const [stateToken, setStateToken] = useState(null);

	const authenticatedWithEmailPassword = (devices, stateToken) => {
		if(!devices) {
			// No MFA required
			refreshLoginState()
		}
		else {
			setDevices(devices);
			setStateToken(stateToken);
		}
	};
	const goToLoginEmailPassword = () => {
		setDevices([]);
		setStateToken(null);
	};
	const isFirstStep = devices.length === 0;
	const countDown = <Countdown
		date={Date.now() + PcpTimeEvents.STATE_TOKEN_EXPIRED}
		onComplete={goToLoginEmailPassword}
		renderer={props => <div>{props.total / 1000} seconds</div>}
	/>;

	return isFirstStep ? <LoginHolder><LoginWithEmailPassword whenAuthenticated={authenticatedWithEmailPassword}/></LoginHolder> :
		<LoginHolder><LoginWithMfa countDown={countDown} devices={devices} stateToken={stateToken} whenAuthenticatedWithMfa={() => refreshLoginState()}/></LoginHolder>;
}

export default LoginSystem;
