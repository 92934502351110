import React from 'react';
import ReactMarkdown from 'react-markdown';
import "./ExportControlDisclaimerText.scss"
import * as classnames from 'classnames';

function ExportControlDisclaimerText() {

	const input = `Welcome to Airbus Public Cloud.

Airbus Public Cloud Platform is an IT platform made available by Airbus for its own business purposes and its customers 
under applicable General Terms and Conditions for their own business purposes.

Access from these countries is restricted regardless of network connection: Crimea Region of the Ukraine, Cuba, Iran, 
North Korea, Syria, Sudan.

Through this platform, you may have been granted some access to classified data. It is your responsibility to use them 
accordingly in the frame of your professional activity.

By accessing and using __**Airbus Public Cloud Platform**__, you commit to comply with the Airbus Data Governance 
requirements and in particular:
- those applicable to Classified Data as described [here](https://sites.google.com/airbus.com/data-governance),
- it remains forbidden to upload sensitive personal data, private data (i.e. not for professional purposes), 
export controlled military or assimilated data (i.e. ITAR, European national military, EAR 600 and 515 series), and 
Airbus “RED” data,
- handling allowed export controlled data and personal data is only allowed for users and purposes authorized by Airbus Data 
Officers & Airbus LOE, as explained on the onboarding process available [here](https://confluence.airbus.corp/display/Z2G22UDGS/PCP+On-boarding+Process) and as per the Export Control Directives and Methods.

In case of doubt on risk associated with the data, please contact your relevant [Airbus data officer](https://sites.google.com/airbus.com/data-governance/resources/network?authuser=0).
 
We remind you that unauthorized massive extraction is considered as a security breach. Please try to perform as many 
actions as possible on the platform itself to ensure traceability. At the end of your action out of the platform, 
exported data shall be deleted. All exports are monitored. Please seek relevant Data Officer's approval before exporting
 data in restricted access (or in case of doubt). 

Please read, __**before accessing Airbus Public Cloud Platform**__, the [Governance Policies](https://confluence.airbus.corp/display/Z2G22UDGS/PCP+Governance+Policies)
related to Airbus Public Cloud usage.

The Cloud Team
`
	return <div className={classnames('ExportControlDisclaimerText')}>
		<ReactMarkdown source={input} linkTarget={"_blank"} />
	</div>
}

export default ExportControlDisclaimerText;
