import {Route, Switch} from 'react-router-dom';
import NotAllowed, {NotAllowedUrl} from '../../../components/shared/NotAllowed';
import NotFound from '../../../components/shared/NotFound';
import React from 'react';
import PrivateRoute from '../../../components/shared/PrivateRoute';
import {Authorities} from '../../../utils/Authorities';
import AccountsRemediationControlPage from '../pages/AccountsRemediationControlPage';

const RoutesForSecurity = () => {
	return (
		<Switch>
			<PrivateRoute
				exact
				path="/remediationcontrol/accounts"
				component={AccountsRemediationControlPage}
				hasAnyAuthorities={[
					Authorities.ROLE_ADMIN,
					Authorities.ROLE_ADMIN_READONLY,
					Authorities.ROLE_SECURITY
				]}
			/>
			<Route exact path={NotAllowedUrl} render={() => <NotAllowed/>}/>
			<Route component={() => <NotFound/>}/>
		</Switch>
	);
};

export default RoutesForSecurity;
