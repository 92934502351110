import React from 'react';
import './Footer.scss';
import Bookmark from '@material-ui/icons/Bookmark';

const documentationLink =
	<a target="_blank" href='https://confluence.airbus.corp/display/Z2G22UDGS/' rel='noopener noreferrer'>
		<Bookmark className='documentationIcon'/>
		<span className='documentationLink' > Documentation</span>
	</a>;
const pcpVersion = process.env.REACT_APP_FOOTER;

const Footer = () => <footer className="footer">{pcpVersion} | {documentationLink}</footer>;

export default Footer;
