import AccountIcon from '@material-ui/icons/AccountBox';
import RefreshIcon from '@material-ui/icons/Refresh';
import {Button} from 'horizon-components-react';
import React, {Component} from 'react';
import AttachToApplication from '../components/account/AttachToApplication';
import ApiTable from '../../../components/tables/ApiTable';
import api from '../../../services/ApiServices';
import {hasAnyAuthority} from '../../../store/UserStore';
import {Authorities} from '../../../utils/Authorities';
import {Link} from 'react-router-dom';
import AppIcon from '@material-ui/icons/Web';
import SwitchCameraIcon from '@material-ui/icons/SwitchCamera';
import AddPartner from '../components/account/AddPartner';
import CreatePlatform from '../components/account/CreatePlatform';
import {getColumnFilterDirection} from 'utils/Utils';

class AdminAccountsPage extends Component {

    constructor(props) {
        super(props);
        this.apiTable = React.createRef();

        this.columns = [
            {
                name: '',
                key: '',
                cellRenderer: (undefinedVariable, colums, data) => {


                    let usedAccount = data.state === 'USED';
                    if (usedAccount) {
                        let accountUUID = encodeURIComponent(data.name);
                        let to = {
                            pathname: `/admin/accounts/${accountUUID}/applications`
                        };
                        return (
                            <Link to={to}>
                                <Button primary size="small" onClick={this.refresh}>
                                    <AppIcon/>
                                    Apps
                                </Button>
                            </Link>
                        );
                    }
                }
            },
            {
                name: 'Name',
                key: 'name',
                filter: true,
                sort: getColumnFilterDirection('name', 'admin-accounts-grid')
            },
            {
                name: 'Account ID',
                key: 'accountId',
                filter: true,
                type: 'number',
                sort: getColumnFilterDirection('accountId', 'admin-accounts-grid')
            },
            {
                name: 'Organization',
                key: 'organization.name',
                filter: true,
                filterType: 'checkedList',
                sort: getColumnFilterDirection('organization.name', 'admin-accounts-grid')
            },
            {
                name: 'State',
                key: 'state',
                addClass: true,
                filter: true,
                filterType: 'checkedList',
                sort: getColumnFilterDirection('state', 'admin-accounts-grid')
            },
            {
                name: '',
                key: '',
                cellRenderer: (undefinedVariable, colums, data) => {
                    let accountUUID = encodeURIComponent(data.name);
                    let switch_role_to = `https://signin.aws.amazon.com/switchrole?roleName=role-admin-sre-ops&account=${accountUUID}`
                    return (
                        <a href={switch_role_to} target="_blank" rel="noopener noreferrer"><Button primary size="small" onClick={this.refresh}>
                                    <SwitchCameraIcon/>
                                    Switch Role
                                </Button></a>
                    );
                }
            }
        ];

        this.refresh = this.refresh.bind(this);
    }

    async load() {
        return await api.getAllAccounts();
    }

    async delete(account) {
        await api.deleteAccount(account.id);
        this.load();
    }

    refresh() {
        this.apiTable.current.load();
    }

    render() {
        const readOnly = hasAnyAuthority(Authorities.ROLE_ADMIN_READONLY);

        return (
            <>
                <div className="float-right">
                    {!readOnly && (
                        <>
                            <AddPartner className="mr-1"/>
                            <CreatePlatform className="mr-1"/>
                        </>
                    )}
                    <Button primary size="small" onClick={this.refresh}>
                        <RefreshIcon/>
                        Refresh
                    </Button>
                </div>
                <h2>
                    <AccountIcon className="icon-page"/>
                    Account Management
                </h2>
                <hr/>
                <ApiTable
                    id="admin-accounts-grid"
                    ref={this.apiTable}
                    columns={this.columns}
                    load={this.load}
                    delete={this.delete}
                    decommissioned={true}
                >
                    {!readOnly && <AttachToApplication onConfirm={this.refresh}/>}
                </ApiTable>
            </>
        );
    }
}

export default AdminAccountsPage;
