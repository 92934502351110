import React, {Component} from 'react';
import api from '../../../../services/ApiServices';
import ApiTable from '../../../../components/tables/ApiTable';
import {Link} from 'react-router-dom';
import {withContext} from '../../../../store/ContextStore';
import {getColumnFilterDirection} from 'utils/Utils';

class UserApplications extends Component {


	constructor(props) {
		super(props);

		this.apiTable = React.createRef();
		this.load = this.load.bind(this);
		this.columns = [
			{
				name: 'Aspire Code',
				key: 'aspireCode',
				filter: true,
				sort: getColumnFilterDirection('aspireCode', 'user-role-grid')
			},
			{
				name: 'Application name',
				key: 'name',
				cellRenderer: (applicationName, column, data) => {
					const aspireCode = encodeURIComponent(data.aspireCode);
					return (
						<Link to={`/applications/${aspireCode}`} onClick={() => {
							this.props.setAppId(aspireCode);
						}}>
							{applicationName}
						</Link>
					);
				},
				filter: true,
				sort: getColumnFilterDirection('name', 'user-role-grid')
			}
		];
	}

	async load() {
		let userId = this.props.match.params.userId;
		return await api.getApplicationsForUser(userId);
	}


	render() {
		return (
			<ApiTable
				id="user-role-grid"
				ref={this.apiTable}
				columns={this.columns}
				load={this.load}
			/>
		);
	}
}

export default withContext(UserApplications);
