import Grid from '@material-ui/core/Grid/index';
import React from 'react';
import {Card, CardContent} from 'horizon-components-react';
import AccountMenu from './AccountMenu';
import RoutesForAccount from './RoutesForAccount';


const LayoutForAccount = () => {
	return (
		<Grid container spacing={0}>
			<Grid item xs={12} md={3} lg={2}>
				<AccountMenu/>
			</Grid>
			<Grid item xs={12} md={9} lg={10}>
				<Card>
					<CardContent>
						<RoutesForAccount/>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};

export default LayoutForAccount;
