import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {withContext} from '../../../store/ContextStore';
import './TeamSettingsMenu.scss';
import api from '../../../services/ApiServices';

class TeamSettingsMenu extends Component {

	constructor(props) {
		super(props);

		this.state = {
			team: null,
			loading: true
		};
	}

	async componentDidMount() {
		this.setState({
			loading: true
		});
		const team = (await api.getTeam(this.props.match.params.id)).data;
		this.setState({
			team: team,
			loading: false
		});
	}

	isSelected = relativePath => this.props.location.pathname === this.buildPath(relativePath);
	isRootPathSelected = () => this.props.location.pathname === `/teams/${this.state.team.id}`;
	buildPath = relativePath => `/teams/${this.state.team.id}/${relativePath}`;

	render() {
    	if (this.state.loading) {
    		return (
				<div>
					<Paper>
						<MenuList>
						</MenuList>
					</Paper>
				</div>
			);
		}

		return (
    		<div>
    			<Paper>
    				<MenuList>
    					<div style={{display: 'flex', marginBottom: '3px'}}>
    						<div style={{
    							fontWeight: 'bold',
    							fontSize: 'large',
    							color: '#0085ad',
    							textAlign: 'center',
    							float: 'right',
    							width: '100%'
    						}}>
    							<div className="teamName">
    								{this.state.team.name}
    							</div>
    						</div>
    					</div>
    					<Link to={this.buildPath('')}>
    						<MenuItem selected={this.isRootPathSelected()}>
    							<SettingsIcon className="icon-menu"/>
    							<span>Team</span>
    						</MenuItem>
    					</Link>
						<Link to={this.buildPath('users')}>
							<MenuItem selected={this.isSelected('users')}>
								<PeopleIcon className="icon-menu"/>
								<span>Members</span>
							</MenuItem>
						</Link>
    				</MenuList>
    			</Paper>

    		</div>

    	);
	}
}

export default withRouter(withContext(TeamSettingsMenu));
