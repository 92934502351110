import _ from 'lodash';

export const StringSorter = (array, key, direction) => {
	return array.sort((d1, d2) => {
		let v1 = _.get(d1, key);
		let v2 = _.get(d2, key);

		v1 = '' + v1;
		v2 = '' + v2;

		if (direction === 'asc') {
			return v1.localeCompare(v2);
		}
		return v2.localeCompare(v1);
	});
};

export const NumberSorter = (array, key, direction) => {
	return array.sort((d1, d2) => {
		let v1 = _.get(d1, key);
		let v2 = _.get(d2, key);

		v1 = '' + v1;
		v2 = '' + v2;

		if (direction === 'asc') {
			return v1 - v2;
		}
		return v2 - v1;
	});
};

export default StringSorter;
