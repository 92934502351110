import RefreshIcon from "@material-ui/icons/Refresh";
import KMSIcon from "@material-ui/icons/VpnKey";
import { Button } from "horizon-components-react";
import React, { Component } from "react";
import ApiTable from "../../../components/tables/ApiTable";
import api from "../../../services/ApiServices";
import { getColumnFilterDirection } from "utils/Utils";

class AdminKMSPage extends Component {
  constructor(props) {
    super(props);
    this.apiTable = React.createRef();

    this.columns = [
      {
        name: "Name",
        key: "name",
        filter: true,
        sort: getColumnFilterDirection("name", "kms-grid"),
      },
      {
        name: "Arn",
        key: "arn",
        filter: true,
        sort: getColumnFilterDirection("arb", "kms-grid"),
      },
      {
        name: "Description",
        key: "description",
        sort: getColumnFilterDirection("description", "kms-grid"),
      },
    ];

    this.refresh = this.refresh.bind(this);
  }

  async load() {
    return await api.getEncryptionKeys();
  }

  refresh() {
    this.apiTable.current.load();
  }

  render() {
    return (
      <>
        <div className="float-right">
          <Button primary size="small" onClick={this.refresh}>
            <RefreshIcon /> Refresh
          </Button>
        </div>
        <h2>
          <KMSIcon className="icon-page" /> Encryption keys management
        </h2>
        <hr />
        <ApiTable
          id="kms-grid"
          rowsPerPage={25}
          ref={this.apiTable}
          columns={this.columns}
          decommissioned={true}
          load={this.load}
        />
      </>
    );
  }
}

export default AdminKMSPage;
