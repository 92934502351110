import React, { Component } from "react";
import ApiTable from "../../../components/tables/ApiTable";
import api from "../../../services/ApiServices";
import { hasAnyAuthority, getCurrentUserEmail } from "../../../store/UserStore";
import { withContext } from "../../../store/ContextStore";
import { Authorities } from "../../../utils/Authorities";
import { Loading, GridCol, GridRow } from "horizon-components-react";
import "./TeamMembersPage.scss";
import TeamAddUser from "../components/team/TeamAddUser";
import { getColumnFilterDirection } from "utils/Utils";
import posthog from "posthog-js";

class TeamMembersPage extends Component {
  constructor(props) {
    super(props);
    this.apiTableOwners = React.createRef();
    this.apiTableMembers = React.createRef();

    this.columns = [
      {
        name: "Email",
        key: "email",
        filter: true,
        sort: getColumnFilterDirection("email", "team-member-grid"),
      },
    ];

    this.state = {
      team: null,
      loading: true,
    };

    this.load = this.load.bind(this);
    this.loadMembers = this.loadMembers.bind(this);
    this.loadOwners = this.loadOwners.bind(this);
    this.deleteOwner = this.deleteOwner.bind(this);
    this.deleteMember = this.deleteMember.bind(this);
    this.refresh = this.refresh.bind(this);

    this.refresh();
  }

  async load() {
    const teamId = this.props.match.params.id;
    const team = (await api.getTeam(teamId)).data;
    this.setState({
      team: team,
      loading: false,
    });

    // Force the table to refresh
    if (this.apiTableOwners && this.apiTableOwners.current) {
      this.apiTableOwners.current.load();
    }
    if (this.apiTableMembers && this.apiTableMembers.current) {
      this.apiTableMembers.current.load();
    }

    return team;
  }

  async loadMembers() {
    return {
      data: this.state.team.members,
    };
  }

  async loadOwners() {
    return {
      data: this.state.team.owners,
    };
  }

  async deleteOwner(userInfo) {
    const teamId = this.props.match.params.id;
    await api.removeTeamOwner(teamId, userInfo.id);
    this.refresh();
  }

  async deleteMember(userInfo) {
    const teamId = this.props.match.params.id;
    await api.removeTeamMember(teamId, userInfo.id);
    this.refresh();
  }

  refresh() {
    this.load();
  }

  render() {
    if (this.state.loading) {
      return <Loading>Loading&nbsp;team&nbsp;members...</Loading>;
    }

    this.teamId = this.props.match.params.id;
    const isAdmin = hasAnyAuthority(Authorities.ROLE_ADMIN);

    // Check if the user is in the owner list
    const currentUserEmail = getCurrentUserEmail();
    const isTeamOwner =
      this.state.team.owners &&
      this.state.team.owners.find(function (element) {
        return element.email === currentUserEmail;
      });

    return (
      <>
        <div className="teamMembers-grid">
          <GridRow>
            <GridCol>
              <h3>Members Management</h3>
            </GridCol>
          </GridRow>

          <GridRow>
            <GridCol>
              <hr />
            </GridCol>
          </GridRow>

          <GridRow>
            <GridCol sm={6}>
              {(isAdmin || isTeamOwner) && (
                <div className="float-right">
                  <TeamAddUser
                    manipulatedListType="owners"
                    teamId={this.state.team.id}
                    onConfirm={this.refresh}
                  />
                </div>
              )}
              <h3>Owners</h3>

              <ApiTable
                ref={this.apiTableOwners}
                columns={this.columns}
                load={this.loadOwners}
                decommissioned={false}
                delete={isAdmin || isTeamOwner ? this.deleteOwner : null}
                deleteButtonSize="extra-small"
                actionsWidth="50px"
                actionsMinWidth="50px"
              />
            </GridCol>
            <GridCol sm={6}>
              {(isAdmin || isTeamOwner) && (
                <div className="float-right">
                  <TeamAddUser
                    manipulatedListType="members"
                    teamId={this.state.team.id}
                    onConfirm={this.refresh}
                  />
                </div>
              )}
              <h3>Members</h3>

              <ApiTable
                id={"team-member-grid"}
                ref={this.apiTableMembers}
                columns={this.columns}
                load={this.loadMembers}
                decommissioned={false}
                delete={this.deleteMember}
                isAllowedToDelete={isAdmin || isTeamOwner}
                deleteButtonSize="extra-small"
                actionsWidth="50px"
                actionsMinWidth="50px"
              />
            </GridCol>
          </GridRow>
        </div>
      </>
    );
  }
}

export default withContext(TeamMembersPage);
