import React from 'react';
import ReactMarkdown from 'react-markdown';

function AmisPage() {

    const input = '# AWS AMI Management is now easier!\n' +
        'You don\'t need anymore to use PCP Portal or the API to retrieve newer AMI versions, they are all shared to your account without any action from your side.\n' +
        '\n' +
        'In the AWS console, make sure to select "Private images" and not the default "Owned by me", you will see the AMIs shared by PCP AMI production account `003920101472`.\n' +
        '\n' +
        '**[This link contains a few filters to easily find PCP Redhat7 AMI](https://eu-west-1.console.aws.amazon.com/ec2/v2/home?region=eu-west-1#Images:visibility=private-images;ownerAlias=003920101472;name=ami-airbus-pcp-redhat7-release-;tag:pcp:ami:validated=true;sort=desc:creationDate)**.\n'+
        '\n' +
        '**[This link contains a few filters to easily find all PCP AMI](https://eu-west-1.console.aws.amazon.com/ec2/v2/home?region=eu-west-1#Images:visibility=private-images;ownerAlias=003920101472;tag:pcp:ami:validated=true;sort=desc:creationDate)**.\n'+
        '\n' +
        'You can also use an AWS CLI call.\n' +
        '```\n' +
        '$ aws ec2 describe-images --owners "003920101472" --filters "Name=name,Values=ami-airbus-pcp-redhat7-release-*" "Name=tag:pcp:ami:validated,Values=true"\n' +
        '```\n' +
        '\n' +
        '#### See the **[dedicated Confluence page](https://confluence.airbus.corp/display/Z2G22UDDG/AMI+Distribution)** for more details.\n' +

        '\n';
    return <ReactMarkdown source={input} />;
}

export default AmisPage;
