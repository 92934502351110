import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import RulesIcon from "@material-ui/icons/AssignmentTurnedIn";
import PadLock from "@material-ui/icons/Lock";
import PeopleIcon from "@material-ui/icons/People";
import SettingsIcon from "@material-ui/icons/Settings";
import AppIcon from "@material-ui/icons/Web";
import { GridCol, GridContainer, GridRow } from "horizon-components-react";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { hasAnyAuthority } from "../store/UserStore";
import { Authorities } from "../utils/Authorities";

class HomePage extends Component {
  render() {
    const isAdmin = hasAnyAuthority([
      Authorities.ROLE_ADMIN,
      Authorities.ROLE_ADMIN_READONLY,
    ]);
    const isSecurity = hasAnyAuthority([Authorities.ROLE_SECURITY]);
    return (
      <div>
        <div>
          <GridContainer>
            <GridRow justify="center">
              <GridCol xs={12} md={6} lg={6}>
                <Card>
                  <Link to={"/applications"}>
                    <Button style={{ width: "100%" }}>
                      <CardContent className="text-center">
                        <AppIcon
                          style={{
                            width: "100px",
                            fontSize: "-webkit-xxx-large",
                          }}
                        />
                        <h5>
                          <div style={{ fontSize: "x-large" }}>
                            Applications
                          </div>
                        </h5>
                        <div className="text-muted">
                          Administer your applications
                        </div>
                      </CardContent>
                    </Button>
                  </Link>
                </Card>
              </GridCol>

              <GridCol xs={12} md={6} lg={6}>
                <Card>
                  <Link to={"/teams"}>
                    <Button style={{ width: "100%" }}>
                      <CardContent className="text-center">
                        <PeopleIcon
                          style={{
                            width: "100px",
                            fontSize: "-webkit-xxx-large",
                          }}
                        />
                        <h5>
                          <div style={{ fontSize: "x-large" }}>Teams</div>
                        </h5>
                        <div className="text-muted">Administer your teams</div>
                      </CardContent>
                    </Button>
                  </Link>
                </Card>
              </GridCol>
              {isAdmin && (
                <GridCol xs={12} md={6} lg={6}>
                  <Card>
                    <Link to={"/admin/teams"}>
                      <Button style={{ width: "100%" }}>
                        <CardContent className="text-center">
                          <SettingsIcon
                            style={{
                              width: "100px",
                              fontSize: "-webkit-xxx-large",
                            }}
                          />
                          <h5>
                            <div style={{ fontSize: "x-large" }}>
                              Administration
                            </div>
                          </h5>
                          <div className="text-muted">PCP administration</div>
                        </CardContent>
                      </Button>
                    </Link>
                  </Card>
                </GridCol>
              )}
              <GridCol xs={12} md={6} lg={6}>
                <Card>
                  <Link to={"/compliancerules"}>
                    <Button style={{ width: "100%" }}>
                      <CardContent className="text-center">
                        <RulesIcon
                          style={{
                            width: "100px",
                            fontSize: "-webkit-xxx-large",
                          }}
                        />
                        <h5>
                          <div style={{ fontSize: "x-large" }}>Compliance</div>
                        </h5>
                        <div className="text-muted">
                          Applied rules for your services
                        </div>
                      </CardContent>
                    </Button>
                  </Link>
                </Card>
              </GridCol>
              {(isAdmin || isSecurity) && (
                <GridCol xs={12} md={6} lg={6}>
                  <Card>
                    <Link to={"/remediationcontrol/accounts"}>
                      <Button style={{ width: "100%" }}>
                        <CardContent className="text-center">
                          <PadLock
                            style={{
                              width: "100px",
                              fontSize: "-webkit-xxx-large",
                            }}
                          />
                          <h5>
                            <div style={{ fontSize: "x-large" }}>
                              Exceptions
                            </div>
                          </h5>
                          <div className="text-muted">Security exceptions</div>
                        </CardContent>
                      </Button>
                    </Link>
                  </Card>
                </GridCol>
              )}
            </GridRow>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default HomePage;
