import React from 'react';
import RoutesForApplicationSettings from './RoutesForApplicationSettings';
import Grid from '@material-ui/core/Grid';
import {Card, CardContent} from 'horizon-components-react';
import ApplicationSettingsMenu from './ApplicationSettingsMenu';

const LayoutForApplicationSetting = () => {
	return (
		<Grid container spacing={0}>
			<Grid item xs={12} md={3} lg={2}>
				<ApplicationSettingsMenu/>
			</Grid>
			<Grid item xs={12} md={9} lg={10}>
				<Card>
					<CardContent>
						<RoutesForApplicationSettings/>
					</CardContent>
				</Card>
			</Grid>
		</Grid>);
};

export default LayoutForApplicationSetting;
