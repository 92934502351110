import React from 'react';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Checkbox from '@material-ui/core/Checkbox';
import * as PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import Tooltip from '@material-ui/core/Tooltip';


function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

function TableWithSelectionAndFilteringHeader(props) {
	const { order, orderBy, headers, multiple, disabled, onFilter, onRequestSort, onSelectAll } = props;

	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell key="select" padding="checkbox">
					{multiple &&
						<Checkbox onChange={(event) => {onSelectAll(event)}} disabled={disabled}/>
					}
				</TableCell>
				{headers.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? 'right' : 'left'}
						padding={headCell.disablePadding ? 'none' : 'default'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
			<TableRow>
				<TableCell colSpan={headers.length + 1} style={{paddingTop: '0px', paddingBottom: '0px'}}>
					<Input label="Filter" size="small"
						   fullWidth={true} disableUnderline={true}
						   placeholder={"Filter"}
						   onChange={(event) => {onFilter(event.target.value)}}/>
				</TableCell>
			</TableRow>
		</TableHead>
	);
}

TableWithSelectionAndFilteringHeader.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	onFilter: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	headers: PropTypes.array.isRequired
};

const TableWithSelectionAndFiltering = ({multiple, headers, rows, onSelectionChange, disabled, maxHeight, disabledRows, disabledRowsMessage}) => {

	const identifierFieldName = headers.filter((header) => {
		return header.isIdentifier
	})[0].id;

	const defaultOrderBys = headers.filter((header) => {
		return header.defaultOrderBy
	});

	const [filter, setFilter] = React.useState('');
	const [order, setOrder] = React.useState('asc');
	const [selected, setSelected] = React.useState([]);
	const [orderBy, setOrderBy] = React.useState(defaultOrderBys ? defaultOrderBys[0].id : '');

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleClick = (event, name, isRowDisabled) => {

		if (isRowDisabled) {
			return;
		}

		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (multiple) {
			if (selectedIndex === -1) {
				newSelected = newSelected.concat(selected, name);
			} else if (selectedIndex === 0) {
				newSelected = newSelected.concat(selected.slice(1));
			} else if (selectedIndex === selected.length - 1) {
				newSelected = newSelected.concat(selected.slice(0, -1));
			} else if (selectedIndex > 0) {
				newSelected = newSelected.concat(
					selected.slice(0, selectedIndex),
					selected.slice(selectedIndex + 1),
				);
			}
		} else {
			if (selectedIndex === 0) {
				newSelected = [];
			} else {
				newSelected = [name];
			}
		}
		setSelected(newSelected);
		if (onSelectionChange) {
			onSelectionChange(newSelected);
		}
	};

	const handleSelectAllClick = event => {
		let newSelection = [];
		if (event.target.checked) {
			const rowsNotDisabled = rows.filter((row) => !isRowDisabled(row));
			newSelection = rowsNotDisabled.map(n => n[identifierFieldName]);
		}
		setSelected(newSelection);
		if (onSelectionChange) {
			onSelectionChange(newSelection);
		}
	};

	const filterRows = (rows, filter) => {
		if (filter && filter !== "") {
			const regexp = new RegExp(filter, "gi");
			return rows.filter((row) => {
				let hasFilter = false;
				headers.forEach((header) => {
					const v = row[header.id];
					if (v && v.match(regexp)) {
						hasFilter = true;
						return false;
					}
				});
				return hasFilter;
			})
		} else {
			return rows;
		}
	}

	const isSelected = (name) => selected.indexOf(name) !== -1;
	const isRowDisabled = (row) => {
		if (disabled) return true;
		else if (disabledRows)
			return disabledRows(row);
		else
			return false;
	};
	const isAllRowDisabled = () => {
		let isAllRowDisabled = true;
		rows.forEach((row) => {
			if (!isRowDisabled(row)) {
				isAllRowDisabled = false;
			}
		});
		return isAllRowDisabled;
	}

	return (
		<div>
			<Paper>
				<TableContainer style={{maxHeight: maxHeight}}>
					<Table
						stickyHeader aria-label="enhanced sticky table"
						aria-labelledby="tableTitle"
						size='small'
					>
						<TableWithSelectionAndFilteringHeader
							order={order}
							orderBy={orderBy}
							onFilter={(filter) => setFilter(filter)}
							onRequestSort={handleRequestSort}
							onSelectAll={handleSelectAllClick}
							multiple={multiple}
							disabled={isAllRowDisabled()}
							headers={headers}
						/>
						<TableBody>
							{rows && stableSort(filterRows(rows, filter), getComparator(order, orderBy))
								.map((row, index) => {
									const isItemSelected = isSelected(row[identifierFieldName]);
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											onClick={(event) => handleClick(event, row[identifierFieldName], isRowDisabled(row))}
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row[identifierFieldName]}
											selected={isItemSelected}
										>
											<TableCell padding="checkbox">
												{isRowDisabled(row) && disabledRowsMessage &&
													<Tooltip title={disabledRowsMessage} placement="right">
														<span>
															<Checkbox
																style={{padding: '0px'}}
																checked={isItemSelected}
																disabled={isRowDisabled(row)}
																inputProps={{ 'aria-labelledby': labelId }}
															/>
														</span>
													</Tooltip>
												}
												{(!isRowDisabled(row) || !disabledRowsMessage) &&
													<Checkbox
														style={{padding: '0px'}}
														checked={isItemSelected}
														disabled={isRowDisabled(row)}
														inputProps={{'aria-labelledby': labelId}}
													/>
												}
											</TableCell>
											{ headers.map((header) => {
												return <TableCell>{row[header.id]}</TableCell>
											})}
										</TableRow>
									);
								})}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</div>
	);

}

export default TableWithSelectionAndFiltering;