import React, {Component} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import api from 'services/ApiServices';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import DecommissionedTooltip from 'components/tooltips/DecommissionedTooltip';

class DetachAwsRoleFromUser extends Component {


	constructor(props) {
		super(props);

		this.state = {open: false, loading: false};
		this.handleClickOpen = this.handleClickOpen.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.detachRoleToUser = this.detachRoleToUser.bind(this);
	}

	handleClickOpen() {
		this.setState({open: true});
	}

	handleClose() {
		this.setState({open: false});
	}

	async detachRoleToUser() {
		this.setState({loading: true});
		await api.detachAwsRoleFromUserInApplication(this.props.appId, this.props.userId, this.props.arn);
		this.props.onConfirm();
	}

	render() {
		return (
            <>
				<DecommissionedTooltip>
					<Button
						disabled
						color="secondary"
						onClick={this.handleClickOpen}
					>
						<DeleteIcon/>
					</Button>
				</DecommissionedTooltip>
                <Dialog
                	open={this.state.open}
                	onClose={this.handleClose}
                	aria-labelledby="alert-dialog-title"
                	aria-describedby="alert-dialog-description"
                >
                	<DialogContent>
                		<DialogContentText id="alert-dialog-description">
                            Do you want to detach the role <strong>{this.props.roleName}</strong> from this user ?
                		</DialogContentText>
                	</DialogContent>
                	<DialogActions>
                		<Button onClick={this.handleClose} color="secondary" disabled={this.state.loading}>
                            Disagree
                		</Button>
                		<Button onClick={this.detachRoleToUser} disabled={this.state.loading}>
                			{this.state.loading ? (
                                <>Saving</>
                			) : (
                                <>
                                    Agree
                                </>
                			)}
                		</Button>
                	</DialogActions>
                </Dialog>
            </>
		);
	}
}

export default DetachAwsRoleFromUser;
