import PeopleIcon from "@material-ui/icons/People";
import _ from "lodash";
import React, { Component } from "react";
import CreateOrEditUserIntoPcp from "../../administration/components/user/CreateOrEditUserIntoPcp";
import ApiTable from "../../../components/tables/ApiTable";
import api from "../../../services/ApiServices";
import { hasAnyAuthority } from "../../../store/UserStore";
import { Authorities } from "../../../utils/Authorities";
import { Link } from "react-router-dom";
import RoleIcon from "@material-ui/icons/Assignment";
import Fab from "@material-ui/core/Fab";
import { Tooltip } from "@material-ui/core";
import { withContext } from "../../../store/ContextStore";
import { getColumnFilterDirection } from "utils/Utils";
import DecommissionedTooltip from "components/tooltips/DecommissionedTooltip";

class UsersPage extends Component {
  constructor(props) {
    super(props);
    this.apiTable = React.createRef();

    this.columns = [
      {
        name: "",
        key: "",
        cellRenderer: (undefinedParameter, column, data) => {
          return (
            <DecommissionedTooltip>
              <Fab size="small" disabled>
                <RoleIcon />
              </Fab>
            </DecommissionedTooltip>
          );
        },
      },
      {
        name: "Email",
        key: "user.email",
        filter: true,
        sort: getColumnFilterDirection("user.email", "users-grid"),
      },
      {
        name: "Authorities",
        key: "authorities",
        cellRenderer: (authorities) => {
          return this.renderAuthorities(authorities);
        },
        sort: getColumnFilterDirection("authorities", "users-grid"),
      },
    ];

    this.load = this.load.bind(this);
    this.delete = this.delete.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  renderAuthorities(authorities) {
    const result = [];
    authorities.forEach((authority) => {
      result.push(this.renderAuthority(authority));
    });
    return result;
  }

  renderAuthority(authority) {
    return <div key={authority.name}>{authority.name}</div>;
  }

  async load() {
    const appId = this.props.match.params.id;
    return await api.getUsers(appId);
  }

  async delete(userInfo) {
    const appId = this.props.match.params.id;
    await api.detachAnUserFromAnApplication(appId, userInfo.id);
    this.load();
  }

  refresh() {
    this.apiTable.current.load();
  }

  adaptData(userInfo) {
    const userModel = _.cloneDeep(userInfo.user);
    userModel.authorities = userInfo.authorities;
    userModel.send_notification = userInfo.send_notification;
    return userModel;
  }

  render() {
    this.appId = this.props.match.params.id;
    const isAdmin = hasAnyAuthority(Authorities.ROLE_ADMIN);
    const isAppOwner = this.props.authorities.includes(
      Authorities.ROLE_APP_OWNER
    );

    return (
      <>
        <div className="float-right">
          {(isAdmin || isAppOwner) && (
            <CreateOrEditUserIntoPcp
              onConfirm={this.refresh}
              className="mr-1"
            />
          )}
        </div>
        <h3>
          <PeopleIcon className="icon-page" />
          Users Management
        </h3>
        <hr />

        <ApiTable
          id="users-grid"
          ref={this.apiTable}
          dataAdapter={this.adaptData}
          columns={this.columns}
          load={this.load}
          delete={this.delete}
          decommissioned={true}
          isAllowedToDelete={isAdmin || isAppOwner}
        >
          {(isAdmin || isAppOwner) && (
            <CreateOrEditUserIntoPcp onConfirm={this.refresh} />
          )}
        </ApiTable>
      </>
    );
  }
}

export default withContext(UsersPage);
