import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import List from '@material-ui/core/List';
import {ListItem} from '@material-ui/core';
import ClientIcon from '@material-ui/icons/People';
import ListItemText from '@material-ui/core/ListItemText';
import SecurityTeamIcon from '@material-ui/icons/Whatshot';


function timeConverter(UNIX_timestamp) {
	const now = new Date(UNIX_timestamp);
	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	const year = now.getFullYear();
	const month = months[now.getMonth()];
	const date = now.getDate() > 9 ? now.getDate() : '0' + now.getDate();
	const hour = now.getHours() > 9 ? now.getHours() : '0' + now.getHours();
	const min = now.getMinutes() > 9 ? now.getMinutes() : '0' + now.getMinutes();
	const sec = now.getSeconds() > 9 ? now.getSeconds() : '0' + now.getSeconds();
	return date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
}

const RemediationDisabledEvent = ({event}) => {

	const parameters = JSON.parse(JSON.parse(event.parameters));
	const body = !parameters.hasOwnProperty('client_request') ? JSON.parse(parameters.body) : parameters;

	return <ExpansionPanel>
		<ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
			<Typography
				style={{fontSize: '15px'}}>Remediation <strong>DISABLED</strong> on {timeConverter(event.when)}
				<br/>by {event.who}
			</Typography>
		</ExpansionPanelSummary>
		<ExpansionPanelDetails>
			<List>
				<ListItem key={Math.random()}>
					<ClientIcon/>
					<ListItemText
						primary={
							<>Client request</>
						}
						secondary={
							<>{body.client_request}</>
						}
					/>
				</ListItem>
				<ListItem>
					<SecurityTeamIcon/>
					<ListItemText
						primary={
							<>Security rationale</>
						}
						secondary={
							<>{body.security_rational}</>
						}
					/>
				</ListItem>
			</List>
		</ExpansionPanelDetails>
	</ExpansionPanel>;
};

export default RemediationDisabledEvent;
