import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import classnames from 'classnames';
import {Button, GridCol, GridRow} from 'horizon-components-react';
import _ from 'lodash';
import React, { Component } from 'react';
import { Field } from 'react-final-form';
import api from '../../../../services/ApiServices';
import { withContext } from '../../../../store/ContextStore';
import { removeEmpty } from '../../../../utils/Utils';
import DialogForm from '../../../../components/shared/DialogForm';
import {
	DefaultTextAreaAdapter,
	DefaultTextInputAdapter
} from '../../../../components/shared/form/DefaultAdapter';


class CreateTeam extends Component {
	constructor(props) {
		super(props);
		this.formDialog = React.createRef();

		this.edit = !!props.model;

		// Remove the user lists from the model, in order to avoid updating the list when sending the info
		// to the backend
		if (props.model) {
			props.model.owners = null;
			props.model.members = null;
		}

		const creationModel = {
			name: null,
			description: null,
			aws_role_arn: null
		};

		this.state = {
			saving: false,
			model: this.edit ? this.props.model : creationModel
		};
		this.toggle = this.toggle.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	toggle(event) {
		event.preventDefault();
		this.formDialog.current.open();
		if (this.edit) {
			const updateModel = _.cloneDeep(this.props.model);
			this.setState({
				model: updateModel
			});
		}
	}

	async handleSubmit(values) {
		const toSend = _.cloneDeep(values);

		removeEmpty(toSend);

		if (this.edit) {
			const response = await api.updateTeam(toSend);
			this.setState({
				model: response.data
			});
		} else {
			await api.createTeam(toSend);
		}
	}

	render() {
		let buttonLabel = 'Add';
		let popupTitle = 'Add team';
		if (this.edit) {
			buttonLabel = 'Edit';
			popupTitle = 'Edit team';
		}

		if (this.props.noLabel) {
			buttonLabel = '';
			popupTitle = '';
		}
		return (
			<span className={classnames(this.props.className)}>
				<Button primary size="small" withIcon="true" onClick={this.toggle}>
					{this.edit ? <EditIcon /> : <AddIcon />}
					{buttonLabel}
				</Button>
				<DialogForm
					className="create-application-dialog dialog-with-select-input"
					ref={this.formDialog}
					model={this.state.model}
					dialogTitle={popupTitle}
					onSubmit={this.handleSubmit}
					onConfirm={this.props.onConfirm}
				>
					<GridRow>
						<GridCol>
							<Field
								name="name"
								label="Name"
								placeholder="Team name"
								required={true}
								errorText="name is required"
								disabled={this.state.saving}
								component={DefaultTextInputAdapter}
								autoFocus
							/>
						</GridCol>
					</GridRow>
					<GridRow>
						<GridCol>
							<Field
								name="description"
								label="Description"
								placeholder="A short description for the team"
								disabled={this.state.saving}
								component={DefaultTextAreaAdapter}
							/>
						</GridCol>
					</GridRow>
					<GridRow>
						<GridCol>
							<Field
								name="aws_role_arn"
								label="AWS Role ARN"
								placeholder="arn:aws:iam:743486263330:aws:role/airbus-service-role/role-jump-admin-xxxxxxxxxxxxx"
								disabled={this.state.saving}
								component={DefaultTextAreaAdapter}
							/>
						</GridCol>
					</GridRow>
				</DialogForm>
			</span>
		);
	}
}

export default withContext(CreateTeam);
