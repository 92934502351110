import React, {useEffect, useState} from 'react';
import {Form} from 'react-final-form';
import {TextField} from '@material-ui/core';
import {Button} from 'horizon-components-react';
import api from 'services/ApiServices';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


function LoginWithMfa({whenAuthenticatedWithMfa, devices, stateToken, countDown}) {
	const [loading, setLoading] = useState(false);
	const [otpToken, setOtpToken] = useState('');
	const [deviceSelected, setDeviceSelected] = useState((devices.length >= 1) ? (devices[0].id) : '');
	const [labelWidth, setLabelWidth] = React.useState(0);
	const inputLabel = React.useRef(null);
	useEffect(() => {
		setLabelWidth(inputLabel.current.offsetWidth);
	}, []);

	function onSubmit() {
		setLoading(true);
		api.authenticateWithMFA(deviceSelected, otpToken, stateToken).then(() => {
			whenAuthenticatedWithMfa();
		}).finally(() => setLoading(false));
	}

	return <>
        <Form
        	onSubmit={onSubmit}
        	render={({
        		handleSubmit
        	}) => <form onSubmit={handleSubmit}>
        		<TextField
        			value={otpToken}
        			onChange={(event) => setOtpToken(event.target.value)}
        			inputProps={{style: {fontSize: 20, width: 500, textAlign: 'center'}}}
        			name="token"
        			variant="outlined"
        			label="Token"
        			autoFocus
        		/>
        		<br/>
        		<br/>
        		<FormControl disabled={devices.length === 1} variant="outlined" style={{fontSize: 20, width: 528}}>
        			<InputLabel ref={inputLabel}>
                        Device identifier
        			</InputLabel>
        			<Select
        				name='deviceId'
        				value={deviceSelected}
        				onChange={(event) => setDeviceSelected(event.target.value)}
        				labelWidth={labelWidth}
        			>
        				{devices.map(device => {
        					return <MenuItem value={device.id}>{device.id} - {device.type} </MenuItem>;
        				})}
        			</Select>
        		</FormControl>
        		<br/>
        		<br/>
        		<Button submit disabled={loading || (deviceSelected.length === 0 || otpToken.length === 0)} primary style={{fontSize: 20, width: 528}}>
                    Confirm your MFA
        		</Button>
        		<div style={{fontSize: '10px', textAlign: 'center', color: 'grey'}}>
        			{countDown}
        		</div>
        	</form>}
        />

    </>;
}


export default LoginWithMfa;
