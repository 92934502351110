import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ReactMarkdown from 'react-markdown';

function ConfirmationDialog({open, popupTitle, popupMessageMarkdown, onConfirm, onCancel}) {

	const handleCancel = () => {
		if (onCancel) {
			onCancel();
		}
	};

	const handleConfirm = () => {
		if (onConfirm) {
			onConfirm();
		}
	};

	return (
		<Dialog
			maxWidth="xs"
			aria-labelledby="confirmation-dialog-title"
			open={open}
			onClose={handleCancel}
		>
			<DialogTitle id="confirmation-dialog-title">{popupTitle}</DialogTitle>
			<DialogContent dividers>
				<ReactMarkdown source={popupMessageMarkdown} />
			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={handleCancel} variant={"contained"}>
					Cancel
				</Button>
				<Button onClick={handleConfirm} color="primary" variant={"contained"}>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export function withConfirmationPopup(WrappedComponent) {
	return class extends React.Component {
		constructor(props) {
			super(props);
			this.state = {
				popupTitle: props.popupTitle,
				popupMessageMarkdown: props.popupMessageMarkdown,
				onAccept: props.onAccept,
				onCancel: props.onCancel,
				open: false,
			};
		}

		open = () => {this.setState({ open: true })};
		close = () => {this.setState({ open: false })};

		onConfirm = () => {
			if (this.state.onAccept) {
				this.state.onAccept();
			}
			// Close the dialog
			this.close();
		}

		onCancel = () => {
			if (this.state.onCancel) {
				this.state.onCancel();
			}
			// Close the dialog
			this.close();
		}

		render() {
			return <>
				<ConfirmationDialog
					open={this.state.open}
					popupTitle={this.state.popupTitle}
					popupMessageMarkdown={this.state.popupMessageMarkdown}
					onConfirm={this.onConfirm}
					onCancel={this.onCancel}
				/>
				<WrappedComponent
					{...this.props}
					onClick={this.open}  // Override the original onClick
				/>
			</>
		}
	}
}