

const tagDisplay = {
	"Patching": {
		"DisplayName": "Patching",
		"Color": "#b8b831",
	},
	"Emergency": {
		"DisplayName": "Emergency",
		"Color": "#bc2e33",
	},
	"Uncategorized": {
		"DisplayName": "Uncategorized",
		"Color": "#d1d3d4",
	}
}

const categoryMapping = {
	"MachinesSecurityMaintenance/Patching": {
		"Color": "#b8b831",
		"Tags": [
			"Patching"
		]
	},
	"MachinesSecurityMaintenance/Patching/Emergency": {
		"Color": "#bc2e33",
		"Tags": [
			"Patching",
			"Emergency"
		]
	},
	"Uncategorized": {
		"Color": "#d1d3d4",
		"Tags": [
			"Uncategorized"
		]
	}
}

class PlannedEventsCategoryDisplay {

	static getDisplayInfo(category) {
		let result = {};

		if (categoryMapping.hasOwnProperty(category) === false) {
			category = "Uncategorized";
		}

		result = JSON.parse(JSON.stringify(categoryMapping[category]));

		// Replace the tags with the color
		let enrichedTags = [];
		result.Tags.forEach(function(tagname) {
			enrichedTags.push(JSON.parse(JSON.stringify(tagDisplay[tagname])));
		})

		result.Tags = enrichedTags;

		return result;
	}

}

export default PlannedEventsCategoryDisplay;