import React from 'react';
import {Info, Warning} from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import './InfoIconWithPopover.scss'

const useStyles = makeStyles((theme) => ({
	popover: {
		pointerEvents: 'none',
	},
	paper: {
		padding: theme.spacing(1),
	},
}));

export default function InfoIconWithPopover(props) {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<>
			<Typography
				aria-owns={open ? 'info-not-editable-mouse-over-popover' : undefined}
				aria-haspopup="true"
				display={"inline"}
				onMouseEnter={handlePopoverOpen}
				onMouseLeave={handlePopoverClose}
			>
				<Info className={classnames("icon-emphasis-on-hover")}/>
			</Typography>
			<Popover
				id="info-not-editable-mouse-over-popover"
				open={open}
				className={classes.popover}
				classes={{
					paper: classes.paper,
				}}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<Typography>{props.children}</Typography>
			</Popover>
		</>
	);
}