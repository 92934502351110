import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import classnames from 'classnames';
import { Button } from 'horizon-components-react';
import React, { Component } from 'react';
import { Field } from 'react-final-form';
import api from '../../../../services/ApiServices';
import DialogForm from '../../../../components/shared/DialogForm';
import { DefaultTextInputAdapter } from '../../../../components/shared/form/DefaultAdapter';
import './CreateOrEditOrganization.scss';
import DecommissionedTooltip from 'components/tooltips/DecommissionedTooltip';

class CreateOrEditOrganization extends Component {
	constructor(props) {
		super(props);
		this.formDialog = React.createRef();
		this.edit = props.model ? true : false;

		this.state = {
			saving: false,
			model: this.edit ? props.model : {}
		};
		this.toggle = this.toggle.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	toggle(event) {
		event.preventDefault();
		this.formDialog.current.open();
	}

	async handleSubmit(values) {
		if (this.edit) {
			await this.update(values);
		} else {
			await this.create(values);
		}
	}

	async update(values) {
		await api.updateOrganization(
			this.state.model.id,
			values.name,
			values.awsId
		);
	}

	async create(values) {
		await api.createOrganization(values.name, values.awsId);
	}

	render() {
		let dialogTitle = 'Create an organization';
		let buttonLabel = 'Add';
		let buttonIcon = <AddIcon />;
		if (this.edit) {
			buttonLabel = 'Edit';
			dialogTitle = 'Edit an organization';
			buttonIcon = <EditIcon />;
		}

		if (this.props.icon) {
			buttonIcon = this.props.icon;
		}

		return (
			<span className={classnames(this.props.className)}>
				<DecommissionedTooltip>
					<Button
						disabled
						primary
						size="small"
						onClick={this.toggle}
						title={this.props.title}
					>
						{buttonIcon}
						{buttonLabel}
					</Button>
				</DecommissionedTooltip>
				<DialogForm
					className="create-organization-dialog"
					ref={this.formDialog}
					model={this.state.model}
					dialogTitle={dialogTitle}
					onSubmit={this.handleSubmit}
					onConfirm={this.props.onConfirm}
				>
					<Field
						name="name"
						component={DefaultTextInputAdapter}
						label="Name"
						placeholder="Name"
						required={true}
						errorText="Name is required"
						disabled={this.state.saving}
						autoFocus
					/>
					<Field
						name="awsId"
						component={DefaultTextInputAdapter}
						label="AWS ID"
						placeholder="AWS ID"
						disabled={this.state.saving}
					/>
				</DialogForm>
			</span>
		);
	}
}

export default CreateOrEditOrganization;
