import {
	GridCol,
	GridContainer,
	GridRow,
	Loading,
} from 'horizon-components-react';
import React, {Component} from 'react';
import AccountIcon from '@material-ui/icons/AccountBox';
import '../components/account/AccountCard.scss';
import api from 'services/ApiServices';
import {Authorities} from 'utils/Authorities';
import {hasAnyAuthority, hasAnyAuthorityOnThisApplication} from 'store/UserStore';
import AttachApplicationToAnAccount from 'domains/application/components/account/AttachApplicationToAnAccount';
import ImportAccount from 'domains/application/components/account/ImportAccount';
import AccountCard from 'domains/application/components/account/AccountCard';
import {withContext} from 'store/ContextStore';


class AccountsInApplicationPage extends Component {

	constructor(props) {
		super(props);
		this.state = {loading: true, accounts: [], refresh: false};
		this.onConfirm = this.onConfirm.bind(this);
		this.loadAccounts = this.loadAccounts.bind(this);
	}

	componentDidMount() {
		this.loadAccounts();
	}

	onConfirm() {
		this.loadAccounts();
	}

	loadAccounts() {
		this.setState({loading: true});
		api.getAccounts(this.props.match.params.id)
			.then(accounts => {
				this.setState({accounts});
			}).finally(() => this.setState({loading: false}));
	}

	renderAccounts() {
		let applicationHasAccounts = this.state.accounts.length > 0;
		if (applicationHasAccounts) {
			return this.state.accounts.map(this.renderAccountCard);
		} else {
			return <p style={{fontSize: 'larger'}}>You have no account attached to this application.</p>;
		}
	}


    renderAccountCard = account =>
    	<GridCol key={account.name} xs={12} md={6} lg={4}>
    		<AccountCard onConfirm={this.onConfirm} {...account}/>
    	</GridCol>;


    render() {
    	if (this.state.loading) {
    		return <Loading>Loading accounts</Loading>;
    	}

    	const isAdmin = hasAnyAuthority(Authorities.ROLE_ADMIN);
    	return (
            <>
                <div className="float-right">
                	{(isAdmin) && (
                        <>
                            <AttachApplicationToAnAccount className="mr-1" onConfirm={this.onConfirm}/>
                            <ImportAccount className="mr-1" onConfirm={this.onConfirm}/>
                        </>
                	)}
                </div>
                <h3>
                	<AccountIcon className="icon-page"/>
                    Accounts
                </h3>

                <GridContainer>
                	<GridRow justify="center">
                		{this.renderAccounts()}
                	</GridRow>
                </GridContainer>
            </>
    	);
    }
}

export default withContext(AccountsInApplicationPage);
