import {GridCol, GridRow, Loading} from 'horizon-components-react';
import React, {Component} from 'react';
import {withContext} from '../../../store/ContextStore';
import {hasAnyAuthority, getCurrentUserEmail} from '../../../store/UserStore';
import {Authorities} from '../../../utils/Authorities';
import api from '../../../services/ApiServices';
import './TeamPage.scss';
import CreateTeam from '../../administration/components/team/CreateTeam';

class TeamPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			team: null,
			isTeamOwner: false,
			loading: true,
		};

		this.refresh = this.refresh.bind(this);
	}

	async componentDidMount() {
		await this.refresh();
	}

	async refresh() {
		const team = (await api.getTeam(this.props.match.params.id)).data;

		// Check if the user is in the owner list
		let isTeamOwner = false;
		const currentUserEmail = getCurrentUserEmail();
		if (team.owners && team.owners.includes(currentUserEmail)) {
			isTeamOwner = true;
		}

		this.setState({
			team: team,
			isTeamOwner: isTeamOwner,
			loading: false
		});
	}

	render() {

		if (this.state.loading) {
			return <Loading>Loading&nbsp;team...</Loading>;
		}

		const team = this.state.team;

		const isAdmin = hasAnyAuthority(Authorities.ROLE_ADMIN);

		return (
			<div>
				<div className="float-right">
					<div className="teamCard-toolbar">
						{isAdmin && (
							<CreateTeam
								model={team}
								onConfirm={this.refresh}
								className="mr-1"
							/>
						)}
					</div>
				</div>

				<div className="team-card-summary-title">
					<h4>
						{team.name}
					</h4>
					<h5>{team.description}</h5>
				</div>
				<hr/>
				<div className="team-card-summary-container">
					<GridRow>
						<GridCol sm="5">
							<b>Name</b>
						</GridCol>
						<GridCol sm="7">{team.name}</GridCol>
					</GridRow>
					<GridRow>
						<GridCol sm="5">
							<b>Description</b>
						</GridCol>
						<GridCol sm="7">{team.description}</GridCol>
					</GridRow>
					<GridRow>
						<GridCol sm="5">
							<b>AWS Role Arn associated to the members</b>
						</GridCol>
						<GridCol sm="7">{team.aws_role_arn}</GridCol>
					</GridRow>
				</div>
			</div>
		);
	}
}

export default withContext(TeamPage);
