import {CardContent, Grid} from '@material-ui/core';
import List from '@material-ui/core/es/List';
import React, {useState} from 'react';
import AccountsSearchBar from './AccountsSearchBar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import AppIcon from '@material-ui/icons/Web';
import {Strategy} from 'domains/security/component/RemediationStrategies/RemediationStrategySelector';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import {accountRemediationCardRenderers} from 'domains/security/pages/Renderers';

function AccountsSearchList({accounts}) {
	const [query, setQuery] = useState(null);
	const [strategyFilter, setStrategyFilter] = useState(Strategy.OFF);
	const [fieldToSearch, setFieldToSearch] = useState('aws_account_name');
	const [slice, setSlice] = useState(20);

	const onSearch = query => {
		const isAspireCodeFormat = query.toLowerCase() !== 'prod' && parseFloat(query).toString() && query.length === 4;
		const isAccountIdFormat = parseInt(query) && query.length === 12;
		const whatToSearch = isAccountIdFormat ? 'aws_account_id' : isAspireCodeFormat ? 'aspire_code' : 'aws_account_name';
		setFieldToSearch(whatToSearch);
		setQuery(query === '' ? null : query);
	};

	const accountsToDisplay = () => {
		if (strategyFilter === 'ALL') {
			return accounts
				.filter(account => {
					if (query === null) {
						return true;
					}
					if (account[fieldToSearch] !== null) return account[fieldToSearch].toLowerCase().includes(query);
				})
				.map(account => React.createElement(accountRemediationCardRenderers[account.remediation_strategy], {account: account, key: JSON.stringify(account)}));

		} else {
			return accounts
				.filter(account => {
					if (query === null) {
						return true;
					}
					if (account[fieldToSearch] !== null) return account[fieldToSearch].toLowerCase().includes(query);
				})
				.filter(account => account.remediation_strategy === strategyFilter)
				.map(account => React.createElement(accountRemediationCardRenderers[strategyFilter], {account: account, key: JSON.stringify(account)}));
		}
	};


	const groupByAspireCode = array =>
		array.reduce((objectsByKeyValue, obj) => {
			const value = obj.props.account.aspire_code;
			objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
			return objectsByKeyValue;
		}, []);
	const accountsToDisplayGroupedByAspireCode = groupByAspireCode(accountsToDisplay().slice(0, slice));
	const aspireCodeDivider = (aspireCode, items) => {
		return <>
			<Card key={aspireCode} style={{margin: '10px'}}>
				<CardContent>
					<Typography>
						{aspireCode === 'null' ? 'Accounts not attached to an aspire code' : (<><AppIcon/> {aspireCode}</>)}
					</Typography>
					{items}
				</CardContent>
			</Card>
		</>;
	};
	const cardsToDisplay = () => {
		let cards = [];
		for (const aspireCode in accountsToDisplayGroupedByAspireCode) {
			const card = aspireCodeDivider(aspireCode, accountsToDisplayGroupedByAspireCode[aspireCode]);
			cards.push(card);
		}
		return cards;
	};

	return (
		<>
			<Grid container
				direction="row"
				justify="center"
				alignItems="center"
			>
				<Grid item lg={12}>
					<Grid container>
						<Grid item xl={12} lg={12} xs={12}>
							<AccountsSearchBar onSearch={onSearch}/>
						</Grid>
						<Grid item xl={12} lg={12}>
							<FormControl component="fieldset">
								<FormLabel component="legend">Remediation strategy</FormLabel>
								<RadioGroup row value={strategyFilter} onChange={(event) => setStrategyFilter(event.target.value)}>
									<FormControlLabel value={Strategy.OFF} control={<Radio/>} label={Strategy.OFF}/>
									<FormControlLabel value={Strategy.PARTIAL} control={<Radio/>} label={Strategy.PARTIAL}/>
									<FormControlLabel value={Strategy.ON} control={<Radio/>} label={Strategy.ON}/>
									<FormControlLabel value={'ALL'} control={<Radio/>} label={'ALL'}/>
								</RadioGroup>
							</FormControl>
						</Grid>
					</Grid>
					<span style={{
						fontWeight: 'italic',
						fontSize: '10px'
					}}>
						{accountsToDisplay().length}{accountsToDisplay().length > 1 ? ' accounts' : ' account'} found
					</span>
					<List>
						{cardsToDisplay()}
						{accountsToDisplay().length > 20 &&
                        <Button onClick={() => setSlice(slice + 20)} fullWidth color='secondary' variant='outlined'>See more</Button>
						}</List>
				</Grid>
			</Grid>
		</>
	);
}

export default AccountsSearchList;
