import React, { Component } from 'react';
import api from '../../../../services/ApiServices';
import 'react-big-scheduler/lib/css/style.css'
import moment from 'moment'
import PlannedEventsCalendar from '../../../../components/shared/planned-events/PlannedEventsCalendar';
import PlannedEventsCategoryDisplay from '../../../../components/shared/planned-events/PlannedEventsCategoryDisplay';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

class AccountPlannedEventsCalendar extends Component {
	constructor(props) {
		super(props);

		// Build a static resource list with the events categories
		const resources = [
			{
				'id': 'categoryPATCHING',
				'name': 'Security patching'
			},
			{
				'id': 'categoryPATCHINGEMERGENCY',
				'name': 'Emergency security patching'
			},
			{
				'id': 'other',
				'name': 'Other'
			}];

		this.state = {
			events: [],
			eventsLoading: true,
			resources
		};
	}

	reload() {
		this.setState({ eventsLoading: true });
		api.PlannedEvents.getAccountPlannedEvents(this.props.aspireCode, this.props.accountName)
			.then((response) => {
				const events = response.data;
				const transformedEvents = events.map(accountEvent => {
					const category = accountEvent.event.category;
					let displayInfo = PlannedEventsCategoryDisplay.getDisplayInfo(category)

					let resourceId = "other";
					if (category === 'MachinesSecurityMaintenance/Patching') {
						resourceId = "categoryPATCHING";
					} else if (category === 'MachinesSecurityMaintenance/Patching/Emergency') {
						resourceId = "categoryPATCHINGEMERGENCY";
					}

					// Compute if the event is starts in the past and is finished
					const startDate = moment(accountEvent.event.startDate);
					const endDate = moment(accountEvent.event.endDate);
					const now = moment();
					const isPastEvent = startDate < now && endDate < now;

					return {
						id: accountEvent.event.id,
						start: startDate.format('YYYY-MM-DD HH:mm:ss'),
						end: endDate.format('YYYY-MM-DD HH:mm:ss'),
						resourceId: resourceId,
						isPastEvent,
						title: accountEvent.event.title,
						description: accountEvent.event.description,
						category: accountEvent.event.category,
						displayInfo: displayInfo,
						bgColor: displayInfo.Color,
						rawEvent: accountEvent
					};
				});
				this.setState({events: transformedEvents});
			})
			.finally(() => {
				this.setState({ eventsLoading: false });
			});
	}

	componentDidMount() {
		this.reload();
	}

	render() {
		return <Grid container spacing={3}>
			<Grid item xs={12} sm={12}>
				{!(this.state.eventsLoading || this.state.accountsLoading) &&
				<h4>Calendar</h4>
				}
				<PlannedEventsCalendar loading={this.state.eventsLoading} events={this.state.events}
									   resources={this.state.resources} resourceName={"Category"}/>
			</Grid>

			{!this.state.eventsLoading &&
				<>
					<Grid item xs={12} sm={12}>
						<Divider />
					</Grid>
					<Grid item xs={12} sm={12}>
						<h4>Upcoming events</h4>
					</Grid>
					<Grid item xs={12} sm={12}>
						<TableContainer component={Paper} style={{maxHeight: '500px'}}>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										<TableCell>Start</TableCell>
										<TableCell>End</TableCell>
										<TableCell>Title</TableCell>
										<TableCell>Description</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{this.state.events.map((event) => {
										if (!event.isPastEvent) {
											return <TableRow key={event.name}>
												<TableCell component="th" scope="row">
													{moment(event.rawEvent.event.startDate).format('YYYY/MM/DD')}
												</TableCell>
												<TableCell>
													{moment(event.rawEvent.event.endDate).format('YYYY/MM/DD')}
												</TableCell>
												<TableCell>{event.title}</TableCell>
												<TableCell>{event.description}</TableCell>
											</TableRow>
										}
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</>
			}
		</Grid>
	}
}

export default AccountPlannedEventsCalendar;
