import AddIcon from '@material-ui/icons/Add';
import classnames from 'classnames';
import { Button } from 'horizon-components-react';
import React, { Component } from 'react';
import { Field } from 'react-final-form';
import api from '../../../../services/ApiServices';
import DialogForm from '../../../../components/shared/DialogForm';
import { DefaultTextInputAdapter } from '../../../../components/shared/form/DefaultAdapter';
import './CreatePlatform.scss';
import DecommissionedTooltip from 'components/tooltips/DecommissionedTooltip';

class CreatePlatform extends Component {
	constructor(props) {
		super(props);
		this.formDialog = React.createRef();

		this.state = {
			saving: false
		};
		this.toggle = this.toggle.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	async toggle(event) {
		event.preventDefault();
		this.formDialog.current.open();
	}

	async handleSubmit(values) {
		try {
			const accountName = values.account;

			this.setState({ saving: true });
			await api.createPlatform(accountName);
		} finally {
			this.setState({ saving: false });
		}
	}

	render() {
		return (
			<span className={classnames(this.props.className)}>
				<DecommissionedTooltip>
					<Button
						disabled
						primary
						size="small"
						onClick={this.toggle}
						title="Create a platform"
					>
						<AddIcon />
						<span>Platform</span>
					</Button>
				</DecommissionedTooltip>
				<DialogForm
					className="create-platform-dialog"
					ref={this.formDialog}
					dialogTitle="Create a platform"
					onSubmit={this.handleSubmit}
					onConfirm={this.props.onConfirm}
				>
					<div className="mb-4">
						<label>
							Account
							<span className="mandatory">&nbsp;*</span>
						</label>
						<Field
							name="account"
							required={true}
							disabled={this.state.saving}
							component={DefaultTextInputAdapter}
							options={this.state.options}
							placeholder="Name of the account"
							autoFocus
						/>
					</div>
				</DialogForm>
			</span>
		);
	}
}

export default CreatePlatform;
