import RefreshIcon from '@material-ui/icons/Refresh';
import AppIcon from '@material-ui/icons/Web';
import {Button} from 'horizon-components-react';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import CreateApplication from '../components/application/CreateApplication';
import ApiTable from '../../../components/tables/ApiTable';
import api from '../../../services/ApiServices';
import {hasAnyAuthority} from '../../../store/UserStore';
import {Authorities} from '../../../utils/Authorities';
import {withContext} from '../../../store/ContextStore';
import {getColumnFilterDirection} from 'utils/Utils';


class AdminApplicationsPage extends Component {
    constructor(props) {
        super(props);
        this.apiTable = React.createRef();


        this.columns = [
            {
                name: 'Name',
                key: 'name',
                filter: true,
                cellRenderer: (appName, column, data) => {
                    const aspireCode = encodeURIComponent(data.aspireCode);
                    return (
                        <Link to={`/applications/${aspireCode}`} onClick={() => {
                            this.props.setAppId(data.aspireCode);
                        }}>{appName}</Link>
                    );
                },
                sort: getColumnFilterDirection('name', 'admin-apps-grid')
            },
            {
                name: 'Aspire Code',
                key: 'aspireCode',
                filter: true,
                sort: getColumnFilterDirection('aspireCode', 'admin-apps-grid')
            },
            {
                name: 'Service Line',
                key: 'serviceLine',
                filter: true,
                filterType: 'checkedList',
                sort: getColumnFilterDirection('serviceLine', 'admin-apps-grid')
            },
            {
                name: 'Owner Name',
                key: 'owner_name',
                filter: true,
                filterType: 'checkedList',
                sort: getColumnFilterDirection('owner_name', 'admin-apps-grid')
            },
            {
                name: 'Category',
                key: 'category',
                filter: true,
                filterType: 'checkedList',
                sort: getColumnFilterDirection('category', 'admin-apps-grid')
            }
        ];

        this.refresh = this.refresh.bind(this);
    }

    async load() {
        return await api.getAllApplications();
    }

    async delete(application) {
        await api.deleteApplication(application.aspireCode);
    }

    refresh() {
        this.apiTable.current.load();
    }

    render() {
        const readOnly = hasAnyAuthority(Authorities.ROLE_ADMIN_READONLY);

        return (
            <>
                <div className="float-right">
                    {!readOnly && (
                        <CreateApplication onConfirm={this.refresh} className="mr-1"/>
                    )}
                    <Button primary size="small" onClick={this.refresh}>
                        <RefreshIcon/>
                        Refresh
                    </Button>
                </div>
                <h2>
                    <AppIcon className="icon-page"/>
                    Application Management
                </h2>
                <hr/>
                <ApiTable
                    id="admin-apps-grid"
                    ref={this.apiTable}
                    columns={this.columns}
                    load={this.load}
                    delete={this.delete}
                    decommissioned={true}
                >
                    {!readOnly && <CreateApplication onConfirm={this.refresh}/>}
                </ApiTable>
            </>
        );
    }
}

export default withContext(AdminApplicationsPage);
