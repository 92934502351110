import AWSIcon from "@material-ui/icons/Cloud";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Button } from "horizon-components-react";
import React, { Component } from "react";
import ApiTable from "../../../components/tables/ApiTable";
import api from "../../../services/ApiServices";
import { getColumnFilterDirection } from "utils/Utils";

class AdminAWSServicesPage extends Component {
  constructor(props) {
    super(props);
    this.apiTable = React.createRef();

    this.columns = [
      {
        name: "Name",
        key: "name",
        sort: getColumnFilterDirection("name", "admin-aws-service-grid"),
      },
      {
        name: "Description",
        key: "description",
        sort: getColumnFilterDirection("description", "admin-aws-service-grid"),
      },
      {
        name: "Trust",
        key: "trust",
        sort: getColumnFilterDirection("trust", "admin-aws-service-grid"),
      },
    ];

    this.refresh = this.refresh.bind(this);
  }

  async load() {
    return await api.getAWSServices();
  }

  refresh() {
    this.apiTable.current.load();
  }

  render() {
    return (
      <>
        <div className="float-right">
          <Button primary size="small" onClick={this.refresh}>
            <RefreshIcon />
            Refresh
          </Button>
        </div>
        <h2>
          <AWSIcon className="icon-page" />
          AWS Services Management
        </h2>
        <hr />
        <ApiTable
          id={"admin-aws-service-grid"}
          rowsPerPage={25}
          ref={this.apiTable}
          decommissioned={true}
          columns={this.columns}
          load={this.load}
        />
      </>
    );
  }
}

export default AdminAWSServicesPage;
