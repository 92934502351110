import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import {Autosave, Button, GridCol, GridContainer, GridRow, Loading} from 'horizon-components-react';
import _ from 'lodash';
import React, {Component} from 'react';
import {Field, Form} from 'react-final-form';
import api from '../../../../services/ApiServices';
import {
	DefaultTextAreaAdapter,
	DefaultTextInputAdapter,
	DefaultTypeaheadAdapter
} from '../../../../components/shared/form/DefaultAdapter';
import './EditRuleForm.scss';
import {RulesStatus} from '../../../../utils/StatusOfAComplianceRule';
import {Switch} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const severitiesOptions = [
	{
		label: 'LOW',
		value: 'LOW'
	},
	{
		label: 'MEDIUM',
		value: 'MEDIUM'
	},
	{
		label: 'HIGH',
		value: 'HIGH'
	},
	{
		label: 'CRITICAL',
		value: 'CRITICAL'
	}
];

class EditRule extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			saving: false,
			hasAutomatedRemediation: props.rule.hasAutomatedRemediation,
			awsOptions: []
		};
		this.onSave = this.onSave.bind(this);
		this.cancel = this.cancel.bind(this);
		this.reloadRule = this.reloadRule.bind(this);
	}

	async onSave(values) {
		this.setState({
			saving: true
		});
		const toSend = _.cloneDeep(values);
		toSend.severity = toSend.severity.value;
		toSend.status = toSend.status.value;
		toSend.hasAutomatedRemediation = this.state.hasAutomatedRemediation;
		try {
			await api.ComplianceRules.updateComplianceRule(toSend);
			this.setState({
				saving: false
			});
			this.reloadRule();
		} catch {
			this.setState({
				saving: false
			});
		}
	}

	cancel(event) {
		event.preventDefault();
		if (typeof this.props.cancel === 'function') {
			this.props.cancel();
		}
		this.reloadRule();
	}

	reloadRule() {
		if (typeof this.props.reload === 'function') {
			this.props.reload();
			this.setState({"hasAutomatedRemediation": this.props.rule.hasAutomatedRemediation});
		}
	}

	render() {
		if (this.state.loading) {
			return <Loading>Loading&nbsp;AWS&nbsp;Services</Loading>;
		}

		const rule = _.cloneDeep(this.props.rule);

		rule.severity = severitiesOptions.find(
			option => option.value === rule.severity
		);
		rule.status = RulesStatus.find(
			option => option.value === rule.status
		);

		let ruleStatusHasNeverBeenInitialized = rule.status === undefined;
		if (ruleStatusHasNeverBeenInitialized) {
			rule.status = RulesStatus.find(
				option => option.value === 'draft'
			);
		}

		return (
			<Form
				initialValues={rule}
				onSubmit={this.onSave}
				render={({handleSubmit}) => (
					<form onSubmit={handleSubmit} style={{width: '1000px', marginLeft: 'auto', marginRight: 'auto'}}>
						<GridContainer>
							<GridRow>
								<GridCol sm="6">
									<GridRow>
										<GridCol sm="3">
											<div className="text-right">
												<b>Id</b>
											</div>
										</GridCol>
										<GridCol sm="8">
											<Field
												name="complianceId"
												component={DefaultTextInputAdapter}
												placeholder="Compliance identifier"
												required={true}
												errorText="Compliance id is required"
												disabled={true}
												autoFocus
											/>
										</GridCol>
									</GridRow>
									<GridRow>
										<GridCol sm="3">
											<div className="text-right">
												<b>Service</b>
											</div>
										</GridCol>
										<GridCol sm="8">
											<Field
												name="service"
												component={DefaultTextInputAdapter}
												placeholder="Service"
												required={true}
												errorText="Service is required"
												disabled={this.state.saving}
												autoFocus
											/>
										</GridCol>
									</GridRow>
									<GridRow>
										<GridCol sm="3">
											<div className="text-right">
												<b>Resource Type</b>
											</div>
										</GridCol>
										<GridCol sm="8">
											<Field
												name="resourceType"
												component={DefaultTextInputAdapter}
												placeholder="AWS::Lambda::Function"
												required={true}
												errorText="Type is required"
												disabled={this.state.saving}
											/>
										</GridCol>
									</GridRow>
									<GridRow>
										<GridCol sm="3">
											<div className="text-right">
												<b>Risk Type</b>
											</div>
										</GridCol>
										<GridCol sm="8">
											<Field
												name="riskType"
												component={DefaultTextInputAdapter}
												placeholder="Confidentiality, Integrity, Security..."
												disabled={this.state.saving}
											/>
										</GridCol>
									</GridRow>
									<GridRow>
										<GridCol sm="3">
											<div className="text-right">
												<b>Severity</b>
											</div>
										</GridCol>
										<GridCol sm="8">
											<Field
												name="severity"
												placeholder="Select a severity level"
												required={true}
												errorText="Severity is required"
												disabled={this.state.saving}
												options={severitiesOptions}
												component={DefaultTypeaheadAdapter}
												maxMenuHeight={200}
											/>
										</GridCol>
									</GridRow>
									<GridRow>
										<GridCol sm="3">
											<div className="text-right">
												<b>Status</b>
											</div>
										</GridCol>
										<GridCol sm="8">
											<Field
												name="status"
												placeholder="Select a status"
												required={true}
												errorText="Status is required"
												disabled={this.state.saving}
												options={RulesStatus}
												component={DefaultTypeaheadAdapter}
												maxMenuHeight={200}
											/>
										</GridCol>
									</GridRow>
								</GridCol>
								<GridCol sm="6">
									<div className="mb-4">
										<div className="mb-2">
											<b>Description</b>
										</div>
										<Field
											name="description"
											placeholder="A short description"
											rows={10}
											disabled={this.state.saving}
											component={DefaultTextAreaAdapter}
										/>
									</div>
									<div className="mb-4">
										<div className="mb-2">
											<b>Rationale</b>
										</div>
										<Field
											name="rationale"
											placeholder="Rationale"
											rows={10}
											disabled={this.state.saving}
											component={DefaultTextAreaAdapter}
										/>
									</div>
									<div className="mb-4">
										<div className="mb-2">
											<b>Remarks</b>
										</div>
										<Field
											name="remarks"
											placeholder="remarks"
											rows={10}
											disabled={this.state.saving}
											component={DefaultTextAreaAdapter}
										/>
									</div>
									<div className="mb-4">
										<div className="mb-2">
											<b>Remediation</b>
										</div>

										<FormControlLabel
											control={<Switch checked={this.state.hasAutomatedRemediation} onChange={((event, checked) => this.setState({"hasAutomatedRemediation": checked}))} />}
											label="Remediation is automated"/>

										<Field
											name="remediation"
											placeholder="Remediation procedure"
											rows={10}
											disabled={this.state.saving}
											component={DefaultTextAreaAdapter}
										/>
									</div>
								</GridCol>
							</GridRow>
							<GridRow justify="center">
								<GridCol xs={3}>
									<Button
										className="mr-1"
										size="small"
										onClick={this.cancel}
										disabled={this.state.saving}
									>
										<CancelIcon/>
										<span>&nbsp;Cancel</span>
									</Button>
									<Button size="small" primary disabled={this.state.saving}>
										{this.state.saving ? (
											<Autosave className="autosave-in-primary-button"/>
										) : (
                                            <>
                                                <SaveIcon/>
                                                <span>Save</span>
                                            </>
										)}
									</Button>
								</GridCol>
							</GridRow>
						</GridContainer>
					</form>
				)}
			/>
		);
	}
}

export default EditRule;
