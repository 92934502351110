import React, { useEffect } from 'react';
import { Redirect, Route, useParams, useLocation } from 'react-router-dom';
import { hasAnyAuthority } from '../../store/UserStore';
import { NotAllowedUrl } from './NotAllowed';
import posthog from 'posthog-js';

export const PostHogPageView = ({omitCapturePageView, children}) => {
	const params = useParams()
	const location = useLocation()

	// Track pageviews
	useEffect(() => {
	  if (posthog && !omitCapturePageView) {

		posthog.capture(
		  '$pageview',
		  {
			'$current_url': window.location.href,
			...params
		  }
		)
	  }
	}, [location])
	return children
}

export const PrivateRoute = ({ component, hasAnyAuthorities, omitCapturePageView, ...rest }) => {
	let Component = component
	return (
		<Route
			{...rest}
			render={props => {
				return hasAnyAuthority(hasAnyAuthorities) ? (
					<PostHogPageView omitCapturePageView={omitCapturePageView}><Component {...props} /></PostHogPageView>
				) : (
					<Redirect
						to={{
							pathname: NotAllowedUrl,
							state: { from: props.location }
						}}
					/>
				)
			}
			}
		/>
	);
};

export default PrivateRoute;
