import { TableCell, Typeahead } from 'horizon-components-react';
import _ from 'lodash';
import React, { Component } from 'react';
import './CheckedlistFilter.scss';

export const CHECKLIST_FILTER = 'checkedList';

class CheckedListFilter extends Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.filter = this.filter.bind(this);
		this.state = {
			options: []
		};
	}

	filter(data) {
		const column = this.props.column;
		return data.filter(aData => {
			let value = _.get(aData, column.key);
			if (typeof value === 'string') {
				if (
					this.state.options.findIndex(option => option.value === value) !== -1
				) {
					return true;
				}
			} else if (Array.isArray(value)) {
				for (var i = 0; i < value.length; i++) {
					let aValue = _.clone(value[i]);
					if (column.filterValue) {
						aValue = _.get(aValue, column.filterValue);
					}
					if (
						this.state.options.findIndex(option => option.value === aValue) !==
            -1
					) {
						return true;
					}
				}
			}
			return false;
		});
	}

	onChange(options) {
		this.setState(
			{
				options
			},
			this.props.updateData
		);
	}

	hasValue() {
		return this.state.options.length > 0;
	}

	saveFilter() {
		return {
			column: this.props.column,
			value: this.state.options
		};
	}

	loadFilter(value) {
		this.setState(
			{
				options: value
			},
			this.props.updateData
		);
	}

	getUniqueValues(column) {
		const options = [];
		this.props.data.forEach(aData => {
			let value = _.get(aData, column.key);

			if (Array.isArray(value)) {
				value.forEach(aValue => {
					let label = _.clone(aValue);
					let value = _.clone(aValue);
					if (column.filterLabel) {
						label = _.get(aValue, column.filterLabel);
					}
					if (column.filterValue) {
						value = _.get(aValue, column.filterValue);
					}

					if (options.findIndex(option => option.value === value) === -1) {
						options.push({
							label,
							value
						});
					}
				});
			} else if (options.findIndex(option => option.value === value) === -1) {
				options.push({
					label: value,
					value
				});
			}
		});

		options.sort((o1, o2) => {
			const l1 = '' + o1.label;
			const l2 = '' + o2.label;
			const s1 = l1.toLowerCase();
			const s2 = l2.toLowerCase();
			return s1.localeCompare(s2);
		});
		return options;
	}

	render() {
		const column = this.props.column;
		const options = this.getUniqueValues(column);

		return (
			<TableCell className="cell-filter" key={column.name}>
				<Typeahead
					className="checked-list-filter"
					value={this.state.options}
					options={options}
					onChange={this.onChange}
					isMulti
				/>
			</TableCell>
		);
	}
}

const CheckedListFilterWithRef = React.forwardRef((props, ref) => (
	<CheckedListFilter ref={ref} {...props} />
));

export default CheckedListFilterWithRef;
