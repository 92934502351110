import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import classnames from 'classnames';
import {
	Autosave,
	Button,
	Dialog,
	DialogActions,
	DialogContent
} from 'horizon-components-react';
import React, { Component } from 'react';

class ConfirmRemove extends Component {
	constructor(props) {
		super(props);
		this.state = {
			deleting: false,
			modal: false
		};
		this.toggle = this.toggle.bind(this);
		this.confirm = this.confirm.bind(this);
	}

	async confirm() {
		this.setState({
			deleting: true
		});
		try {
			if (typeof this.props.onConfirm === 'function') {
				await this.props.onConfirm(this.props.model);
				this.setState({
					deleting: false,
					modal: !this.state.modal
				});
			}
		} catch {
			this.setState({
				deleting: false
			});
		}
	}

	toggle() {
		this.setState({
			modal: !this.state.modal
		});
	}
	render() {
		const buttonLabel = this.props.buttonLabel
			? this.props.buttonLabel
			: 'Delete';
		const msg = this.props.children
			? this.props.children
			: 'Are you sure you want to remove ?';
		const buttonSize = this.props.buttonSize
			? this.props.buttonSize
			: 'small';
		return (
			<span className={classnames(this.props.className)}>
				<Button disabled={this.props.disabled} secondary size={buttonSize} onClick={this.toggle} withIcon="true">
					<DeleteIcon />
					{buttonLabel}
				</Button>
				<Dialog
					title="Confirm deletion"
					open={this.state.modal}
					onClose={this.toggle}
					className={this.props.className}
				>
					<DialogContent>{msg}</DialogContent>
					<DialogActions>
						<Button onClick={this.toggle} disabled={this.state.deleting} withIcon="true">
							<CancelIcon />
							<span>Cancel</span>
						</Button>
						<Button
							primary
							onClick={this.confirm}
							disabled={this.state.deleting}
						 	withIcon="true"
						>
							{this.state.deleting ? (
								<Autosave className="autosave-in-primary-button" />
							) : (
                <>
                  <DeleteIcon />
                  <span>Confirm</span>
                </>
							)}
						</Button>
					</DialogActions>
				</Dialog>
			</span>
		);
	}
}

export default ConfirmRemove;
