import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import {
	Autosave,
	Button, GridCol, GridContainer, GridRow
} from 'horizon-components-react';
import React, { Component } from 'react';
import { Form } from 'react-final-form';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';


class DialogForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			saving: false,
			modal: false
		};

		this.toggle = this.toggle.bind(this);
		this.open = this.open.bind(this);
		this.close = this.close.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	open() {
		this.setState({
			modal: true
		});
	}

	close() {
		this.setState({
			modal: false
		});
	}

	toggle(event) {
		event.preventDefault();
		this.setState({
			modal: !this.state.modal
		});
	}

	async onSubmit(values) {
		try {
			this.setState({
				saving: true
			});
			await this.props.onSubmit(values);
			this.setState({
				modal: !this.state.modal,
				saving: false
			});
			if (typeof this.props.onConfirm === 'function') {
				this.props.onConfirm();
			}
		} catch (e) {
			this.setState({
				saving: false
			});
		}
	}

	render() {
		const dialogTitle = this.props.dialogTitle
			? this.props.dialogTitle
			: 'Form title to fill';
		const saveIcon = this.props.saveIcon ? this.props.saveIcon : <SaveIcon />;
		const saveLabel = this.props.saveLabel ? this.props.saveLabel : 'Save';
		return (
			<Dialog
				maxWidth={'md'}
				fullWidth={true}
				open={this.state.modal}
				onClose={this.toggle}
				scroll="body"
			>
				<Form
					initialValues={this.props.model}
					onSubmit={this.onSubmit}
					render={({ handleSubmit, form, submitting, pristine, values }) => (
						<form onSubmit={handleSubmit}>
							<DialogContent>
								<GridContainer>
									<GridRow>
										<GridCol>
											<h3>{dialogTitle}</h3>
										</GridCol>
									</GridRow>
									{this.props.children}
								</GridContainer>
							</DialogContent>
							<DialogActions>
								<Button
									type="button"
									onClick={this.toggle}
									disabled={this.state.saving}
								>
									<CancelIcon />
									<span>&nbsp;Cancel</span>
								</Button>
								<Button primary disabled={this.state.saving}>
									{this.state.saving ? (
										<Autosave className="autosave-in-primary-button" />
									) : (
										<>
										  {saveIcon}
										  {saveLabel}
										</>
									)}
								</Button>
							</DialogActions>
						</form>
					)}
				/>
			</Dialog>
		);
	}
}

const DialogFormWithRef = React.forwardRef((props, ref) => (
	<DialogForm ref={ref} {...props} />
));

export default DialogFormWithRef;
