import React from 'react';
import {MuiPickersUtilsProvider, TimePicker} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import {CardContent} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';


const MachinesSecurityMaintenanceConfigurationForEnvironment = ({handleConfigurationChange, configuration, label, disabled}) => {
    return <Card>
        <CardHeader
            title={label}
        />
        <CardContent>
            <FormControl style={{width: '100%', paddingBottom: '12px'}}>
                <InputLabel shrink>Day of the week *</InputLabel>
                <Select
                    MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        }
                    }}
                    value={configuration.start_day}
                    required={true}
                    disabled={disabled}
                    onChange={event =>
                        handleConfigurationChange({...configuration, start_day: event.target.value})
                    }>
                    <MenuItem value={'MONDAY'}>Monday</MenuItem>
                    <MenuItem value={'TUESDAY'}>Tuesday</MenuItem>
                    <MenuItem value={'WEDNESDAY'}>Wednesday</MenuItem>
                    <MenuItem value={'THURSDAY'}>Thursday</MenuItem>
                    <MenuItem value={'FRIDAY'}>Friday</MenuItem>
                    <MenuItem value={'SATURDAY'}>Saturday</MenuItem>
                    <MenuItem value={'SUNDAY'}>Sunday</MenuItem>
                </Select>
            </FormControl>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <TimePicker
                    autoOk
                    label='Time of the day'
                    variant='inline'
                    ampm={false}
                    required={true}
                    disabled={disabled}
                    value={moment({'hour': configuration.start_hour, 'minute': 0, 'second': 0})}
                    views={['hours']}
                    onChange={(value) => {
                        configuration.start_hour = value.hours()
                        handleConfigurationChange({...configuration});
                    }}
                />
            </MuiPickersUtilsProvider>
        </CardContent>
    </Card>
    ;
};
export default MachinesSecurityMaintenanceConfigurationForEnvironment;
