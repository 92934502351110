import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import api from '../../../../services/ApiServices';
import {withContext} from '../../../../store/ContextStore';
import {Loading} from 'horizon-components-react';
import DecommissionedTooltip from 'components/tooltips/DecommissionedTooltip';

class DetachAccountFromApplication extends Component {


	constructor(props) {
		super(props);

		this.state = {open: false, saving: false};
		this.handleClickOpen = this.handleClickOpen.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.detachAccountToApplication = this.detachAccountToApplication.bind(this);
	}

	handleClickOpen() {
		this.setState({open: true});
	}

	handleClose() {
		this.setState({open: false});
	}

	async detachAccountToApplication() {
		this.setState({saving: true});
		await api.detachAccountFromApplication(this.props.appId, this.props.accountSelected);
		this.props.onConfirm();
	}

	render() {
		if (this.state.loading) {
			return <Loading>Loading&nbsp;AWS&nbsp;Services</Loading>;
		}
		return (
            <>
				<DecommissionedTooltip>
					<Button
						disabled
						style={{float: 'right'}}
						size="small"
						color="secondary"
						onClick={this.handleClickOpen}
					>
						Detach
					</Button>
				</DecommissionedTooltip>
                <Dialog
                	open={this.state.open}
                	onClose={this.handleClose}
                	aria-labelledby="alert-dialog-title"
                	aria-describedby="alert-dialog-description"
                >
                	<DialogContent>
                		<DialogContentText id="alert-dialog-description">
                            Do you want to detach <strong>{this.props.accountSelected}</strong> from your application ?
                		</DialogContentText>
                	</DialogContent>
                	<DialogActions>
                		<Button disabled={this.state.saving} onClick={this.handleClose} color="secondary">
                            Disagree
                		</Button>
                		<Button disabled={this.state.saving} onClick={this.detachAccountToApplication} color="primary">
                            Agree
                		</Button>
                	</DialogActions>
                </Dialog>
            </>
		);
	}
}

export default withContext(DetachAccountFromApplication);
