import OrganizationIcon from '@material-ui/icons/Business';
import RefreshIcon from '@material-ui/icons/Refresh';
import {Button} from 'horizon-components-react';
import React, {Component} from 'react';
import CreateOrEditOrganization from '../components/organization/CreateOrEditOrganization';
import ApiTable from '../../../components/tables/ApiTable';
import api from '../../../services/ApiServices';
import {hasAnyAuthority} from '../../../store/UserStore';
import {Authorities} from '../../../utils/Authorities';
import {getColumnFilterDirection} from 'utils/Utils';

class AdminOrganizationsPage extends Component {
    constructor(props) {
        super(props);
        this.apiTable = React.createRef();

        this.columns = [
            {
                name: 'Name',
                key: 'name',
                filter: true,
                sort: getColumnFilterDirection('name', 'admin-organizations-grid')
            },
            {
                name: 'AWS ID',
                key: 'awsId',
                filter: true,
                sort: getColumnFilterDirection('awsId', 'admin-organizations-grid')
            }
        ];

        this.refresh = this.refresh.bind(this);
    }

    async load() {
        return await api.getOrganizations();
    }

    async delete(organization) {
        return await api.deleteOrganization(organization.id);
    }

    refresh() {
        this.apiTable.current.load();
    }

    render() {
        const readOnly = hasAnyAuthority(Authorities.ROLE_ADMIN_READONLY);

        return (
            <>
                <div className="float-right">
                    {!readOnly && (
                        <CreateOrEditOrganization
                            onConfirm={this.refresh}
                            className="mr-1"
                        />
                    )}
                    <Button primary size="small" onClick={this.refresh}>
                        <RefreshIcon/>
                        Refresh
                    </Button>
                </div>
                <h2>
                    <OrganizationIcon className="icon-page"/>
                    Organization Management
                </h2>
                <hr/>
                <ApiTable
                    id="admin-organizations-grid"
                    rowsPerPage={15}
                    ref={this.apiTable}
                    columns={this.columns}
                    load={this.load}
                    delete={this.delete}
                    decommissioned={true}
                >
                    {!readOnly && <CreateOrEditOrganization onConfirm={this.refresh}/>}
                </ApiTable>
            </>
        );
    }
}

export default AdminOrganizationsPage;
