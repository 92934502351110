import React, {Component} from 'react';
import './Layout.scss';
import {Route, Switch, withRouter} from 'react-router-dom';
import {withContext} from '../store/ContextStore';
import HomePage from '../pages/HomePage';
import LayoutForAdministration from '../domains/administration/layout/LayoutForAdministration';
import LayoutForCompliance from '../domains/compliance/layout/LayoutForCompliance';
import LayoutForApplication from '../domains/application/layout/LayoutForApplication';
import LayoutForTeam from '../domains/team/layout/LayoutForTeam';
import LayoutForSecurity from '../domains/security/layout/LayoutForSecurity';
import LayoutForProfile from "../domains/profile/layout/LayoutForProfile";

class Layout extends Component {
	render() {
		return (
            <>
                <div className="pcp-page-content">
                	<Switch>
                		<Route exact path="/" render={() => <HomePage/>}/>
                		<Route path="/applications*" render={() => <LayoutForApplication/>}/>
                		<Route path="/teams*" render={() => <LayoutForTeam/>}/>
                		<Route path="/admin*" render={() => <LayoutForAdministration/>}/>
                		<Route path="/compliancerules*" render={() => <LayoutForCompliance/>}/>
                		<Route path="/remediationcontrol*" render={() => <LayoutForSecurity/>}/>
                		<Route path="/profile*" render={() => <LayoutForProfile/>}/>
                	</Switch>
                </div>
            </>
		);
	}
}

export default withRouter(withContext(Layout));
