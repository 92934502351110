import AttachIcon from '@material-ui/icons/ArrowRightAlt';
import AppIcon from '@material-ui/icons/Web';
import { Button, Loading } from 'horizon-components-react';
import React, { Component } from 'react';
import { Field } from 'react-final-form';
import api from '../../../../services/ApiServices';
import { withContext } from '../../../../store/ContextStore';
import EnvTypes from '../../../../utils/EnvTypes';
import DialogForm from '../../../../components/shared/DialogForm';
import { DefaultTypeaheadAdapter } from '../../../../components/shared/form/DefaultAdapter';
import './AttachToApplication.scss';
import DecommissionedTooltip from 'components/tooltips/DecommissionedTooltip';

class AttachToApplication extends Component {
	constructor(props) {
		super(props);
		this.formDialog = React.createRef();
		this.account = this.props.model.name;

		this.typesOptions = EnvTypes.map(value => {
			return {
				label: value,
				value
			};
		});

		this.state = {
			loading: false,
			saving: false,
			options: [],
			model: {}
		};
		this.toggle = this.toggle.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	async toggle(event) {
		event.preventDefault();
		this.formDialog.current.open();
		this.loadApplications();
	}

	async loadApplications() {
		try {
			this.setState({ loading: true });
			const response = await api.getAllApplications();
			const applications = response.data;
			const options = applications.map(application => {
				return {
					label: `${application.name} (${application.aspireCode})`,
					value: application.aspireCode
				};
			});
			this.setState({
				options
			});
		} finally {
			this.setState({ loading: false });
		}
	}

	async handleSubmit(values) {
		try {
			const aspireCode = values.application.value;
			const envType = values.type.value;

			this.setState({ saving: true });
			await api.attachApplicationToAccount(aspireCode, this.account, envType);
		} finally {
			this.setState({ saving: false });
		}
	}

	render() {
		if (this.props.model.state !== 'READY') {
			return null;
		}

		return (
      <>
		<DecommissionedTooltip>
			<Button
				disabled
				primary
				size="small"
				onClick={this.toggle}
				title="Attach to application"
			>
				<AttachIcon />
				<AppIcon />
			</Button>
		</DecommissionedTooltip>
        <DialogForm
        	className="dialog-with-select-input"
        	ref={this.formDialog}
        	model={this.state.model}
        	dialogTitle={`Attach ${this.account} to an application`}
        	onSubmit={this.handleSubmit}
        	onConfirm={this.props.onConfirm}
        >
        	{this.state.loading ? (
        		<Loading>Loading&nbsp;applications</Loading>
        	) : (
            <>
              <div className="mb-4">
              	<label>
                  Application<span className="mandatory">&nbsp;*</span>
              	</label>
              	<Field
              		name="application"
              		required={true}
              		disabled={this.state.saving}
              		component={DefaultTypeaheadAdapter}
              		options={this.state.options}
              		autoFocus
              	/>
              </div>
              <div>
              	<label>
                  Type<span className="mandatory">&nbsp;*</span>
              	</label>
              	<Field
              		name="type"
              		required={true}
              		disabled={this.state.saving}
              		component={DefaultTypeaheadAdapter}
              		options={this.typesOptions}
              	/>
              </div>
            </>
        	)}
        </DialogForm>
      </>
		);
	}
}

export default withContext(AttachToApplication);
