import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import Checkbox from '@material-ui/core/Checkbox';
import classnames from 'classnames';
import { Button, Loading } from 'horizon-components-react';
import _ from 'lodash';
import React, { Component } from 'react';
import { Field } from 'react-final-form';
import api from '../../../../services/ApiServices';
import { withContext } from '../../../../store/ContextStore';
import DialogForm from '../../../../components/shared/DialogForm';
import { DefaultCreateTypeaheadAdapter } from '../../../../components/shared/form/DefaultAdapter';
import './CreateOrEditUserIntoPcp.scss';
import DecommissionedTooltip from 'components/tooltips/DecommissionedTooltip';

class CreateOrEditUserIntoPcp extends Component {
	availableRoles = ['ROLE_APP_DEVELOPER', 'ROLE_APP_OWNER'];

	adminAvailableRoles = [
		'ROLE_ADMIN',
		'ROLE_ADMIN_READONLY',
		'ROLE_SECURITY',
		'ROLE_USER'
	];

	constructor(props) {
		super(props);
		this.formDialog = React.createRef();
		this.admin = props.admin ? true : false;
		this.edit = props.model ? true : false;
		this.createUser = false;
		this.state = {
			saving: false,
			loading: false,
			usersOptions: [],
			model: this.edit ? props.model : {}
		};

		if (this.admin) {
			this.availableRoles = this.adminAvailableRoles;
		}

		this.toggle = this.toggle.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleEmailChange = this.handleEmailChange.bind(this);
		this.handleSendNotificationChange = this.handleSendNotificationChange.bind(this);
	}

	toggle(event) {
		event.preventDefault();
		if (this.edit && this.state.model && this.state.model.authorities) {
			const authorities = this.state.model.authorities.map(authority => {
				if (typeof authority === 'object') {
					return authority.name;
				}
				return authority;
			});
			let model = _.cloneDeep(this.state.model);
			model.authorities = authorities;
			this.setState({
				model
			});
		}
		this.formDialog.current.open();
		if (!this.edit) {
			this.loadUsers();
		}
	}

	async loadUsers() {
		this.setState({ loading: true });
		try {
			const response = await api.getAllUsers();

			let usersOptions = [];
			response.data.forEach(user => {
				const option = {
					label: user.email,
					value: user.id
				};
				usersOptions.push(option);
			});

			this.setState({
				usersOptions,
				loading: false
			});
		} catch (e) {
			this.setState({
				loading: false
			});
		}
	}

	handleEmailChange(option) {
		let model = this.state.model;
		model.email = option.label;

		if (option.__isNew__) {
			this.createUser = true;
		} else {
			this.createUser = false;
			model.id = option.value;
		}
		this.setState({ model });
	}

	handleSendNotificationChange(event) {
		const model = this.state.model;
		model.send_notification = event.target.checked;

		console.log("handleSendNotificationChange - new model: ", model);
		this.setState({ model });
	}

	async handleSubmit(values) {
		console.log("handleSubmit - values: ", values);
		if (this.admin) {
			if (this.createUser) {
				const response = await api.createUser({
					email: this.state.model.email
				});
				let user = response.data;
				await api.updateUser(user.id, {
					authorities: values.authorities,
					send_notification: `${values.send_notification}`
				});
			} else {
				await api.updateUser(this.state.model.id, {
					authorities: values.authorities,
					send_notification: `${values.send_notification}`
				});
			}
		} else {
			let authorities = [];
			values.authorities.forEach(value => {
				authorities.push({ name: value });
			});

			if (this.createUser) {
				const response = await api.createUser({
					email: this.state.model.email
				});
				let user = response.data;
				await api.attachApplicationToUser(
					this.props.appId,
					user.id,
					authorities,
					`${values.send_notification}`
				);
			} else if (this.edit) {
				await api.updateApplicationUser(
					this.props.appId,
					this.state.model.id,
					authorities,
					`${values.send_notification}`
				);
			} else {
				await api.attachApplicationToUser(
					this.props.appId,
					this.state.model.id,
					authorities,
					`${values.send_notification}`
				);
			}
		}
	}

	render() {
		let buttonLabel = 'Add';
		if (this.edit) {
			buttonLabel = 'Edit';
		}

		return (
			<span className={classnames(this.props.className)}>
				<DecommissionedTooltip>
					<Button
						disabled
						primary
						size="small"
						onClick={this.toggle}
					>
						{this.edit ? <EditIcon /> : <AddIcon />}
						{buttonLabel}
					</Button>
				</DecommissionedTooltip>
				<DialogForm
					className="create-edit-dialog dialog-with-select-input"
					ref={this.formDialog}
					model={this.state.model}
					dialogTitle={buttonLabel}
					onSubmit={this.handleSubmit}
					onConfirm={this.props.onConfirm}
				>
					{this.state.loading ? (
						<Loading>Loading&nbsp;users...</Loading>
					) : (
						<div className="create-or-edit-user-dialog-content">
							{this.edit ? (
								<div className="form-group">
									<label className="control-label">Email</label>
									<div>
										<p className="form-control-static">
											{this.state.model.email}
										</p>
									</div>
								</div>
							) : (
								<div className="mb-4">
									<label>Email</label>
									<Field
										name="email"
										placeholder="Select user by email or type a new email"
										required={true}
										errorText="AWS Name is required"
										disabled={this.state.saving}
										options={this.state.usersOptions}
										onChange={this.handleEmailChange}
										component={DefaultCreateTypeaheadAdapter}
										autoFocus
									/>
								</div>
							)}
							<div className="form-group">
								<label className="control-label">Receive notifications</label>
								<div>
									<p className="form-control-static">
										<Field name="send_notification" type="checkbox">
											{props => {
												console.log(props);
												return (
													<Checkbox
														placeholder="Receive notification"
														checked={props.input.checked}
														onChange={props.input.onChange}
														inputProps={{ 'aria-label': 'primary checkbox' }}
													/>
												)
											}}
										</Field>
									</p>
								</div>
							</div>
							<div>
								<label>
									Authorities&nbsp;<span className="mandatory">*</span>
								</label>
								<Field
									className="create-user-authorities"
									name="authorities"
									component="select"
									type="select"
									required={true}
									disabled={this.state.saving}
									multiple
								>
									{this.availableRoles.map(role => (
										<option value={role} key={role}>
											{role}
										</option>
									))}
								</Field>
							</div>
						</div>
					)}
				</DialogForm>
			</span>
		);
	}
}

export default withContext(CreateOrEditUserIntoPcp);
