import AddIcon from '@material-ui/icons/Add';
import classnames from 'classnames';
import { Button, Loading } from 'horizon-components-react';
import React, { Component } from 'react';
import { Field } from 'react-final-form';
import api from '../../../../services/ApiServices';
import DialogForm from '../../../../components/shared/DialogForm';
import {
	DefaultTextInputAdapter,
	DefaultTypeaheadAdapter
} from '../../../../components/shared/form/DefaultAdapter';
import './AddPartner.scss';
import DecommissionedTooltip from 'components/tooltips/DecommissionedTooltip';

class AddPartner extends Component {
	constructor(props) {
		super(props);
		this.formDialog = React.createRef();

		this.state = {
			loading: false,
			saving: false,
			options: []
		};
		this.toggle = this.toggle.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	async toggle(event) {
		event.preventDefault();
		this.formDialog.current.open();

		this.loadApplications();
	}

	async loadApplications() {
		try {
			this.setState({ loading: true });
			const response = await api.getAllApplications();
			const applications = response.data;
			const options = applications.map(application => {
				return {
					label: `${application.name} (${application.aspireCode})`,
					value: application.aspireCode
				};
			});
			this.setState({
				options,
				loading: false
			});
		} catch {
			this.setState({ loading: false });
		}
	}

	async handleSubmit(values) {
		try {
			this.setState({ saving: true });
			await api.addPartner(values.application.value, values.account);
			this.setState({ saving: false });
		} catch {
			this.setState({ saving: false });
		}
	}

	render() {
		return (
			<span className={classnames(this.props.className)}>
				<DecommissionedTooltip>
					<Button
						disabled
						primary
						size="small"
						onClick={this.toggle}
						title="Add a partner to an application"
					>
						<AddIcon />
						<span>Partner</span>
					</Button>
				</DecommissionedTooltip>
				<DialogForm
					className="dialog-with-select-input add-partner"
					ref={this.formDialog}
					dialogTitle="Add a partner"
					onSubmit={this.handleSubmit}
					onConfirm={this.props.onConfirm}
				>
					{this.state.loading ? (
						<Loading>Loading&nbsp;applications</Loading>
					) : (
            <>
              <div className="mb-4">
              	<label>
                  Partner account<span className="mandatory">&nbsp;*</span>
              	</label>
              	<Field
              		name="account"
              		required={true}
              		disabled={this.state.saving}
              		component={DefaultTextInputAdapter}
              		placeholder="Name of the account"
              		autoFocus
              	/>
              </div>
              <div className="mb-4">
              	<label>
                  Application<span className="mandatory">&nbsp;*</span>
              	</label>
              	<Field
              		name="application"
              		required={true}
              		disabled={this.state.saving}
              		component={DefaultTypeaheadAdapter}
              		options={this.state.options}
              	/>
              </div>
            </>
					)}
				</DialogForm>
			</span>
		);
	}
}

export default AddPartner;
