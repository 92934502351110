import AttachIcon from '@material-ui/icons/ArrowRightAlt';
import RoleIcon from '@material-ui/icons/Assignment';
import {Button, Loading} from 'horizon-components-react';
import React, {Component} from 'react';
import {Field} from 'react-final-form';
import api from '../../../../services/ApiServices';
import {withContext} from '../../../../store/ContextStore';
import DialogForm from '../../../../components/shared/DialogForm';
import {DefaultTypeaheadAdapter} from '../../../../components/shared/form/DefaultAdapter';
import './AttachRoleToUser.scss';
import DecommissionedTooltip from 'components/tooltips/DecommissionedTooltip';

class AttachRoleToUser extends Component {
	constructor(props) {
		super(props);
		this.formDialog = React.createRef();

		this.state = {
			loading: false,
			saving: false,
			accounts: [],
			roles: [],
			roleFieldDisable: true
		};
		this.toggle = this.toggle.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onAccountSelected = this.onAccountSelected.bind(this);
		this.onConfirm = this.onConfirm.bind(this);
	}

	async toggle(event) {
		event.preventDefault();
		this.formDialog.current.open();
		this.load();
	}

	async load() {
		try {
			this.setState({loading: true});
			api.getAccounts(this.props.appId).then(data => {
				const accountsOptions = data.map(account => {
					return {label: `${account.name} - ${account.type}`, value: account.name};
				});
				this.setState({
					accounts: accountsOptions
				});
			});
		} finally {
			this.setState({loading: false});
		}
	}

	async onSubmit(values) {
		await api.attachAwsRoleToUserInApplication(this.props.appId, this.props.userId, values.role.value);
	}

	onConfirm() {
		this.props.onConfirm();
	}

	async onAccountSelected(event) {
		try {
			this.setState({loading: true, roleFieldDisable: true});
			api.getApplicationAccountAwsRoles(this.props.appId, event.value).then(response => {
				const rolesOptions = response.data.map(role => {
					return {label: role.name, value: role.arn};
				});
				this.setState({
					roles: rolesOptions,
					roleFieldDisable: false
				});
			});
		} finally {
			this.setState({loading: false});
		}
	}

	render() {
		return (
            <>
				<DecommissionedTooltip>
					<Button
						disabled
						primary
						size="small"
						onClick={this.toggle}
						title="Attach Role"
						className="mr-1"
					>
						<AttachIcon/>
						<RoleIcon/>
					</Button>
				</DecommissionedTooltip>
                <DialogForm
                	className="attach-to-role-dialog"
                	ref={this.formDialog}
                	model={this.state.model}
                	dialogTitle={'Attach a role'}
                	onSubmit={this.onSubmit}
                	onConfirm={this.onConfirm}
                >
                	{this.state.loading ? (
                		<Loading>Loading roles</Loading>
                	) : (
                        <>
                            <label>Account</label>
                            <Field
                            	name="account"
                            	required={true}
                            	disabled={this.state.saving}
                            	component={DefaultTypeaheadAdapter}
                            	options={this.state.accounts}
                            	onChange={this.onAccountSelected}
                            	autoFocus
                            />

                            <label>Role</label>
                            <Field
                            	name="role"
                            	required={true}
                            	disabled={this.state.roleFieldDisable}
                            	component={DefaultTypeaheadAdapter}
                            	options={this.state.roles}
                            />
                        </>
                	)}
                </DialogForm>
            </>
		);
	}
}

export default withContext(AttachRoleToUser);
