import {store} from '../state/store';
import {HashRouter as Router} from 'react-router-dom';
import ContextStore, {withContext} from '../store/ContextStore';
import Layout from '../layout/Layout';
import {ToastContainer} from 'react-toastify';
import Footer from '../layout/footer/Footer';
import {Provider} from 'react-redux';
import React, {Component} from 'react';
import ExportControlDisclaimerPage from '../domains/exportcontrol/pages/ExportControlDisclaimerPage';
import api from '../services/ApiServices';
import Header from '../layout/header/Header';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import NewPortalBanner from 'components/banner/NewPortalBanner';

class PCPPortalApp extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			isUserInExportControlApp: false,
			isExportControlDisclaimerApproved: false,
			isExportControlDisclaimerDismissed: false
		}
	}

	componentDidMount() {
		this.setState({loading: true});

		const isDisclaimerDismissedFromLocalStorage = localStorage.getItem("exportcontrol-disclaimer-dismiss") === "true";
		this.setState({
			isExportControlDisclaimerDismissed: isDisclaimerDismissedFromLocalStorage
		});

		if (isDisclaimerDismissedFromLocalStorage === false) {
			this.refreshIsCurrentUserInExportControlApplication()
				.then((isUserInExportControlApp => {
					if (isUserInExportControlApp) {
						return this.refreshExportControlDisclaimerState();
					}
				}))
				.finally(() => {
					this.setState({loading: false})
				});
		} else {
			this.setState({loading: false});
		}
	}

	refreshIsCurrentUserInExportControlApplication() {
		return new Promise((resolve, reject) => {
			// Fetch if the user is in E/C app
			const isUserInExportControlAppFromLocalStorage = localStorage.getItem('exportcontrol-disclaimer-is-current-user-in-ec-app');
			if (isUserInExportControlAppFromLocalStorage === null) {
				// Fetch the status from the backend
				api.ExportControlDisclaimer.isCurrentUserInExportControlApplication().then(response => {
					const isUserInExportControlApp = response.data.isInExportControlApplication;
					localStorage.setItem("exportcontrol-disclaimer-is-current-user-in-ec-app", isUserInExportControlApp.toString());
					this.setState({ isUserInExportControlApp: isUserInExportControlApp });
					resolve(isUserInExportControlApp);
				}).catch(reason => reject(reason))
			} else {
				const isUserInExportControlApp = isUserInExportControlAppFromLocalStorage === "true";
				this.setState({
					isUserInExportControlApp: isUserInExportControlApp
				});
				resolve(isUserInExportControlApp);
			}
		});
	}

	refreshExportControlDisclaimerState() {
		return new Promise((resolve, reject) => {
			// Fetch if the user is in E/C app
			const isLastDisclaimerApprovedFromLocalStorage = localStorage.getItem('exportcontrol-last-disclaimer-is-accepted');
			if (isLastDisclaimerApprovedFromLocalStorage === null) {
				// Fetch the status from the backend
				api.ExportControlDisclaimer.getLastDisclaimerRecordForCurrentUser().then(response => {
					// Extract the status from the response
					let isLastDisclaimerApproved = false;
					const lastDisclaimerRecord = response.data.LastDisclaimerRecord;
					if (lastDisclaimerRecord && lastDisclaimerRecord.hasOwnProperty('disclaimer_accepted')) {
						isLastDisclaimerApproved = lastDisclaimerRecord['disclaimer_accepted'];
					}

					// Save the result in the local storage and update the current state
					localStorage.setItem("exportcontrol-last-disclaimer-is-accepted", isLastDisclaimerApproved.toString());
					this.setState({ isExportControlDisclaimerApproved: isLastDisclaimerApproved });

					// Send to the next promise
					resolve(isLastDisclaimerApproved);

				}).catch(reason => {
					reject(reason);
				});
			} else {
				const isLastDisclaimerApproved = isLastDisclaimerApprovedFromLocalStorage === "true";
				this.setState({ isExportControlDisclaimerApproved:isLastDisclaimerApproved });
				resolve(isLastDisclaimerApproved);
			}
		});
	}

	onDisclaimerDismiss() {
		localStorage.setItem("exportcontrol-disclaimer-dismiss", "true");
		this.setState({
			isExportControlDisclaimerDismissed: true
		})
	}

	render() {
		return (
			<Provider store={store}>
				<Router>
					<div className="App">
						<ContextStore>
							<Header/>
							<NewPortalBanner />
							{this.state.loading &&
								<Grid container spacing={3} style={{width: '80%', margin: 'auto'}}>
									<Grid item xs={12}>
										<div style={{textAlign: 'center'}}><CircularProgress /></div>
									</Grid>
								</Grid>
							}
							{!this.state.loading &&
								<>
									{(!this.state.isExportControlDisclaimerDismissed && this.state.isUserInExportControlApp && !this.state.isExportControlDisclaimerApproved)
										? <ExportControlDisclaimerPage onDismiss={() => this.onDisclaimerDismiss()}/>
										: <Layout/>
									}
								</>
							}
						</ContextStore>
						<ToastContainer/>
						<Footer/>
					</div>
				</Router>
			</Provider>
		);
	}
}

export default withContext(PCPPortalApp);