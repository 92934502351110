import React, {Component} from 'react';
import api from '../../../../services/ApiServices';
import ApiTable from '../../../../components/tables/ApiTable';
import {withContext} from '../../../../store/ContextStore';
import {getColumnFilterDirection} from 'utils/Utils';

class UserAwsRoles extends Component {


	constructor(props) {
		super(props);

		this.apiTable = React.createRef();
		this.load = this.load.bind(this);
		this.columns = [
			{
				name: 'Arn',
				key: 'arn',
				filter: true,
				sort: getColumnFilterDirection('name', 'user-awsroles-grid')
			}
		];
	}

	async load() {
		const userId = this.props.match.params.userId;
		return api.getAllAwsRolesOfUser(userId);
	}


	render() {
		return (
			<ApiTable
				id="user-role-grid"
				ref={this.apiTable}
				columns={this.columns}
				load={this.load}
			/>
		);
	}
}

export default withContext(UserAwsRoles);
