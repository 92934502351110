import RulesIcon from '@material-ui/icons/AssignmentTurnedIn';
import EditIcon from '@material-ui/icons/Edit';
import BackIcon from '@material-ui/icons/Reply';
import {Button, Loading} from 'horizon-components-react';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Toolbar from 'domains/compliance/components/Toolbar';
import {hasAnyAuthority} from 'store/UserStore';
import {Authorities} from 'utils/Authorities';
import './RulePage.scss';
import RuleCard from 'domains/compliance/components/rule/RuleCard';

const PERMISSION = {
	'toEditRule': [Authorities.ROLE_ADMIN, Authorities.ROLE_SECURITY]
};

function RulePage(props) {
	const ruleCard = React.createRef();
	const hasNoRightToEditRule = !hasAnyAuthority(PERMISSION.toEditRule);

	const [rule, setRule] = useState([]);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		setRule(props.rule);
		setLoading(props.loading);
	}, [props.rule, props.loading]);


	const goBackToRulesList =
        <Link key='back-to-list-button' to='/compliancerules'>
        	<Button secondary title="Back to list">
        		<BackIcon/>
        	</Button>
        </Link>;

	const editRule = <Button
		key='edit-rule-button'
		id='edit-rule-button'
		primary
		onClick={() => ruleCard.current.toggleEdition()}
		disabled={hasNoRightToEditRule}>
		<EditIcon/>
        Edit
	</Button>;

	return <>
        <Toolbar pageTitle='Detail of rule' pageIcon={<RulesIcon/>} actions={[goBackToRulesList, editRule]}/>
        {loading ? <Loading>Loading rule</Loading> : <RuleCard ref={ruleCard} rule={rule}/>}
    </>;
}

export default RulePage;
