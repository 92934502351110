import React from 'react';
import { withContext } from 'store/ContextStore';
import Grid from '@material-ui/core/Grid';
import ApplicationPlannedEventsCalendar from '../components/planned-events/ApplicationPlannedEventsCalendar';

const ApplicationPlannedEventsPage = ({appId}) => {
    return <>
        <h2>
            Planned events
        </h2>
        <hr/>
        <Grid container spacing={3}>
            <>
                <Grid item xs={12} sm={12}>
                    <ApplicationPlannedEventsCalendar aspireCode={appId} />
                </Grid>
            </>
        </Grid>
    </>;
};

export default withContext(ApplicationPlannedEventsPage);
