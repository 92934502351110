import RefreshIcon from "@material-ui/icons/Refresh";
import { Button, IconButton } from "horizon-components-react";
import React, { Component } from "react";
import ApiTable from "../../../components/tables/ApiTable";
import api from "../../../services/ApiServices";
import { getColumnFilterDirection } from "utils/Utils";
import ScalpelIcon from "../../../components/shared/icons/ScalpelIcon";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import InfoIconWithPopover from "../components/application/InfoIconWithPopover";
import { Delete } from "@material-ui/icons";

class AdminComplianceExceptionsScalpelAccountDetailsPage extends Component {
  constructor(props) {
    super(props);

    this.apiTableExistingPerRuleExceptions = React.createRef();
    this.apiTableColumnsExistingPerRuleExceptions = [
      {
        name: "RuleID",
        key: "rule_id",
        filter: true,
        sort: getColumnFilterDirection(
          "rule_id",
          "admin-compliance-exceptions-scalpel-grid"
        ),
      },
      {
        name: "",
        key: "",
        cellRenderer: (undefinedVariable, colums, data) => {
          return (
            <>
              {this.state.isRemovingRuleToPartialExceptionList &&
                this.state.ruleIdBeingRemovedFromException === data.rule_id && (
                  <CircularProgress size={"2rem"} />
                )}
              {(!this.state.isRemovingRuleToPartialExceptionList ||
                this.state.ruleIdBeingRemovedFromException !==
                  data.rule_id) && (
                <IconButton
                  size="small"
                  title={"Remove partial exception"}
                  disabled={this.state.isRemovingRuleToPartialExceptionList}
                  onClick={() => this.removeRuleIdToExceptions(data.rule_id)}
                  style={{ margin: "0px" }}
                >
                  <Delete />
                </IconButton>
              )}
            </>
          );
        },
      },
    ];

    this.apiTableNonComplianceBeingRemediatedWithoutFullException = React.createRef();
    this.apiTableColumnsNonComplianceBeingRemediatedWithoutFullException = [
      {
        name: "Region",
        key: "region",
        filter: true,
        filterType: "checkedList",
        sort: getColumnFilterDirection(
          "region",
          "admin-compliance-exceptions-scalpel-grid"
        ),
      },
      {
        name: "Service",
        key: "service",
        filter: true,
        filterType: "checkedList",
        sort: getColumnFilterDirection(
          "service",
          "admin-compliance-exceptions-scalpel-grid"
        ),
      },
      {
        name: "Resource Type",
        key: "resourceType",
        filter: true,
        filterType: "checkedList",
        sort: getColumnFilterDirection(
          "resourceType",
          "admin-compliance-exceptions-scalpel-grid"
        ),
      },
      {
        name: "Rule ID",
        key: "ruleId",
        filter: true,
        filterType: "checkedList",
        sort: getColumnFilterDirection(
          "ruleId",
          "admin-compliance-exceptions-scalpel-grid"
        ),
      },
      {
        name: "Resource Identifier",
        key: "resourceIdentifier",
        filter: true,
        sort: getColumnFilterDirection(
          "resourceIdentifier",
          "admin-compliance-exceptions-scalpel-grid"
        ),
      },
      /*{
                name: 'Last assessment date',
                key: 'lastAssessmentDate',
                filter: true,
                sort: getColumnFilterDirection('lastAssessmentDate', 'admin-compliance-exceptions-scalpel-grid')
            },*/
      {
        name: "",
        key: "",
        cellRenderer: (undefinedVariable, colums, data) => {
          return (
            <>
              {this.state.isAddingRuleToPartialExceptionList &&
                this.state.ruleIdBeingAddedToException === data.ruleId && (
                  <CircularProgress size={"2rem"} />
                )}
              {!this.isRuleInPartialExceptionList(data.ruleId) &&
                (!this.state.isAddingRuleToPartialExceptionList ||
                  this.state.ruleIdBeingAddedToException !== data.ruleId) && (
                  <IconButton
                    size="small"
                    title={"Add to partial exception"}
                    disabled={this.state.isAddingRuleToPartialExceptionList}
                    onClick={() => this.addRuleIdToExceptions(data.ruleId)}
                    style={{ margin: "0px" }}
                  >
                    <AddIcon />
                  </IconButton>
                )}
            </>
          );
        },
      },
    ];

    this.state = {
      accountName: props.match.params.accountName,
      accountId: props.match.params.accountId,

      exceptionsForThisAccount: {},

      isLoadingAccountDetails: true,
      isAddingRuleToPartialExceptionList: false,
      ruleIdBeingAddedToException: null,
    };

    this.loadRemediationExceptions = this.loadRemediationExceptions.bind(this);
    this.loadNonComplianceBeingRemediatedWithoutFullException = this.loadNonComplianceBeingRemediatedWithoutFullException.bind(
      this
    );

    this.loadAccountDetails();
  }

  loadAccountDetails() {
    api.Account.getAccountDetailsForName(this.state.accountName)
      .then((response) => this.setState({ accountId: response.data.accountId }))
      .finally(() => this.setState({ isLoadingAccountDetails: false }));
  }

  async loadRemediationExceptions() {
    return await api.ComplianceExceptions.getRemediationExceptionsForAccount(
      this.state.accountId
    ).then((response) => {
      this.setState({
        exceptionsForThisAccount: response.data,
      });
      return {
        data: response.data.perRuleExceptions,
      };
    });
  }

  async loadNonComplianceBeingRemediatedWithoutFullException() {
    return await api.ComplianceResults.getComplianceResultsForAccountId(
      this.state.accountId
    );
  }

  isRuleInPartialExceptionList(ruleId) {
    if (this.state.exceptionsForThisAccount) {
      return (
        this.state.exceptionsForThisAccount.perRuleExceptions.filter((item) => {
          return item.rule_id === ruleId;
        }).length > 0
      );
    } else {
      return false;
    }
  }

  addRuleIdToExceptions(ruleId) {
    // Compute the new exception list
    const previousPerRuleExceptions = [
      ...new Set(
        this.state.exceptionsForThisAccount.perRuleExceptions.map(
          (perRuleException) => {
            return perRuleException.rule_id;
          }
        )
      ),
    ];
    console.log(previousPerRuleExceptions);
    if (previousPerRuleExceptions.indexOf(ruleId) < 0) {
      this.setState({
        ruleIdBeingAddedToException: ruleId,
        isAddingRuleToPartialExceptionList: true,
      });
      const newExceptionsPerRuleIds = previousPerRuleExceptions.slice();
      newExceptionsPerRuleIds.push(ruleId);
      api.ComplianceExceptions.savePartialRemediationConfiguration(
        this.state.accountId,
        newExceptionsPerRuleIds,
        "Created with the compliance scalpel",
        "Created with the compliance scalpel"
      )
        .then(() => this.apiTableExistingPerRuleExceptions.current.load())
        .finally(() =>
          this.setState({
            ruleIdBeingAddedToException: null,
            isAddingRuleToPartialExceptionList: false,
          })
        );
    }
  }

  removeRuleIdToExceptions(ruleId) {
    // Compute the new exception list
    const previousPerRuleExceptions = [
      ...new Set(
        this.state.exceptionsForThisAccount.perRuleExceptions.map(
          (perRuleException) => {
            return perRuleException.rule_id;
          }
        )
      ),
    ];

    if (previousPerRuleExceptions.indexOf(ruleId) >= 0) {
      this.setState({
        ruleIdBeingRemovedFromException: ruleId,
        isRemovingRuleToPartialExceptionList: true,
      });
      const newExceptionsPerRuleIds = previousPerRuleExceptions
        .slice()
        .filter((ruleIdFromArray) => ruleIdFromArray !== ruleId);

      api.ComplianceExceptions.savePartialRemediationConfiguration(
        this.state.accountId,
        newExceptionsPerRuleIds,
        "Created with the compliance scalpel",
        "Created with the compliance scalpel"
      )
        .then(() => this.apiTableExistingPerRuleExceptions.current.load())
        .finally(() =>
          this.setState({
            ruleIdBeingRemovedFromException: null,
            isRemovingRuleToPartialExceptionList: false,
          })
        );
    }
  }

  render() {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item md={12} xs={12} xl={12}>
            <h2>
              <ScalpelIcon className="icon-page" />
              Compliance scalpel details for {this.state.accountName}
            </h2>
            <hr />
          </Grid>

          {this.state.isLoadingAccountDetails && (
            <Grid item xs={12}>
              <div style={{ textAlign: "center" }}>
                {" "}
                <CircularProgress />
              </div>
            </Grid>
          )}

          {!this.state.isLoadingAccountDetails && (
            <>
              <Grid item md={12} xs={12} xl={6}>
                <div className="float-right">
                  <Button
                    primary
                    size="small"
                    onClick={() =>
                      this.apiTableExistingPerRuleExceptions.current.load()
                    }
                  >
                    <RefreshIcon />
                    Refresh
                  </Button>
                </div>
                <h3>Exception configuration for {this.state.accountName}</h3>
                <p>
                  Remediation strategy:{" "}
                  <b>
                    {this.state.exceptionsForThisAccount.remediationStrategy}
                  </b>
                  <InfoIconWithPopover>
                    <ul>
                      <li>OFF = Remediation is fully disabled</li>
                      <li>PARTIAL = Remediation is disabled for some rules</li>
                      <li>ON = Remediation is fully enabled</li>
                    </ul>
                  </InfoIconWithPopover>
                </p>
                <ApiTable
                  id="admin-compliance-exceptions-scalpel-existing-rules-grid"
                  rowsPerPage={50}
                  decommissioned={false}
                  ref={this.apiTableExistingPerRuleExceptions}
                  columns={this.apiTableColumnsExistingPerRuleExceptions}
                  load={this.loadRemediationExceptions}
                  emptyTableMessage={"No rule in exception"}
                  initialSortColumn={
                    this.apiTableColumnsExistingPerRuleExceptions[4]
                  }
                  initialSortDirection={"asc"}
                />
              </Grid>

              <Grid item md={12} xs={12} xl={6}>
                <div className="float-right">
                  <Button
                    primary
                    size="small"
                    onClick={() =>
                      this.apiTableNonComplianceBeingRemediatedWithoutFullException.current.load()
                    }
                  >
                    <RefreshIcon />
                    Refresh
                  </Button>
                </div>
                {/* Use marginbottom in order to avoid reduction of the table (as the button is taking more space than the h3 in height*/}
                <h3 style={{ marginBottom: "12px" }}>
                  Remediations blocked by exception
                </h3>
                <ApiTable
                  id="admin-compliance-exceptions-scalpel-grid"
                  rowsPerPage={50}
                  decommissioned={false}
                  ref={
                    this
                      .apiTableNonComplianceBeingRemediatedWithoutFullException
                  }
                  columns={
                    this
                      .apiTableColumnsNonComplianceBeingRemediatedWithoutFullException
                  }
                  load={
                    this.loadNonComplianceBeingRemediatedWithoutFullException
                  }
                  emptyTableMessage={"No remediation blocked by exception"}
                  initialSortColumn={
                    this
                      .apiTableColumnsNonComplianceBeingRemediatedWithoutFullException[4]
                  }
                  initialSortDirection={"asc"}
                />
              </Grid>
            </>
          )}
        </Grid>
      </>
    );
  }
}

export default AdminComplianceExceptionsScalpelAccountDetailsPage;
