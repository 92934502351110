import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import PersonIcon from '@material-ui/icons/Person';
import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {Link, matchPath} from 'react-router-dom';
import './ProfileMenu.scss';

class ProfileMenu extends Component {
	renderMenuItem(label, icon, path) {
		const match = matchPath(this.props.location.pathname, {
			path: path,
			exact: true,
			strict: false
		});
		let selected = match ? match.isExact : false;
		return (
			<Link to={path}>
				<MenuItem selected={selected}>
					{icon}
					<span>{label}</span>
				</MenuItem>
			</Link>
		);
	}

	render() {
		return (
			<Paper>
				<MenuList>
					{this.renderMenuItem(
						'My AWS roles',
						<PersonIcon className="icon-menu"/>,
						'/profile/awsroles'
					)}
				</MenuList>
			</Paper>
		);
	}
}

export default withRouter(ProfileMenu);
