import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import AccountIcon from "@material-ui/icons/AccountBox";
import OrganizationIcon from "@material-ui/icons/Business";
import AWSIcon from "@material-ui/icons/Cloud";
import AMIIcon from "@material-ui/icons/Computer";
import KMSIcon from "@material-ui/icons/VpnKey";
import PersonIcon from "@material-ui/icons/Person";
import PeopleIcon from "@material-ui/icons/People";
import AppIcon from "@material-ui/icons/Web";
import HealingIcon from "@material-ui/icons/Healing";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link, matchPath } from "react-router-dom";
import "./AdministrationMenu.scss";
import MachinesSecurityMaintenanceIcon from "../../../components/shared/machine-security-maintenance/MachinesSecurityMaintenanceIcon";
import ScalpelIcon from "../../../components/shared/icons/ScalpelIcon";

class AdministrationMenu extends Component {
  renderMenuItem(label, icon, path) {
    const match = matchPath(this.props.location.pathname, {
      path: path,
      exact: true,
      strict: false,
    });
    let selected = match ? match.isExact : false;
    return (
      <Link to={path}>
        <MenuItem selected={selected}>
          {icon}
          <span>{label}</span>
        </MenuItem>
      </Link>
    );
  }

  render() {
    return (
      <Paper>
        <MenuList>
          {this.renderMenuItem(
            "Teams",
            <PeopleIcon className="icon-menu" />,
            "/admin/teams"
          )}

          {this.renderMenuItem(
            "Machines security maintenance",
            <MachinesSecurityMaintenanceIcon className="icon-menu" />,
            "/admin/maintenance-configuration"
          )}
          {this.renderMenuItem(
            "Manual EC2 Patching",
            <HealingIcon className="icon-menu" />,
            "/admin/manual-ec2-patching"
          )}
          {this.renderMenuItem(
            "Compliance exceptions scalpel",
            <ScalpelIcon className="icon-menu" />,
            "/admin/compliance-exceptions-scalpel"
          )}
        </MenuList>
      </Paper>
    );
  }
}

export default withRouter(AdministrationMenu);
