import {debounce} from 'lodash';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';

function AccountsSearchBar({onSearch}) {
	const onChangeSearch = (event) => {
		debounceSearch(event.target.value);
	};

	const debounceSearch = debounce(query => {
		onSearch(query.toLowerCase());
	}, 200);

	return <TextField
		placeholder={'Search by account name, strictly by its id, or even strictly by an aspire code'}
		onChange={onChangeSearch}
		style={{margin: 8}}
		fullWidth
		autoFocus
		margin="normal"
		InputLabelProps={{
			shrink: true,
		}}
		InputProps={{
			startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>
		}}
	/>;
}

export default AccountsSearchBar;
