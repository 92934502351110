import {GridCol, GridContainer, GridRow, Loading} from 'horizon-components-react';
import React, {Component} from 'react';
import api from '../../../services/ApiServices';
import {withContext} from '../../../store/ContextStore';
import AppCard from '../components/application/AppCard';

class MyApplicationsPage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			applications: [],
			loading: false
		};
	}

	async componentDidMount() {
		this.setState({
			loading: true
		});
		try {
			const applications = await api.getCurrentUserApplications();
			this.setState({
				applications: applications.data,
				loading: false
			});
		} catch (e) {
			this.setState({
				loading: false
			});
		}
	}

	renderApp(app) {
		return (
			<GridCol key={app.aspireCode} xs={12} md={6} lg={4}>
				<AppCard app={app}/>
			</GridCol>
		);
	}

	renderApps() {
		let userHasApplications = this.state.applications.length > 0;
		if(userHasApplications) {
			return this.state.applications.map(a => {
				return this.renderApp(a.application);
			});
		}else{
			return <p style={{fontWeight: 'bolder', fontSize: 'larger'}}>You have no application attached yet. Please contact the CRE team.</p>;
		}
	}

	render() {
		if (this.state.loading) {
			return <Loading>Loading applications</Loading>;
		}

		return (
			<GridContainer>
				<GridRow justify="center">{this.renderApps()}</GridRow>
			</GridContainer>
		);
	}
}

export default withContext(MyApplicationsPage);
