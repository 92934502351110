import { Checkbox, TableCell } from 'horizon-components-react';
import _ from 'lodash';
import React, { Component } from 'react';
import './CheckedlistFilter.scss';

export const BOOLEAN_FILTER = 'boolean';

class CheckboxFilter extends Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.filter = this.filter.bind(this);
		this.state = {
			checkboxValue: null
		};
		this.checkboxStates = [null, true, false];
		this.checkboxClick = 0;
	}

	filter(data) {
		const column = this.props.column;

		return data.filter(aData => {
			let value = _.get(aData, column.key);
			return value === this.state.checkboxValue;
		});
	}

	onChange() {
		this.checkboxClick++;
		const checkboxValue = this.checkboxStates[this.checkboxClick % 3];
		this.setState({ checkboxValue }, this.props.updateData);
	}

	hasValue() {
		return this.state.checkboxValue !== null;
	}

	saveFilter() {
		return {
			column: this.props.column,
			value: this.state.checkboxValue
		};
	}

	loadFilter(value) {
		if (value === true) {
			this.checkboxClick = 1;
		} else if (value === false) {
			this.checkboxClick = 2;
		} else {
			this.checkboxClick = 0;
		}
		this.setState(
			{
				checkboxValue: value
			},
			this.props.updateData
		);
	}

	render() {
		const column = this.props.column;
		const indeterminate =
      this.state.checkboxValue === null ||
      this.state.checkboxValue === undefined;
		return (
			<TableCell className="cell-filter" key={column.name}>
				<Checkbox
					className="checkbox-filter"
					onChange={this.onChange}
					checked={this.state.checkboxValue === true}
					indeterminate={indeterminate}
				/>
			</TableCell>
		);
	}
}

const CheckboxFilterWithRef = React.forwardRef((props, ref) => (
	<CheckboxFilter ref={ref} {...props} />
));

export default CheckboxFilterWithRef;
