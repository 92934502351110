import React from 'react';
import { withContext } from 'store/ContextStore';
import Grid from '@material-ui/core/Grid';
import AccountPlannedEventsCalendar from '../components/planned-events/AccountPlannedEventsCalendar';

const AccountPlannedEventsPage = ({appId, accountName}) => {
    return <>
        <h2>
            Planned events for account {accountName}
        </h2>
        <hr/>
        <Grid container spacing={3}>
            <>
                <Grid item xs={12} sm={12}>
                    <AccountPlannedEventsCalendar aspireCode={appId} accountName={accountName} />
                </Grid>
            </>
        </Grid>
    </>;
};

export default withContext(AccountPlannedEventsPage);
