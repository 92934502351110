import React, {Component} from 'react';
import {Button} from 'horizon-components-react';
import classnames from 'classnames';
import ContactMail from '@material-ui/icons/ContactMail';
import {toast} from 'react-toastify';
import api from '../../../../services/ApiServices';
import {Tooltip} from '@material-ui/core';


class ContactUsersApplication extends Component {

	constructor(props) {
		super(props);
		this.state = {
			usersEmails: []
		};

		this.copyUsersEmailsIntoClipboard = this.copyUsersEmailsIntoClipboard.bind(this);
	}

	async componentDidMount() {
		const appId = this.props.aspireCode;
		const usersApplication = (await api.getUsers(appId)).data;
		const usersEmails = usersApplication.map(user =>
			user.user.email
		);
		this.setState({usersEmails: usersEmails});
	}

	copyUsersEmailsIntoClipboard() {
		navigator.clipboard.writeText(this.state.usersEmails);
		toast.info('All users emails of this application are into your clipboard');
	}

	render() {
		return (
            <>
                <Tooltip title={'Copy all users emails into your clipboard'}>
                	<Button onClick={this.copyUsersEmailsIntoClipboard} className={classnames(this.props.className)}
                		primary
                		size="small">
                		<ContactMail/>
                	</Button>
                </Tooltip>
            </>
		);
	}
}

export default ContactUsersApplication;