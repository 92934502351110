import api from 'services/ApiServices';
import {
	ALL_REMEDIATION_ACCOUNTS_LOADED, ALL_REMEDIATION_ACCOUNTS_LOADING,
	CHANGE_STRATEGY_MODAL_CLOSED,
	CHANGE_STRATEGY_MODAL_OPENED,
	COMPLIANCE_RULES_LOADED,
	LOGBOOK_DELETED_LOGENTRY,
	LOGBOOK_DELETING_LOGENTRY,
	LOGBOOK_ERROR,
	LOGBOOK_LOADED,
	LOGBOOK_LOADING,
} from 'state/actions-types';
import {service} from 'domains/security/api/LambdaService';

export const loadLogbook = () => {
	return (dispatch) => {
		dispatch(logbookIsLoading());
		return api.getLogbook()
			.then(data => dispatch(logbookLoaded(data)))
			.catch(() => dispatch(logbookOnError()));
	};
};

export const deleteLogentry = (logentry) => {
	return async (dispatch) => {
		dispatch(logbookDeletingLogentry(logentry))
		await setTimeout(async () => {
			await api.deleteLogEntry(logentry)
			dispatch(logbookDeletedLogentry(logentry))
		}, 2000)
	}
}

export const loadComplianceRules = () => {
	return (dispatch) => {
		return api.ComplianceRules.getAllComplianceRules()
			.then(r => dispatch(compliancesRulesLoaded(r.data)));
	};
};

export const loadAllRemediationAccounts = () => {
	return (dispatch) => {
		dispatch(remediationControlLoading());
		return service.getAllAccountsRemediation()
			.then(r => dispatch(allRemediationAccountsLoaded(r.data)));
	};
};

export const changeStrategyModalOpened = () => {
	return {
		type: CHANGE_STRATEGY_MODAL_OPENED,
	};
};

export const changeStrategyModalClosed = () => {
	return {
		type: CHANGE_STRATEGY_MODAL_CLOSED,
	};
};

export const remediationControlLoading = () => {
	return {
		type: ALL_REMEDIATION_ACCOUNTS_LOADING,
	};
};

const compliancesRulesLoaded = (rules) => {
	return {
		type: COMPLIANCE_RULES_LOADED,
		payload: {
			rules
		}
	};
};

const allRemediationAccountsLoaded = (remediationAccounts) => {
	return {
		type: ALL_REMEDIATION_ACCOUNTS_LOADED,
		payload: [...remediationAccounts]

	};
};
const logbookLoaded = (logEntries) => {
	return {
		type: LOGBOOK_LOADED,
		payload: {
			logEntries
		}
	};
};

const logbookDeletingLogentry = (logentry) => {
	return {
		type: LOGBOOK_DELETING_LOGENTRY,
		payload: {
			...logentry
		}
	}
}

const logbookDeletedLogentry = (logentry) => {
	return {
		type: LOGBOOK_DELETED_LOGENTRY,
		payload: {
			...logentry
		}
	}
}

const logbookOnError = () => {
	return {
		type: LOGBOOK_ERROR
	};
};

export const addLogEntry = (logEntryInput) => {
	return (dispatch) => {
		dispatch(logbookIsLoading());
		return api.addLogEntry({
			type: logEntryInput.type,
			data: {
				...logEntryInput.payload
			},
			publicationDate: Math.floor(Date.now() / 1000)
		})
			.then(() => {
				dispatch(loadLogbook());
			});
	};
};

const logbookIsLoading = () => {
	return {
		type: LOGBOOK_LOADING
	};
};
