import PrivateRoute from '../../../components/shared/PrivateRoute';
import AdminHomePage from '../pages/AdminHomePage';
import {Authorities} from '../../../utils/Authorities';
import AdminApplicationsPage from '../pages/AdminApplicationsPage';
import AdminAWSServicesPage from '../pages/AdminAWSServicesPage';
import AdminUsersPage from '../pages/AdminUsersPage';
import AdminAmisPage from '../pages/AdminAmisPage';
import AdminAccountsPage from '../pages/AdminAccountsPage';
import AdminOrganizationsPage from '../pages/AdminOrganizationsPage';
import AdminKMSPage from '../pages/AdminKMSPage';
import {Switch} from 'react-router-dom';
import React from 'react';
import AccountApplications from '../components/account/AccountApplications';
import UserApplications from '../components/user/UserApplications';
import AdminTeamsPage from '../pages/AdminTeamsPage';
import UserAwsRoles from 'domains/administration/components/user/UserAwsRoles';
import AdminMachinesSecurityMaintenanceConfigurationPage from 'domains/administration/pages/AdminMachinesSecurityMaintenanceConfigurationPage';
import AdminManualEC2InstancePatchingPage from '../pages/AdminManualEC2InstancePatchingPage';
import UserOneLoginIamRoles from "../components/user/UserOneLoginIamRoles";
import AdminComplianceExceptionsScalpelPage from "../pages/AdminComplianceExceptionsScalpelPage";
import AdminComplianceExceptionsScalpelAccountDetailsPage
	from "../pages/AdminComplianceExceptionsScalpelAccountDetailsPage";

const RoutesForAdministration = () => {
	return (
		<Switch>
			<PrivateRoute
				exact
				path="/admin"
				component={AdminHomePage}
				hasAnyAuthorities={[Authorities.ROLE_ADMIN, Authorities.ROLE_ADMIN_READONLY]}
			/>
			<PrivateRoute
				exact
				path="/admin/applications"
				component={AdminApplicationsPage}
				hasAnyAuthorities={[Authorities.ROLE_ADMIN, Authorities.ROLE_ADMIN_READONLY]}
			/>
			<PrivateRoute
				exact
				path="/admin/aws-services"
				component={AdminAWSServicesPage}
				hasAnyAuthorities={[Authorities.ROLE_ADMIN, Authorities.ROLE_ADMIN_READONLY]}
			/>
			<PrivateRoute
				exact
				path="/admin/users"
				component={AdminUsersPage}
				hasAnyAuthorities={[Authorities.ROLE_ADMIN, Authorities.ROLE_ADMIN_READONLY]}
			/>
			<PrivateRoute
				exact
				path="/admin/users/:userId/applications"
				component={UserApplications}
				hasAnyAuthorities={[Authorities.ROLE_ADMIN, Authorities.ROLE_ADMIN_READONLY]}
			/>
			<PrivateRoute
				exact
				path="/admin/users/:userId/awsroles"
				component={UserAwsRoles}
				hasAnyAuthorities={[Authorities.ROLE_ADMIN, Authorities.ROLE_ADMIN_READONLY]}
			/>
			<PrivateRoute
				exact
				path="/admin/users/:userId/onelogin/iamroles"
				component={UserOneLoginIamRoles}
				hasAnyAuthorities={[Authorities.ROLE_ADMIN]}
			/>
			<PrivateRoute
				exact
				path="/admin/amis"
				component={AdminAmisPage}
				hasAnyAuthorities={[Authorities.ROLE_ADMIN, Authorities.ROLE_ADMIN_READONLY]}
			/>
			<PrivateRoute
				exact
				path="/admin/accounts"
				component={AdminAccountsPage}
				hasAnyAuthorities={[Authorities.ROLE_ADMIN, Authorities.ROLE_ADMIN_READONLY]}
			/>
			<PrivateRoute
				exact
				path="/admin/teams"
				component={AdminTeamsPage}
				hasAnyAuthorities={[Authorities.ROLE_ADMIN, Authorities.ROLE_ADMIN_READONLY]}
			/>
			<PrivateRoute
				exact
				path="/admin/accounts/:accountId/applications"
				component={AccountApplications}
				hasAnyAuthorities={[Authorities.ROLE_ADMIN, Authorities.ROLE_ADMIN_READONLY]}
			/>
			<PrivateRoute
				exact
				path="/admin/organizations"
				component={AdminOrganizationsPage}
				hasAnyAuthorities={[Authorities.ROLE_ADMIN, Authorities.ROLE_ADMIN_READONLY]}
			/>
			<PrivateRoute
				exact
				path="/admin/keys"
				component={AdminKMSPage}
				hasAnyAuthorities={[Authorities.ROLE_ADMIN]}
			/>
			<PrivateRoute
				exact
				path="/admin/maintenance-configuration"
				component={AdminMachinesSecurityMaintenanceConfigurationPage}
				hasAnyAuthorities={[Authorities.ROLE_ADMIN, Authorities.ROLE_ADMIN_READONLY]}
			/>
			<PrivateRoute
				exact
				path="/admin/manual-ec2-patching"
				component={AdminManualEC2InstancePatchingPage}
				hasAnyAuthorities={[Authorities.ROLE_ADMIN]}
			/>
			<PrivateRoute
				exact
				path="/admin/compliance-exceptions-scalpel"
				component={AdminComplianceExceptionsScalpelPage}
				hasAnyAuthorities={[Authorities.ROLE_ADMIN_READONLY, Authorities.ROLE_ADMIN]}
			/>
			<PrivateRoute
				exact
				path="/admin/compliance-exceptions-scalpel/accountName/:accountName"
				component={AdminComplianceExceptionsScalpelAccountDetailsPage}
				hasAnyAuthorities={[Authorities.ROLE_ADMIN, Authorities.ROLE_ADMIN_READONLY]}
			/>
		</Switch>
	);
};

export default RoutesForAdministration;
