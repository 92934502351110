import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import PeopleIcon from "@material-ui/icons/People";
import { Redirect } from "react-router-dom";
import AccountIcon from "@material-ui/icons/AccountBox";
import AppIcon from "@material-ui/icons/Web";
import SettingsIcon from "@material-ui/icons/Settings";
import { Tooltip } from "@material-ui/core";
import BackIcon from "@material-ui/icons/Reply";
import { Button } from "horizon-components-react";
import "./ApplicationSettingsMenu.scss";
import PaletteIcon from "@material-ui/icons/Palette";
import api from "services/ApiServices";
import { hasAnyAuthority } from "store/UserStore";
import { Authorities } from "utils/Authorities";
import { withContext } from "store/ContextStore";
import PlannedEventIcon from "@material-ui/icons/Event";

class ApplicationSettingsMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToApplications: false,
      redirectToAccounts: false,
      isAppOwner: false,
    };
    this.changeApplications = this.changeApplications.bind(this);
  }

  isSelected = (relativePath) =>
    this.props.location.pathname === this.buildPath(relativePath);
  isRootPathSelected = () =>
    this.props.location.pathname === `/applications/${this.props.appId}`;
  buildPath = (relativePath) =>
    `/applications/${this.props.appId}/${relativePath}`;

  changeApplications() {
    this.setState({ redirectToApplications: true });
  }

  async componentDidMount() {
    const response = await api.getCurrentUserApplications();
    const applications = response.data;
    applications.forEach((app) => {
      app.authorities.forEach((authority) => {
        if (
          authority.name === "ROLE_APP_OWNER" &&
          app.application.aspireCode === this.props.appId
        ) {
          this.setState({
            isAppOwner: true,
          });
        }
      });
    });
  }

  render() {
    if (this.state.redirectToApplications) {
      return <Redirect to={"/applications"} />;
    }

    const hasAuthorityToAccess =
      this.state.isAppOwner ||
      hasAnyAuthority([
        Authorities.ROLE_ADMIN,
        Authorities.ROLE_ADMIN_READONLY,
      ]);
    return (
      <div>
        <Paper>
          <MenuList>
            <div style={{ display: "flex", marginBottom: "3px" }}>
              <Tooltip title={"Choose another application"}>
                <Button
                  primary
                  style={{ width: "30px" }}
                  onClick={this.changeApplications}
                >
                  <BackIcon />
                </Button>
              </Tooltip>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "large",
                  color: "#0085ad",
                  textAlign: "center",
                  float: "right",
                  width: "100%",
                }}
              >
                <div className="applicationName">
                  <AppIcon />
                  {this.props.appId}
                </div>
              </div>
            </div>
            <Link to={this.buildPath("")}>
              <MenuItem selected={this.isRootPathSelected()}>
                <SettingsIcon className="icon-menu" />
                <span>Main data</span>
              </MenuItem>
            </Link>
            <Link to={this.buildPath("accounts")}>
              <MenuItem selected={this.isSelected("accounts")}>
                <AccountIcon className="icon-menu" />
                <span>Accounts</span>
              </MenuItem>
            </Link>
            <Link to={this.buildPath("planned-events")}>
              <MenuItem selected={this.isSelected("planned-events")}>
                <PlannedEventIcon className="icon-menu" />
                <span>Planned events</span>
              </MenuItem>
            </Link>
            {hasAuthorityToAccess ? (
              <Link to={this.buildPath("users")}>
                <MenuItem
                  disabled={!hasAuthorityToAccess}
                  selected={this.isSelected("users")}
                >
                  <PeopleIcon className="icon-menu" />
                  <span>Users</span>
                </MenuItem>
              </Link>
            ) : (
              <MenuItem
                disabled={!hasAuthorityToAccess}
                selected={this.isSelected("users")}
              >
                <PeopleIcon className="icon-menu" />
                <span>Users</span>
              </MenuItem>
            )}
          </MenuList>
        </Paper>
      </div>
    );
  }
}

export default withRouter(withContext(ApplicationSettingsMenu));
