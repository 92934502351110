import React from 'react';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import AppIcon from '@material-ui/icons/Web';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Filter1Icon from '@material-ui/icons/Filter1';
import {Link} from 'react-router-dom';

const UserViews = ({userUUID}) => {
	const pathToUsersApps = {
		pathname: `/admin/users/${userUUID}/applications`,
		applicationsOwnerType: 'users'
	};
	const pathToUsersRoles = {pathname: `/admin/users/${userUUID}/awsroles`};
	const pathToOneLoginAwsRoles = {pathname: `/admin/users/${userUUID}/onelogin/iamroles`};
	return <Grid container alignItems="center">
		<div style={{padding: '5px'}}><Link to={pathToUsersApps}><AppIcon/>Apps</Link></div>
		<Divider orientation="vertical" flexItem/>
		<div style={{padding: '5px'}}><Link to={pathToUsersRoles}><AssignmentIcon/>Roles</Link></div>
		<Divider orientation="vertical" flexItem/>
		<div style={{padding: '5px'}}><Link to={pathToOneLoginAwsRoles}><Filter1Icon/>OneLogin</Link></div>
	</Grid>;
};

export default UserViews;
