import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import TransformIcon from '@material-ui/icons/Transform';
import RemediationStrategySelector from 'domains/security/component/RemediationStrategies/RemediationStrategySelector';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {changeStrategyModalClosed} from 'state/actions';

const ChangeStrategy = ({account}) => {
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const isLoading = useSelector(state => state.remediationControl.isLoading);
	return <>
		<Button onClick={() => setOpen(true)} startIcon={<TransformIcon/>} variant='outlined'>Change remediation strategy</Button>
		<Dialog
			disableBackdropClick
			disableEscapeKeyDown
			fullScreen
			open={open}
		>
			<DialogTitle>Change remediation strategy for <strong>{account.aws_account_name}</strong></DialogTitle>
			<DialogContent dividers>
				<RemediationStrategySelector key={JSON.stringify(account)} account={account}/>
			</DialogContent>
			<DialogActions>
				<Button disabled={isLoading} onClick={() => {
					setOpen(false);
					dispatch(changeStrategyModalClosed());
				}} color="primary">
                    Cancel
				</Button>
			</DialogActions>
		</Dialog></>;
};

export default ChangeStrategy;
