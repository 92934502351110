import {CardContent} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import React, {useState} from 'react';
import {remediationEventRenderers} from 'domains/security/pages/Renderers';
import Button from '@material-ui/core/Button';


const RemediationEventsCollapse = ({expanded, events}) => {
	const [slice, setSlice] = useState(5);
	const remediationEvents = events
		.slice(0, slice)
		.map(event => React.createElement(remediationEventRenderers[event.method], {event}));

	return <Collapse in={expanded} timeout="auto" unmountOnExit>
		<CardContent>
			{remediationEvents}
			{(events.length > 5 || slice >= events.length) &&
                <Button onClick={() => setSlice(slice + 5)} fullWidth color='secondary' variant='outlined'>See more</Button>}
		</CardContent>
	</Collapse>;
}
;

export default RemediationEventsCollapse;
