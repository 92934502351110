import React, {Component, createContext} from 'react';
import api from '../services/ApiServices';
import {Authorities} from 'utils/Authorities';
export const Context = createContext({});

class ContextStore extends Component {
	constructor(props) {
		super(props);

		this.state = {
			appId: localStorage.getItem('appId'),
			accountName: localStorage.getItem('accountName'),
			accountType: localStorage.getItem('accountType'),
			authorities: localStorage.getItem('authorities'),
			setAppId: appId => {
				this.setState({
					appId
				});
				this.setAuthoritiesOnApp(appId);
				localStorage.setItem('appId', appId);
			},
			setAccountType: accountType => {
				this.setState({
					accountType
				});
				localStorage.setItem('accountType', accountType);
			},
			setAccountName: accountName => {
				this.setState({
					accountName
				});
				localStorage.setItem('accountName', accountName);
			}
		};
		this.setAuthoritiesOnApp = this.setAuthoritiesOnApp.bind(this);
	}

	async setAuthoritiesOnApp (appId) {
		let currentUserApplications = await api.getCurrentUserApplications(appId);
		let authorities = [];
		currentUserApplications.data.forEach(app => {
			if (appId === app.application.aspireCode) {
				authorities = app.authorities.map(authority => {
					return Authorities[authority.name];
				});
			}
		});
		this.setState({ authorities: authorities });
		localStorage.setItem('authorities', authorities);
	}

	render() {
		return (
			<Context.Provider value={this.state}>
				{this.props.children}
			</Context.Provider>
		);
	}
}

export default ContextStore;

export const withContext = Component => passThroughProps => (
	<Context.Consumer>
		{contextProps => <Component {...passThroughProps} {...contextProps} />}
	</Context.Consumer>
);
