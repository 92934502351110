import React, {useEffect, useState} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from 'services/ApiServices';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';

const CampaignTable = ({campaigns}) => {
    return <TableContainer component={Paper} style={{marginBottom: '1rem'}}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Opened until</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Patch baseline date</TableCell>
                    <TableCell colSpan={2} align={'center'}>Prod</TableCell>
                    <TableCell colSpan={2} align={'center'}>Non-Prod</TableCell>
                    <TableCell colSpan={2} align={'center'}>Others</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell/>
                    <TableCell/>
                    <TableCell/>
                    <TableCell align={'center'}>Start</TableCell>
                    <TableCell align={'center'}>End</TableCell>
                    <TableCell align={'center'}>Start</TableCell>
                    <TableCell align={'center'}>End</TableCell>
                    <TableCell align={'center'}>Start</TableCell>
                    <TableCell align={'center'}>End</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {campaigns.map((value) => {
                    return <TableRow>
                        <TableCell>{moment(value.opened_until * 1000).format('YYYY-MM-DD')}</TableCell>
                        <TableCell>{value.emergency ? 'Emergency': 'Standard'}</TableCell>
                        <TableCell>{value.prod.approval_date}</TableCell>
                        <TableCell align={'center'}>{value.prod.start_date}</TableCell>
                        <TableCell align={'center'}>{value.prod.end_date}</TableCell>
                        <TableCell align={'center'}>{value.non_prod.start_date}</TableCell>
                        <TableCell align={'center'}>{value.non_prod.end_date}</TableCell>
                        <TableCell align={'center'}>{value.undefined.start_date}</TableCell>
                        <TableCell align={'center'}>{value.undefined.end_date}</TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </TableContainer>
};


const MachinesSecurityMaintenanceCampaigns = (props) => {
    const [loading, setLoading] = useState(true);
    const [onGoingCampaigns, setOnGoingCampaigns] = useState(null);
    const [passedCampaigns, setPassedCampaigns] = useState(null);

    useEffect(() => {
        setLoading(true);
        api.MachinesSecurityMaintenance.listAllMachinesSecurityMaintenanceCampaigns()
            .then(r => r.data)
            .then(data => {
                const nowTimestamp = new Date().getTime();

                let onGoingCampaigns = data.filter(function(item) {
                    return item.opened_until * 1000 >= nowTimestamp
                });
                onGoingCampaigns.sort((item1, item2) => item1.opened_until - item2.opened_until)
                setOnGoingCampaigns(onGoingCampaigns);

                let passedCampaigns = data.filter(function(item) {
                    return item.opened_until * 1000 < nowTimestamp
                });
                passedCampaigns.sort((item1, item2) => item1.opened_until - item2.opened_until)
                setPassedCampaigns(passedCampaigns);
            })
            .finally(() => setLoading(false));
    }, [props.refreshToken]);

    return (
        <>
            <Grid item xs={12}>
                <h4 style={{marginBottom: '1em'}}>On-going</h4>
            </Grid>
            <Grid item xs={12}>
                {loading &&
                <div style={{textAlign: 'center'}}><CircularProgress/></div>
                }
                {!loading &&
                    <>
                        {onGoingCampaigns && onGoingCampaigns.length > 0 &&
                            <CampaignTable campaigns={onGoingCampaigns} />
                        }
                        {!onGoingCampaigns || onGoingCampaigns.length <= 0 &&
                        <p>No on-going campaigns</p>
                        }
                    </>
                }
            </Grid>

            <Grid item xs={12}>
                <h4 style={{marginBottom: '1em'}}>Passed</h4>
            </Grid>
            <Grid item xs={12}>
                {loading &&
                <div style={{textAlign: 'center'}}><CircularProgress/></div>
                }
                {!loading &&
                <>
                    {passedCampaigns && passedCampaigns.length > 0 &&
                        <CampaignTable campaigns={passedCampaigns} />
                    }
                    {!passedCampaigns || passedCampaigns.length <= 0 &&
                        <p>No passed campaigns</p>
                    }
                </>
                }
            </Grid>
        </>
    );
};
export default MachinesSecurityMaintenanceCampaigns;
