import Tooltip from "@material-ui/core/Tooltip";
import React from "react";

const DecomissionedTooltip = ({ decommissioned = true, children }) =>
  decommissioned ? (
    <Tooltip
      title={"Feature has been removed please go to the new Portal."}
      arrow
      interactive
    >
      <span>{children}</span>
    </Tooltip>
  ) : (
    children
  );

export default DecomissionedTooltip;
