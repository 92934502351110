import RefreshIcon from "@material-ui/icons/Refresh";
import ComplianceIcon from "@material-ui/icons/VpnKey";
import { Button } from "horizon-components-react";
import React, { Component } from "react";
import api from "../../../services/ApiServices";
import { Link } from "react-router-dom";
import ApiTable from "../../../components/tables/ApiTable";
import { withContext } from "../../../store/ContextStore";
import { getColumnFilterDirection } from "utils/Utils";
import Grid from "@material-ui/core/Grid";

class ComplianceRulesStatusPage extends Component {
  constructor(props) {
    super(props);
    this.apiTable = React.createRef();

    this.columns = [
      {
        name: "Resource",
        key: "resourceIdentifier",
        filter: true,
        sort: getColumnFilterDirection(
          "resourceIdentifier",
          "compliancedetail-grid"
        ),
      },
      {
        name: "Aws Resource type",
        key: "resourceType",
        filter: true,
        filterType: "checkedList",
        sort: getColumnFilterDirection("resourceType", "compliancedetail-grid"),
      },
      {
        name: "AWS Service",
        key: "service",
        filter: true,
        filterType: "checkedList",
        sort: getColumnFilterDirection("service", "compliancedetail-grid"),
      },
      {
        name: "Status",
        key: "assessStatus",
        filter: true,
        filterType: "checkedList",
        sort: getColumnFilterDirection("assessStatus", "compliancedetail-grid"),
      },
      {
        name: "Rule",
        key: "ruleId",
        filter: true,
        filterType: "checkedList",
        cellRenderer: (applicationName, column, data) => {
          const ruleId = encodeURIComponent(data.ruleId);
          return (
            <Link to={`/compliancerules/rule_id/${ruleId}`}>{ruleId}</Link>
          );
        },
      },
    ];

    this.state = {
      isLoading: false,
    };
  }

  onRefresh() {
    this.apiTable.current.load();
  }

  async load() {
    const appId = this.props.match.params.id;
    const accountName = this.props.match.params.accountName;
    return api.ComplianceRules.listAvailableServicesInComplianceRules()
      .then((r) => r.data)
      .then((awsServices) => {
        awsServices.sort();
        return awsServices;
      })
      .then((awsServices) => {
        const promises = awsServices.map((awsService) => {
          return api.ComplianceResults.getComplianceResults(
            appId,
            accountName,
            awsService
          ).then((r) => r.data);
        });
        return Promise.all(promises).then((results) => {
          return {
            data: [].concat.apply([], results),
          };
        });
      });
  }

  render() {
    return (
      <>
        <Grid container>
          <>
            <Grid item xs={6} md={6}>
              <h3>
                <ComplianceIcon className="icon-page" />
                <span>Detailed compliance results</span>
              </h3>
            </Grid>
            <Grid item xs={6} md={6} style={{ textAlign: "right" }}>
              <Button primary size="small" onClick={() => this.onRefresh()}>
                <RefreshIcon /> Refresh
              </Button>
            </Grid>
            <Grid item xs={12} md={12}>
              <hr />
            </Grid>
            <Grid item xs={12} md={12}>
              <ApiTable
                id="compliancedetail-grid"
                rowsPerPage={50}
                decommissioned={false}
                ref={this.apiTable}
                columns={this.columns}
                load={() => this.load()}
              />
            </Grid>
          </>
        </Grid>
      </>
    );
  }
}

export default withContext(ComplianceRulesStatusPage);
