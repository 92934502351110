import React, {useState} from 'react';
import {CardActions, CardContent, CardHeader, IconButton, ListItem} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Alert from '@material-ui/lab/Alert';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {service} from 'domains/security/api/LambdaService';
import Typography from '@material-ui/core/Typography';
import ChangeStrategy from 'domains/security/component/RemediationStrategies/ChangeStrategy';
import RemediationEventsCollapse from 'domains/security/component/RemediationEventsCollapse';


const useStyles = makeStyles(theme => ({
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	}
}));


const AccountWithRemediationPartial = ({account}) => {
	const classes = useStyles();
	const [expanded, setExpanded] = useState(false);
	const [events, setEvents] = useState([]);

	const getEvents = () =>
		service.getAllEventsForAccount(account.aws_account_id)
			.then(r => r.data)
			.then(data => setEvents(data))
			.then(() => setExpanded(!expanded));

	return (<ListItem>
		<Card style={{width: '100%'}}>
			<CardHeader title={
				<Alert severity="warning">
					{account.aws_account_name} has PARTIAL remediation
				</Alert>
			}
			subheader={<>{account.aws_account_id}
				<span style={{float: 'right'}}>{account.is_pcp_account && 'NON'}&nbsp;PCP ACCOUNT</span>
			</>}/>
			<CardContent>
				<Typography>
                    Following rules are excluded from PCP remediation :
					<ul>{account.rules_id.map(rule => <li>{rule}</li>)}</ul>
				</Typography>
			</CardContent>
			<CardActions disableSpacing>
				<ChangeStrategy account={account}/>
				<IconButton className={clsx(classes.expand, {
					[classes.expandOpen]: expanded,
				})}
				onClick={getEvents}>
					<ExpandMoreIcon/>
				</IconButton>
			</CardActions>
			{events.length > 0 && <RemediationEventsCollapse events={events} expanded={expanded}/> }
			{(events.length === 0 && expanded) && 'No remediation change has been done on this account'}
		</Card>
	</ListItem>);

};

export default AccountWithRemediationPartial;
