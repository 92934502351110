import RefreshIcon from "@material-ui/icons/Refresh";
import AddIcon from "@material-ui/icons/Add";
import WarningIcon from "@material-ui/icons/Warning";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import { Button } from "horizon-components-react";
import React, { Component } from "react";
import ApiTable from "../../../components/tables/ApiTable";
import api from "../../../services/ApiServices";
import { service as securityService } from "../../security/api/LambdaService";
import { getColumnFilterDirection } from "utils/Utils";
import ScalpelIcon from "../../../components/shared/icons/ScalpelIcon";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

class AdminComplianceExceptionsScalpelPage extends Component {
  constructor(props) {
    super(props);
    this.apiTable = React.createRef();
    this.state = {
      selectedRule: "",
      isLoadingAvailableRules: true,
      rules: [],
      isAddingExceptions: false,
    };

    let self = this;
    api.ComplianceRules.getAllComplianceRules()
      .then((r) => r.data)
      .then((rules) =>
        rules.filter((rule) => {
          return (
            rule.status &&
            (rule.status.toLowerCase() === "implemented" ||
              rule.status.toLowerCase() === "validated")
          );
        })
      )
      .then((rules) =>
        rules.filter((rule) => {
          return !rule.resourceType
            .toLowerCase()
            .startsWith("aws::ec2::instanceos");
        })
      )
      .then((rules) =>
        rules.filter((rule) => {
          return rule.complianceId.startsWith("PCPCOMP-");
        })
      )
      .then(function (rules) {
        rules.sort(function (a, b) {
          return a.complianceId.localeCompare(b.complianceId);
        });

        if (rules.length > 0) {
          self.setState({
            selectedRule: rules[0],
            rules: rules,
          });
        }
      })
      .finally(() => this.setState({ isLoadingAvailableRules: false }));

    this.columns = [
      {
        name: "Status Warning",
        key: "",
        filter: false,
        cellRenderer: (undefinedVariable, colums, data) => {
          console.log(data);
          return data.remediationRules !==
            data.pcpRemediationRules.join(":") ? (
            <WarningIcon style={{ color: "red" }} />
          ) : (
            <CheckIcon style={{ color: "green" }} />
          );
        },
      },
      {
        name: "Account Name",
        key: "accountAlias",
        filter: true,
        filterType: "checkedList",
        sort: getColumnFilterDirection(
          "accountAlias",
          "admin-compliance-exceptions-scalpel-grid"
        ),
      },
      {
        name: "Account ID",
        key: "accountId",
        filter: true,
        sort: getColumnFilterDirection(
          "accountId",
          "admin-compliance-exceptions-scalpel-grid"
        ),
      },
      {
        name: "Environment",
        key: "accountType",
        filter: true,
        filterType: "checkedList",
        sort: getColumnFilterDirection(
          "accountType",
          "admin-compliance-exceptions-scalpel-grid"
        ),
      },
      {
        name: "CERBAIR remediation TAG",
        key: "remediation",
        filter: true,
        filterType: "checkedList",
        sort: getColumnFilterDirection(
          "remediation",
          "admin-compliance-exceptions-scalpel-grid"
        ),
      },
      {
        name: "CERBAIR Remediation TAG rules",
        key: "remediationRules",
        filter: true,
        filterType: "checkedList",
        sort: getColumnFilterDirection(
          "remediationRules",
          "admin-compliance-exceptions-scalpel-grid"
        ),
      },

      {
        name: "PCP Remediation status",
        key: "pcpRemediation",
        filter: true,
        filterType: "checkedList",
        sort: getColumnFilterDirection(
          "remediation",
          "admin-compliance-exceptions-scalpel-grid"
        ),
      },
      {
        name: "PCP Remediation rules status",
        key: "pcpRemediationRules",
        filter: true,
        filterType: "checkedList",
        sort: getColumnFilterDirection(
          "remediationRules",
          "admin-compliance-exceptions-scalpel-grid"
        ),
        cellRenderer: (undefinedVariable, colums, data) => {
          console.log(data);
          return data.pcpRemediationRules.join(", ");
        },
      },

      {
        name: "Non-compliance for rule",
        key: "status.Fail",
        filter: false,
        sort: getColumnFilterDirection(
          "status.Fail",
          "admin-compliance-exceptions-scalpel-grid"
        ),
      },
    ];

    this.refresh = this.refresh.bind(this);
    this.addExceptions = this.addExceptions.bind(this);
    this.onChangeRule = this.onChangeRule.bind(this);
  }

  async load(rule) {
    return await api.ComplianceExceptions.listNonComplianceBeingRemediatedWithoutFullException(
      rule.complianceId
    );
  }

  refresh() {
    this.apiTable.current.load();
  }

  async addExceptions() {
    this.setState({ isAddingExceptions: true });
    console.log("Table data:", this.apiTable.current.dataPage);
    console.log("Selected Rule:", this.state.selectedRule);
    await Promise.all(
      this.apiTable.current.dataPage.map((account) => {
        const exceptionRules = [
          ...account.pcpRemediationRules.filter(
            (rule) => rule !== this.state.selectedRule.complianceId
          ),
          this.state.selectedRule.complianceId,
        ];
        return securityService.partialRemediation(
          account.accountId,
          exceptionRules,
          "Public cloud platform exception reduction",
          `Enable remediation on compliance rule ${this.state.selectedRule.complianceId}`
        );
      })
    );
    this.setState({ isAddingExceptions: false });
    this.refresh();
  }

  onChangeRule(newValue) {
    // Reload after state has been updated
    this.setState({ selectedRule: newValue }, () => {
      if (this.apiTable.current) this.apiTable.current.load();
    });
  }

  render() {
    return (
      <>
        <h2>
          <ScalpelIcon className="icon-page" />
          Compliance exceptions scalpel
        </h2>
        <hr />
        {this.state.isLoadingAvailableRules && (
          <Grid item xs={12}>
            <div style={{ textAlign: "center" }}>
              <CircularProgress /> <br /> Loading compliance rules...
            </div>
          </Grid>
        )}
        {!this.state.isLoadingAvailableRules && (
          <>
            <Grid container spacing={6}>
              <Grid item md={12} xs={12}>
                {this.state.rules && this.state.rules.length > 0 && (
                  <Autocomplete
                    options={this.state.rules}
                    style={{ width: "100%" }}
                    getOptionLabel={(option) =>
                      `${option.complianceId} - ${option.description}`
                    }
                    disableClearable
                    renderInput={(params) => (
                      <>
                        <h3 style={{ marginBottom: "1rem" }}>
                          Select compliance rule
                        </h3>
                        <TextField {...params} />
                      </>
                    )}
                    value={this.state.selectedRule}
                    onChange={(event, newValue) => this.onChangeRule(newValue)}
                  />
                )}
              </Grid>
              {this.state.selectedRule && (
                <Grid item md={12} xs={12}>
                  <div className="float-right">
                    <Button
                      primary
                      size="small"
                      onClick={this.addExceptions}
                      style={{ marginRight: "1rem" }}
                      disabled={this.state.isAddingExceptions}
                    >
                      <AddIcon />
                      Add Exceptions
                    </Button>
                    {this.state.isAddingExceptions && (
                      <CircularProgress
                        size={24}
                        style={{
                          position: "relative",
                          top: "10px",
                          left: "-90px",
                        }}
                      />
                    )}
                    <Button primary size="small" onClick={this.refresh}>
                      <RefreshIcon />
                      Refresh
                    </Button>
                  </div>
                  <h3 style={{ marginBottom: "1rem" }}>
                    Non compliance per account for rule{" "}
                    {this.state.selectedRule.complianceId}
                  </h3>
                  <ApiTable
                    id="admin-compliance-exceptions-scalpel-grid"
                    rowsPerPage={30}
                    ref={this.apiTable}
                    columns={this.columns}
                    decommissioned={false}
                    load={() => this.load(this.state.selectedRule)}
                    initialSortColumn={this.columns[4]}
                    initialSortDirection={"asc"}
                  />
                </Grid>
              )}
            </Grid>
          </>
        )}
      </>
    );
  }
}

export default AdminComplianceExceptionsScalpelPage;
