import React, {useEffect, useState} from 'react';
import MaintenanceCampaignIcon from '@material-ui/icons/Event';
import api from 'services/ApiServices';
import MachinesSecurityMaintenanceConfigurationForEnvironment from 'domains/administration/components/machines-security-maintenance/MachinesSecurityMaintenanceConfigurationForEnvironment';
import {Button} from 'horizon-components-react';
import {hasAnyAuthority} from 'store/UserStore';
import {Authorities} from 'utils/Authorities';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import moment from 'moment-timezone';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import MachinesSecurityMaintenanceCampaignCreation
    from '../components/machines-security-maintenance/MachinesSecurityMaintenanceCampaignCreation';
import MachinesSecurityMaintenanceCampaigns
    from '../components/machines-security-maintenance/MachinesSecurityMaintenanceCampaigns';

const timezoneOptions = moment.tz.names().map((timezone) => {
    return timezone
});

const AdminMachinesSecurityMaintenanceConfigurationPage = () => {
    const [isInit] = useState(false);
    const [configuration, setConfiguration] = useState({
        PROD: {
            timezone: ""
        },
        "NON_PROD": {
            timezone: ""
        },
        UNDEFINED: {
            timezone: ""
        }
    });

    const [campaignRefresh, setCampaignRefresh] = useState(null);

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        loadConfiguration();
        setCampaignRefresh(new Date().getTime());
    }, [isInit]);
    const loadConfiguration = () => api.MachinesSecurityMaintenance.getMaintenanceConfiguration().then(r => r.data)
        .then(conf => {
            if (conf && conf.length === 3) {
                setConfiguration({
                    PROD: conf.filter(c => c.environment === 'PROD')[0],
                    'NON_PROD': conf.filter(c => c.environment === 'NON_PROD')[0],
                    UNDEFINED: conf.filter(c => c.environment === 'UNDEFINED')[0],
                });
            }
        }).finally(() => {
            setLoading(false);
        });

    const readOnly = hasAnyAuthority(Authorities.ROLE_ADMIN_READONLY);
    const saveConfiguration = () => {
        setSaving(true);
        api.MachinesSecurityMaintenance.saveMaintenanceConfiguration(configuration).finally(() => {
            setSaving(false);
        });
    }

    return <>
        <div className="float-right">
            {!readOnly && (
                <Button primary size="medium" onClick={saveConfiguration} disabled={saving}>
                    {saving && <CircularProgress size={14} />}
                    {!saving && 'Save'}
                </Button>
            )}
        </div>
        <h2>
            <MaintenanceCampaignIcon className="icon-page"/> Machines security maintenance
        </h2>
        <hr/>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <h3>Default patching window</h3>
            </Grid>
            {loading &&
                <Grid item xs={12}>
                    <div style={{textAlign: 'center'}}> <CircularProgress /></div>
                </Grid>
            }

            {!loading &&
                <>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={timezoneOptions}
                            style={{ width: 300 }}
                            disabled={readOnly}
                            disableClearable
                            renderInput={(params) => <TextField {...params} label="Timezone *" />}
                            value={configuration.PROD.timezone}
                            onChange={(event, newValue) => setConfiguration({
                                    'PROD': {...configuration.PROD, 'timezone': newValue},
                                    'NON_PROD': {...configuration['NON_PROD'], 'timezone': newValue},
                                    'UNDEFINED': {...configuration.UNDEFINED, 'timezone': newValue}
                                }
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <MachinesSecurityMaintenanceConfigurationForEnvironment
                            disabled={readOnly}
                            configuration={configuration.PROD}
                            handleConfigurationChange={(prodConfiguration) => setConfiguration(
                                {
                                    PROD: {...prodConfiguration},
                                    'NON_PROD': {...configuration['NON_PROD']},
                                    UNDEFINED: {...configuration['UNDEFINED']}
                                }
                            )}
                            label={'PROD'}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <MachinesSecurityMaintenanceConfigurationForEnvironment
                            disabled={readOnly}
                            configuration={configuration['NON_PROD']}
                            handleConfigurationChange={(nonprodConfiguration) => setConfiguration(
                                {
                                    PROD: {...configuration['PROD']},
                                    'NON_PROD': {...nonprodConfiguration},
                                    UNDEFINED: {...configuration['UNDEFINED']}
                                }
                            )}
                            label={'NON-PROD'}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <MachinesSecurityMaintenanceConfigurationForEnvironment
                            disabled={readOnly}
                            configuration={configuration.UNDEFINED}
                            handleConfigurationChange={(undefinedConfiguration) => setConfiguration(
                                {
                                    PROD: {...configuration['PROD']},
                                    'NON_PROD': {...configuration['NON_PROD']},
                                    UNDEFINED: {...undefinedConfiguration}
                                }
                            )}
                            label={'UNDEFINED'}/>
                    </Grid>
                </>
            }

        <hr/>

        <Grid item xs={12}>
            <h3>Campaigns creation</h3>
        </Grid>
        <Grid item xs={12}>
            <MachinesSecurityMaintenanceCampaignCreation isEmergency={true} onCreate={() => setCampaignRefresh(new Date().getTime())}/>
        </Grid>

        <hr/>

        <Grid item xs={12}>
            <h3>Registered campaigns</h3>
        </Grid>

        <MachinesSecurityMaintenanceCampaigns refreshToken={campaignRefresh} />

        </Grid>
    </>;
}
;
export default AdminMachinesSecurityMaintenanceConfigurationPage;
