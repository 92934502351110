import React from 'react';
import RoutesForTeamSettings from './RoutesForTeamSettings';
import Grid from '@material-ui/core/Grid';
import {Card, CardContent} from 'horizon-components-react';
import TeamSettingsMenu from './TeamSettingsMenu';

const LayoutForTeamSettings = () => {
	return (
		<Grid container spacing={0}>
			<Grid item xs={12} md={3} lg={2}>
				<TeamSettingsMenu/>
			</Grid>
			<Grid item xs={12} md={9} lg={10}>
				<Card>
					<CardContent>
						<RoutesForTeamSettings/>
					</CardContent>
				</Card>
			</Grid>
		</Grid>);
};

export default LayoutForTeamSettings;
