import {Route, Switch} from 'react-router-dom';
import React from 'react';
import PrivateRoute from 'components/shared/PrivateRoute';
import TeamPage from 'domains/team/pages/TeamPage';
import {Authorities} from 'utils/Authorities';
import TeamMembersPage from 'domains/team/pages/TeamMembersPage';
import NotAllowed, {NotAllowedUrl} from 'components/shared/NotAllowed';
import NotFound from 'components/shared/NotFound';

const RoutesForTeamSettings = () => {
	return (
		<Switch>
			<PrivateRoute
				exact
				path="/teams/:id"
				component={TeamPage}
				hasAnyAuthorities={[
					Authorities.ROLE_USER,
					Authorities.ROLE_ADMIN,
					Authorities.ROLE_ADMIN_READONLY
				]}
			/>
			<PrivateRoute
				exact
				path="/teams/:id/users"
				component={TeamMembersPage}
				hasAnyAuthorities={[
					Authorities.ROLE_USER,
					Authorities.ROLE_ADMIN,
					Authorities.ROLE_ADMIN_READONLY
				]}
			/>
			<Route exact path={NotAllowedUrl} component={NotAllowed}/>
			<Route component={NotFound}/>
		</Switch>
	);
};

export default RoutesForTeamSettings;
