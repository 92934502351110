import React, {useState} from 'react';
import {ListItemAvatar, List, ListItem, ListItemText, TextField, Avatar} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import {useSelector} from 'react-redux';
import {Strategy} from 'domains/security/component/RemediationStrategies/RemediationStrategySelector';
import Button from '@material-ui/core/Button';
import {debounce} from 'lodash';

const getIconForAwsService = (service) => {
	try {
		return require('../../../../awsicons/' + service.toLowerCase() + '.png');
	} catch (e) {
		return require('../../../../awsicons/aws.png');
	}
};
const SelectRulesStrategy = ({disabledValidationButton, onValidationButtonClick, selectedRules, onSelectedRulesChanged, alreadySelectedRules, currentStrategy}) => {
	const rules = useSelector(state => state.remediationControl.rules);
	console.log(selectedRules)
	const [search, setSearch] = useState('');
	const foundRules = rules
		.filter(rule => !selectedRules.some(r => r.complianceId === rule.complianceId))
		.filter(rule => rule.complianceId.toLowerCase().includes(search.toLowerCase()));

	const debounceSearch = debounce(query => setSearch(query), 200);
	const removeRuleFromSelectedOnes = rule => onSelectedRulesChanged([...selectedRules.filter(selectedRule => selectedRule !== rule)]);
	const selectedRulesAreExactlyTheSameAsPrevious = selectedRules.map(rule => rule.complianceId).sort().join(',') === alreadySelectedRules.sort().join(',');
	return <>
		<TextField
			helperText={(selectedRulesAreExactlyTheSameAsPrevious && currentStrategy === Strategy.PARTIAL) && 'Current rules selected are the same as previously selected'}
			fullWidth
			InputLabelProps={{
				shrink: true,
			}}
			variant='outlined'
			InputProps={{
				startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>
			}}
			placeholder='Search for a compliance rule'
			onChange={event => debounceSearch(event.target.value)}/>
		<Button fullWidth
			disabled={disabledValidationButton} color='primary'
			variant='outlined'
			onClick={() => onValidationButtonClick()}>VALIDATE SELECTION</Button>
		<List>
			{selectedRules && selectedRules.map(rule =>
				<ListItem>
					<ListItemAvatar style={{margin: '5px'}}>
						<div style={{display: 'inline-block', textAlign: 'center'}}>
							<Avatar variant='square' style={{width: '60px', height: '60px'}} src={getIconForAwsService(rule.service)} alt={rule.service}/>
							<span style={{
								display: 'block',
								fontSize: '1rem',
								color: 'white',
								backgroundColor: 'black'
							}}>{rule.service}</span>
						</div>
					</ListItemAvatar>
					<ListItemText primary={rule.complianceId} secondary={rule.description}/>
					<IconButton onClick={() => removeRuleFromSelectedOnes(rule)}>
						<DeleteOutlineIcon color='secondary'/>
					</IconButton>
				</ListItem>)
			}
		</List>
		{search !== '' &&
        <Paper elevation={3}>
        	{foundRules.length > 0 &&
            <List>
            	{foundRules && foundRules.map(rule =>
            		<ListItem button onClick={() => onSelectedRulesChanged([...selectedRules, rule])}>
            			<ListItemAvatar style={{margin: '5px'}}>
            				<div style={{display: 'inline-block', textAlign: 'center'}}>
            					<Avatar variant='square' style={{width: '60px', height: '60px'}} src={getIconForAwsService(rule.service)} alt={rule.service}/>
            					<span style={{
            						display: 'block',
            						fontSize: '1rem',
            						color: 'white',
            						backgroundColor: 'black'
            					}}>{rule.service}</span>
            				</div>
            			</ListItemAvatar>
            			<ListItemText primary={rule.complianceId} secondary={rule.description}/>
            		</ListItem>)}
            </List>
        	}
        	{foundRules.length === 0 && <>No results found</>}
        </Paper>
		}
	</>;

};

export default SelectRulesStrategy;
