import { Banner } from 'horizon-components-react';
import React from 'react';

export const NotAllowedUrl = '/not-allowed';

const NotAllowed = () => (
	<div className="insufficient-authority">
		<Banner status="error" title="Forbidden">
      You are not authorized to access this page.
		</Banner>
	</div>
);
export default NotAllowed;
