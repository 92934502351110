import {Route, Switch} from 'react-router-dom';
import PrivateRoute from '../../../components/shared/PrivateRoute';
import {Authorities} from '../../../utils/Authorities';
import MyApplications from '../pages/MyApplicationsPage';
import NotAllowed, {NotAllowedUrl} from '../../../components/shared/NotAllowed';
import NotFound from '../../../components/shared/NotFound';
import React from 'react';
import LayoutForApplicationSetting from './LayoutForApplicationSettings';
import LayoutForAccount from '../../account/layout/LayoutForAccount';

const RoutesForApplication = () => {
	return (
		<Switch>
			<PrivateRoute
				exact
				path="/applications"
				component={MyApplications}
				hasAnyAuthorities={[
					Authorities.ROLE_USER,
					Authorities.ROLE_ADMIN,
					Authorities.ROLE_ADMIN_READONLY
				]}
			/>
			<PrivateRoute
				path="/applications/:id/accounts/*"
				component={LayoutForAccount}
				omitCapturePageView
				hasAnyAuthorities={[
					Authorities.ROLE_USER,
					Authorities.ROLE_ADMIN,
					Authorities.ROLE_ADMIN_READONLY
				]}
			/>
			<PrivateRoute
				exact
				path="/applications*"
				component={LayoutForApplicationSetting}
				omitCapturePageView
				hasAnyAuthorities={[
					Authorities.ROLE_USER,
					Authorities.ROLE_ADMIN,
					Authorities.ROLE_ADMIN_READONLY
				]}
			/>
			<Route exact path={NotAllowedUrl} component={NotAllowed}/>
			<Route component={NotFound}/>
		</Switch>
	);
};

export default RoutesForApplication;
