import {Card} from 'horizon-components-react';
import React from 'react';
import './MetricsOfCompliance.scss';

const MetricsOfCompliance = ({metrics}) => {
	return (
		<Card>
			<div className="metric-card">
				{metrics.map((metric, index) => {
					return (
						<div key={index} className="metric-content">
							<div className="metric-value">{metric.value}</div>
							<div className="metric-label">{metric.label}</div>
						</div>
					);
				})}
			</div>
		</Card>

	);
};

export default MetricsOfCompliance;
