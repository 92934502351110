import cx from 'classnames';
import {GridCol, GridContainer, GridRow, Loading} from 'horizon-components-react';
import moment from 'moment';
import React, {Component} from 'react';
import './ApplicationPage.scss';
import api from 'services/ApiServices';
import {hasAnyAuthority} from 'store/UserStore';
import {Authorities} from 'utils/Authorities';
import CreateApplication from 'domains/administration/components/application/CreateApplication';
import ContactUsersApplication from 'domains/application/components/user/ContactUsersApplication';
import {withContext} from 'store/ContextStore';

class ApplicationPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			app: null,
			isAppOwner: false,
			loading: true,
		};

		this.refresh = this.refresh.bind(this);
	}

	async componentDidMount() {

		const response = await api.getCurrentUserApplications();
		const applications = response.data;
		applications.forEach(app => {
			app.authorities.forEach(authority => {
				if (authority.name === 'ROLE_APP_OWNER' && app.application.aspireCode === this.props.appId) {
					this.setState({
						isAppOwner: true
					});
				}
			});
		});

		let application = await api.getApplication(this.props.appId);
		this.setState({app: application.data, loading: false});
	}

	async refresh() {
		let application = await api.getApplication(this.props.appId);
		this.setState({app: application.data});
	}

	render() {

		if (this.state.loading) {
			return <Loading>Loading&nbsp;application...</Loading>;
		}

		const app = this.state.app;
		const isAdmin = hasAnyAuthority(Authorities.ROLE_ADMIN);

		return (
			<div>
				<div className="float-right">
					<div className="appCard-toolbar">
						{isAdmin && (
							<CreateApplication
								noLabel
								model={app}
								onConfirm={this.refresh}
								className="mr-1"
							/>
						)}
						{(this.state.isAppOwner || isAdmin) &&
                        <ContactUsersApplication aspireCode={this.props.appId} className="mr-1"/>
						}
					</div>
				</div>

				<div
					className="app-card-summary-title"
				>
					<h4>
						{app.name} ({app.aspireCode})
					</h4>
					<h5>{app.description}</h5>
				</div>
				<hr/>
				<div className="app-card-summary-container">
					<GridContainer>
						<GridRow>
							<GridCol sm="2">
								<b>Aspire Code</b>
							</GridCol>
							<GridCol sm="5">{app.aspireCode}</GridCol>
						</GridRow>
						<GridRow>
							<GridCol sm="2">
								<b>Description</b>
							</GridCol>
							<GridCol sm="5">{app.description}</GridCol>
						</GridRow>
						<GridRow>
							<GridCol sm="2">
								<b>Owner</b>
							</GridCol>
							<GridCol sm="5">{app.owner_name}</GridCol>
						</GridRow>
						<GridRow>
							<GridCol sm="2">
								<b>Owner email</b>
							</GridCol>
							<GridCol sm="5">{app.owner_email}</GridCol>
						</GridRow>
						<GridRow>
							<GridCol sm="2">
								<b>Service Line</b>
							</GridCol>
							<GridCol sm="5">{app.serviceLine}</GridCol>
						</GridRow>
						<GridRow>
							<GridCol sm="2">
								<b>Division</b>
							</GridCol>
							<GridCol sm="5">{app.division}</GridCol>
						</GridRow>
						<GridRow>
							<GridCol sm="2">
								<b>Request date</b>
							</GridCol>
							<GridCol sm="5">
								{app.request_date ? moment(app.request_date).toString() : ''}
							</GridCol>
						</GridRow>
						<GridRow>
							<GridCol sm="2">
								<b>Customer number</b>
							</GridCol>
							<GridCol sm="5">{app.customer_number}</GridCol>
						</GridRow>
						<GridRow>
							<GridCol sm="2">
								<b>Criticality</b>
							</GridCol>
							<GridCol sm="5">
								<span className={cx(`criticality-${app.criticality}`)}>
									{app.criticality}
								</span>
							</GridCol>
						</GridRow>
						<GridRow>
							<GridCol sm="2">
								<b>Code cpn</b>
							</GridCol>
							<GridCol sm="5">{app.code_cpn}</GridCol>
						</GridRow>
						<GridRow>
							<GridCol sm="2">
								<b>Category</b>
							</GridCol>
							<GridCol sm="5">{app.category ?? 'N/A'}</GridCol>
						</GridRow>
						<GridRow>
							<GridCol sm="2">
								<b>Internet Publishing</b>
							</GridCol>
							<GridCol sm="5">{app.internet_publishing ? 'Enabled' : 'Disabled'}</GridCol>
						</GridRow>
						<GridRow>
							<GridCol sm="2">
								<b>Classification</b>
							</GridCol>
							<GridCol sm="5">{app.export_control ? 'Export/Control' : 'Confidential Amber'}</GridCol>
						</GridRow>
					</GridContainer>
				</div>
			</div>
		);
	}
}

export default withContext(ApplicationPage);
