import {Route, Switch} from 'react-router-dom';
import React from 'react';
import PrivateRoute from 'components/shared/PrivateRoute';
import MyTeamsPage from 'domains/team/pages/MyTeamsPage';
import {Authorities} from 'utils/Authorities';
import NotAllowed, {NotAllowedUrl} from 'components/shared/NotAllowed';
import NotFound from 'components/shared/NotFound';
import LayoutForTeamSettings from './LayoutForTeamSettings';

const RoutesForTeam = () => {
	return (
		<Switch>
			<PrivateRoute
				exact
				path="/teams"
				component={MyTeamsPage}
				hasAnyAuthorities={[
					Authorities.ROLE_USER,
					Authorities.ROLE_ADMIN,
					Authorities.ROLE_ADMIN_READONLY
				]}
			/>
			<PrivateRoute
				path="/teams/:id*"
				component={LayoutForTeamSettings}
				omitCapturePageView
				hasAnyAuthorities={[
					Authorities.ROLE_USER,
					Authorities.ROLE_ADMIN,
					Authorities.ROLE_ADMIN_READONLY
				]}
			/>
			<Route exact path={NotAllowedUrl} component={NotAllowed}/>
			<Route component={NotFound}/>
		</Switch>
	);
};

export default RoutesForTeam;
