import AccountIcon from '@material-ui/icons/AccountBox';
import ImportIcon from '@material-ui/icons/VerticalAlignBottom';
import classnames from 'classnames';
import {Button, GridCol, GridContainer, GridRow, Loading} from 'horizon-components-react';
import React, {Component} from 'react';
import {Field} from 'react-final-form';
import api from '../../../../services/ApiServices';
import DialogForm from '../../../../components/shared/DialogForm';
import {DefaultTextInputAdapter, DefaultTypeaheadAdapter} from '../../../../components/shared/form/DefaultAdapter';
import './ImportAccount.scss';
import {withContext} from '../../../../store/ContextStore';
import DecommissionedTooltip from 'components/tooltips/DecommissionedTooltip';

const accountTypeOptions = [
	{
		label: 'PROD',
		value: 'NON_PROD'
	},
	{
		label: 'NON_PROD',
		value: 'NON_PROD'
	}
];

class ImportAccount extends Component {
	constructor(props) {
		super(props);
		this.formDialog = React.createRef();

		this.state = {
			loading: false,
			saving: false,
			options: []
		};
		this.toggle = this.toggle.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	async toggle(event) {
		event.preventDefault();
		this.formDialog.current.open();

		this.loadOrganizations();
	}

	async loadOrganizations() {
		try {
			this.setState({loading: true});
			const response = await api.getOrganizations();
			const organizations = response.data;
			const options = organizations.map(orga => {
				return {
					label: orga.name,
					value: orga.awsId
				};
			});
			options.sort((o1, o2) => {
				return o1.label.localeCompare(o2.label);
			});
			this.setState({
				options,
				loading: false
			});
		} catch {
			this.setState({loading: false});
		}
	}

	async handleSubmit(values) {
		try {
			this.setState({saving: true});

			const toSend = {
				AspireCode: this.props.appId,
				AccountId: values.AccountId,
				AccountName: values.AccountName,
				OrganizationAwsId: values.OrganizationAwsId.value,
				RootEmail: values.RootEmail,
				AccountType: values.AccountType.value,
				enterprise_case_id: values.enterprise_case_id,
				az_case_id: values.az_case_id
			};

			await api.importAccountIntoApplication(toSend);
			this.setState({saving: false});
		} catch (e) {
			this.setState({saving: false});
			throw e;
		}
	}

	render() {
		return (
			<span className={classnames(this.props.className)}>
				<DecommissionedTooltip>
					<Button
						disabled
						primary
						size="small"
						onClick={this.toggle}
						title="Import an account"
					>
						<ImportIcon/>
						<AccountIcon/>
					</Button>
				</DecommissionedTooltip>
				<DialogForm
					className="dialog-with-select-input import-account"
					ref={this.formDialog}
					dialogTitle="Import an account"
					onSubmit={this.handleSubmit}
					onConfirm={this.props.onConfirm}
				>
					{this.state.loading ? (
						<Loading>Loading&nbsp;organizations...</Loading>
					) : (
						<GridContainer>
							<GridRow>
								<GridCol>
									<Field
										name="AccountId"
										label="Account ID"
										required={true}
										disabled={this.state.saving}
										component={DefaultTextInputAdapter}
										placeholder="ID of the account"
										errorText="Account ID is required"
										autoFocus
									/>
								</GridCol>
								<GridCol>
									<Field
										name="AccountName"
										label="Account Name"
										required={true}
										disabled={this.state.saving}
										component={DefaultTextInputAdapter}
										errorText="Account Name is required"
										placeholder="Name of the account"
									/>
								</GridCol>
							</GridRow>
							<GridRow>
								<GridCol>
									<div className="mb-4">
										<label>
                                  Account Type<span className="mandatory">&nbsp;*</span>
										</label>
										<Field
											name="AccountType"
											required={true}
											disabled={this.state.saving}
											component={DefaultTypeaheadAdapter}
											options={accountTypeOptions}
										/>
									</div>
								</GridCol>
								<GridCol>
									<div className="mb-4">
										<label>
                                  Organization<span className="mandatory">&nbsp;*</span>
										</label>
										<Field
											name="OrganizationAwsId"
											required={true}
											disabled={this.state.saving}
											component={DefaultTypeaheadAdapter}
											options={this.state.options}
										/>
									</div>
								</GridCol>
							</GridRow>
							<Field
								name="RootEmail"
								label="Email"
								type="email"
								errorText="Correct email is required"
								required={true}
								disabled={this.state.saving}
								component={DefaultTextInputAdapter}
								placeholder="Email"
							/>
							<GridRow>
								<GridCol>
									<Field
										name="enterprise_case_id"
										label="Enterprise case ID"
										required={false}
										disabled={this.state.saving}
										component={DefaultTextInputAdapter}
										placeholder="Enterprise case identifier"
										errorText="Enterprise case ID is required"
									/>
								</GridCol>
								<GridCol>
									<Field
										name="az_case_id"
										label="AZ Case ID"
										required={false}
										disabled={this.state.saving}
										component={DefaultTextInputAdapter}
										placeholder="AZ Case identifier"
										errorText="AZ Case Identifier is required"
									/>
								</GridCol>
							</GridRow>
						</GridContainer>
					)}
				</DialogForm>
			</span>
		);
	}
}

export default withContext(ImportAccount);
