import React from 'react';
import RoutesForApplication from './RoutesForApplication';

const LayoutForApplication = () => {
	return (
		<RoutesForApplication/>
	);
};

export default LayoutForApplication;
