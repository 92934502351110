import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import ComplianceIcon from '@material-ui/icons/DoneAll';
import ComputerIcon from '@material-ui/icons/Computer';
import PlannedEventIcon from '@material-ui/icons/Event';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import './AccountMenu.scss';
import AppIcon from '@material-ui/icons/Settings';
import AccountsListOnMenu from 'domains/account/layout/AccountsListOnMenu';
import {withContext} from 'store/ContextStore';
import MachinesSecurityMaintenanceIcon from '../../../components/shared/machine-security-maintenance/MachinesSecurityMaintenanceIcon';

function AccountMenu(props) {

	const isSelected = relativePath => props.location.pathname === buildPath(relativePath);
	const buildPath = relativePath => `/applications/${props.appId}/accounts/${props.accountName}/${relativePath}`;

	return <div>
		<Paper>
			<MenuList>
				<AccountsListOnMenu/>

				<Link to={buildPath('planned-events')}>
					<MenuItem selected={isSelected('planned-events')}>
						<PlannedEventIcon className="icon-menu"/>
						<span>Planned events</span>
					</MenuItem>
				</Link>

				<Link to={buildPath('compliance')}>
					<MenuItem selected={isSelected('compliance')}>
						<ComplianceIcon className="icon-menu"/>
						<span>Compliance overview</span>
					</MenuItem>
				</Link>
				<Link to={buildPath('compliance-detail')}>
					<MenuItem selected={isSelected('compliance-detail')}>
						<ComplianceIcon className="icon-menu"/>
						<span>Compliance detail</span>
					</MenuItem>
				</Link>
				<Link to={buildPath('amis')}>
					<MenuItem selected={isSelected('amis')}>
						<ComputerIcon className="icon-menu"/>
						<span>AMIs</span>
					</MenuItem>
				</Link>
				<Link to={buildPath('maintenance-configuration')}>
					<MenuItem selected={isSelected('maintenance-configuration')}>
						<MachinesSecurityMaintenanceIcon className="icon-menu"/>
						<span>Machines security maintenance</span>
					</MenuItem>
				</Link>
			</MenuList>
		</Paper>

		<br/>
		<Paper>
			<MenuList>
				<Link to={`/applications/${props.appId}`}>
					<MenuItem>
						<AppIcon/>
						<span>Configure <span style={{fontWeight: 'bold'}}>{props.appId}</span></span>
					</MenuItem>
				</Link>
			</MenuList>
		</Paper>
	</div>;
}

export default withRouter(withContext(AccountMenu));
