import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import React from 'react';


function timeConverter(UNIX_timestamp) {
	const now = new Date(UNIX_timestamp);
	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	const year = now.getFullYear();
	const month = months[now.getMonth()];
	const date = now.getDate() > 9 ? now.getDate() : '0' + now.getDate();
	const hour = now.getHours() > 9 ? now.getHours() : '0' + now.getHours();
	const min = now.getMinutes() > 9 ? now.getMinutes() : '0' + now.getMinutes();
	const sec = now.getSeconds() > 9 ? now.getSeconds() : '0' + now.getSeconds();
	return date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
}

const RemediationRestoredEvent = ({event}) =>
	<ExpansionPanel disabled>
		<ExpansionPanelSummary>
			<Typography
				style={{fontSize: '15px'}}>Remediation <strong>RESTORED</strong> on {timeConverter(event.when)}<br/>by {event.who}
			</Typography>
		</ExpansionPanelSummary>
	</ExpansionPanel>;

export default RemediationRestoredEvent;
