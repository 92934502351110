import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import {
    Button,
    GridCol,
    GridRow
} from 'horizon-components-react';
import _ from 'lodash';
import React, {Component} from 'react';
import {Field} from 'react-final-form';
import api from '../../../../services/ApiServices';
import {withContext} from '../../../../store/ContextStore';
import {removeEmpty} from '../../../../utils/Utils';
import DialogForm from '../../../../components/shared/DialogForm';
import {
    DefaultCheckboxWithChangingLabelAdapter,
    DefaultTextAreaAdapter,
    DefaultTextInputAdapter,
    DefaultTypeaheadAdapter
} from '../../../../components/shared/form/DefaultAdapter';
import WarningIconWithPopover from './WarningIconWithPopover';
import classnames from 'classnames';
import {Checkbox} from '@material-ui/core';
import {AllowedRegions} from 'utils/Regions';
import DecommissionedTooltip from 'components/tooltips/DecommissionedTooltip';

const runningTimeFrameOptions = [
    {
        label: 'Not defined',
        value: 'UNDEFINED'
    },
    {
        label: 'Always',
        value: 'ALWAYS'
    },
    {
        label: 'Working time est',
        value: 'WORKINGTIME_EST'
    },
    {
        label: 'Working time get',
        value: 'WORKINGTIME_GET'
    }
];

const encryptionLevelOptions = [
    {
        label: 'LOW',
        value: 'LOW'
    },
    {
        label: 'HIGH',
        value: 'HIGH'
    }
];

const complianceLevelOptions = [
    {
        label: 'None',
        value: 'NONE'
    },
    {
        label: 'Notify',
        value: 'NOTIFY'
    },
    {
        label: 'Full',
        value: 'FULL'
    }
];

const prodCategoriesOptions = [
        {
            label: 'Customer',
            value: 'customer'
        },
        {
            label: 'Partner',
            value: 'partner'
        },
        {
            label: 'Platform',
            value: 'platform'
        }
    ];

const devCategoriesOptions = [
        {
            label: 'Dev',
            value: 'dev'
        }
    ];

const categoriesOptions = (process.env.REACT_APP_ENV==='prod') ? prodCategoriesOptions : devCategoriesOptions;


const criticalityOptions = [
    {
        label: 'Critical',
        value: 'CRITICAL'
    },
    {
        label: 'High',
        value: 'HIGH'
    },
    {
        label: 'Medium',
        value: 'MEDIUM'
    },
    {
        label: 'Low',
        value: 'LOW'
    }
];

const customerNumberOptions = [
    {
        label: 'S',
        value: 'S'
    },
    {
        label: 'M',
        value: 'M'
    },
    {
        label: 'L',
        value: 'L'
    },
    {
        label: 'XL',
        value: 'XL'
    },
    {
        label: 'XXL',
        value: 'XXL'
    },
];

class CreateApplication extends Component {
    constructor(props) {
        super(props);
        this.formDialog = React.createRef();

        this.edit = !!props.model;

        const creationModel = {
            aspireCode: null,
            criticality: 'LOW',
            domain: null,
            running_timeframe: runningTimeFrameOptions[1],
            serviceLine: null,
            owner_email: null,
            compliance_level: complianceLevelOptions[0],
            encryption_level: encryptionLevelOptions[0],
            category: categoriesOptions[0],
            delivery_date: null,
            name: null,
            division: null,
            customer_number: null,
            compliance_profile: null,
            request_date: null,
            description: null,
            code_cpn: null,
            owner_name: null,
            internet_publishing: false,
            export_control: false,
            mixed_fleet: false,
            allowed_region: AllowedRegions[0]
        };

        this.timeOutId = 0;

        this.state = {
            saving: false,
            model: this.edit ? this.props.model : creationModel
        };
        this.toggle = this.toggle.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAspireCode = this.handleAspireCode.bind(this);
    }

    toggle(event) {
        event.preventDefault();
        this.formDialog.current.open();
        if (this.edit) {
            const updateModel = _.cloneDeep(this.props.model);
            if (updateModel) {
                if(!updateModel.export_control){
                    this.disableExportControl = updateModel.export_control 
                }
                else{
                    this.disableExportControl = true;
                }
                if (updateModel.running_timeframe) {
                    updateModel.running_timeframe = runningTimeFrameOptions.find(
                        option => option.value === updateModel.running_timeframe
                    );
                }
                if (updateModel.compliance_level) {
                    updateModel.compliance_level = complianceLevelOptions.find(
                        option => option.value === updateModel.compliance_level
                    );
                }
                if (updateModel.encryption_level) {
                    updateModel.encryption_level = encryptionLevelOptions.find(
                        option => option.value === updateModel.encryption_level
                    );
                }
                if (updateModel.criticality) {
                    updateModel.criticality = criticalityOptions.find(
                        option => option.value === updateModel.criticality
                    );
                }
                if (updateModel.customer_number) {
                    updateModel.customer_number = customerNumberOptions.find(
                        option => option.value === updateModel.customer_number
                    );
                }
                if (updateModel.category) {
                    updateModel.category = categoriesOptions.find(
                        option => option.value === updateModel.category
                    );
                }
                if (updateModel.allowed_region) {
                    updateModel.allowed_region = AllowedRegions.find(
                        option => option.value === updateModel.allowed_region
                    );
                }
            }
            this.setState({
                model: updateModel
            });
        }
        else {
            const creationModel = {
                aspireCode: null,
                criticality: 'LOW',
                domain: null,
                running_timeframe: runningTimeFrameOptions[1],
                serviceLine: null,
                owner_email: null,
                compliance_level: complianceLevelOptions[0],
                encryption_level: encryptionLevelOptions[0],
                category: categoriesOptions[0],
                delivery_date: null,
                name: null,
                division: null,
                customer_number: null,
                compliance_profile: null,
                request_date: null,
                description: null,
                code_cpn: null,
                owner_name: null,
                internet_publishing: false,
                export_control: false,
                mixed_fleet: false,
                allowed_region: AllowedRegions[0]
            };
            this.setState({
                model: this.edit ? this.props.model : creationModel,
                saving: false
            })
        }
    }

    async handleSubmit(values) {
        const toSend = _.cloneDeep(values);

        removeEmpty(toSend);

        if (toSend.running_timeframe) {
            toSend.running_timeframe = toSend.running_timeframe.value;
        }
        if (toSend.encryption_level) {
            toSend.encryption_level = toSend.encryption_level.value;
        }
        if (toSend.compliance_level) {
            toSend.compliance_level = toSend.compliance_level.value;
        }
        if (toSend.criticality) {
            toSend.criticality = toSend.criticality.value;
        }
        if (toSend.customer_number) {
            toSend.customer_number = toSend.customer_number.value;
        }
        if (toSend.category) {
            toSend.category = toSend.category.value;
        }
        if (toSend.allowed_region) {
            toSend.allowed_region = toSend.allowed_region.value;
        }
        if (this.edit) {
            const response = await api.updateApplication(toSend);
            this.setState({
               model: response.data
            });
        } else {
            await api.createApplication(toSend);
        }
    }

    async handleAspireCode(event) {
        let aspireCode = event.trim()
        if (aspireCode) {
            try {
                const response = await api.getApplicationsInfo(aspireCode)

                const model = {
                    ...this.state.model,
                    owner_email: response.data['manager'],
                    serviceLine: response.data['oldServiceLine'],
                    division: response.data['businessUnit'],
                    description: response.data['shortDescription'],
                    owner_name: response.data['managerName']
                }
                this.setState({ model })
            }
            catch {
                const model = {
                    ...this.state.model,
                    owner_email: null,
                    serviceLine: null,
                    division: null,
                    description: null,
                    owner_name: null
                }
                this.setState({ model })
            }
        }
    }

    render() {
        const buttonLabel = (this.edit
            ? `Edit`
            : 'Create new application');
        return (
            <span className={classnames(this.props.className)}>
                <DecommissionedTooltip>
                    <Button
                        disabled
                        primary
                        size="small"
                        onClick={this.toggle}
                    >
                        {this.edit ? <EditIcon /> : <AddIcon />}
                        {buttonLabel}
                    </Button>
                </DecommissionedTooltip>
                <DialogForm
                    style={{ width: '40rem', maxWidth: 'initial' }}
                    ref={this.formDialog}
                    model={this.state.model}
                    dialogTitle={(this.edit
                        ? `Edit application ${this.state.model.name}`
                        : 'Create a new application')}
                    onSubmit={this.handleSubmit}
                    onConfirm={this.props.onConfirm}
                >
                    <GridRow>
                        <GridCol>
                            <div>
                                <h5>Application identity</h5>
                            </div>
                        </GridCol>
                    </GridRow>
                    <GridRow>
                        <GridCol>
                            <Field
                                name="name"
                                label="Name"
                                placeholder="Application name"
                                required={true}
                                errorText="name is required"
                                disabled={this.state.saving}
                                component={DefaultTextInputAdapter}
                                autoFocus
                                onChange={(e, newvalue) => this.setState({ model: { ...this.state.model, name: newvalue } })}
                            />
                        </GridCol>
                        <GridCol>
                            <Field
                                name="aspireCode"
                                label="Aspire Code"
                                placeholder="Aspire Code"
                                required={true}
                                errorText="Aspire code is required"
                                disabled={this.state.saving || this.edit}
                                component={DefaultTextInputAdapter}
                                onKeyUp={(e) => {
                                    if (this.timeOutId) clearTimeout(this.timeOutId);
                                    this.timeOutId = setTimeout(this.handleAspireCode, 800, e.target.value);
                                }}
                                onChange={(e, newvalue) => this.setState({ model: { ...this.state.model, aspireCode: newvalue } })}
                            />
                        </GridCol>
                    </GridRow>
                    <GridRow>
                        <GridCol>
                            <Field
                                name="description"
                                label="Description"
                                placeholder="A short description"
                                disabled={this.state.saving}
                                component={DefaultTextAreaAdapter}
                                maxLength={250}
                                onChange={(e, newvalue) => this.setState({ model: { ...this.state.model, description: newvalue } })}
                            />
                        </GridCol>
                    </GridRow>
                    <GridRow>
                        <GridCol>
                            <Field
                                name="division"
                                label="Division"
                                placeholder="Airbus Division"
                                disabled={this.state.saving}
                                component={DefaultTextInputAdapter}
                                onChange={(e, newvalue) => this.setState({ model: { ...this.state.model, division: newvalue } })}
                            />
                        </GridCol>
                        <GridCol>
                            <Field
                                name="serviceLine"
                                label="Service line"
                                placeholder="Service Line"
                                disabled={this.state.saving}
                                component={DefaultTextInputAdapter}
                                onChange={(e, newvalue) => this.setState({ model: { ...this.state.model, serviceLine: newvalue } })}
                            />
                        </GridCol>
                    </GridRow>
                    <GridRow>
                        <GridCol>
                            <Field
                                name="code_cpn"
                                label="CPN code"
                                required={true}
                                errorText="CPN Code is required"
                                disabled={this.state.saving}
                                component={DefaultTextInputAdapter}
                                onChange={(e, newvalue) => this.setState({ model: { ...this.state.model, code_cpn: newvalue } })}

                            />
                        </GridCol>
                        <GridCol>
                            <div>
                                <label>
                                    Category <span className="mandatory">*</span>
                                </label>
                                <Field
                                    name="category"
                                    required={true}
                                    errorText="Application category is required"
                                    disabled={this.state.saving}
                                    component={DefaultTypeaheadAdapter}
                                    options={categoriesOptions}
                                    onChange={(e) => this.setState({ model: { ...this.state.model, category: e } })}

                                />
                            </div>
                        </GridCol>
                    </GridRow>
                    <GridRow>
                        <GridCol>
                            <div>
                                <label>
                                    Region <span className="mandatory">*</span>
                                </label>
                                <Field
                                    name="allowed_region"
                                    required={true}
                                    errorText="Region is required"
                                    disabled={this.state.saving || this.edit}
                                    component={DefaultTypeaheadAdapter}
                                    options={AllowedRegions}
                                    onChange={(e) => this.setState({ model: { ...this.state.model, allowed_region: e } })}
                                />
                            </div>
                        </GridCol>
                    </GridRow>

                    <GridRow>
                        <GridCol>
                            <div>
                                <h5>Owner details</h5>
                            </div>
                        </GridCol>
                    </GridRow>
                    <GridRow>
                        <GridCol>
                            <Field
                                name="owner_name"
                                label="Owner"
                                placeholder="Owner name"
                                required={true}
                                errorText="Owner name is required"
                                disabled={this.state.saving}
                                component={DefaultTextInputAdapter}
                            />
                        </GridCol>
                        <GridCol>
                            <Field
                                style={{ minWidth: '200px' }}
                                name="owner_email"
                                label="Owner email"
                                placeholder="Owner email"
                                required={true}
                                errorText="Owner email is required"
                                disabled={this.state.saving}
                                component={DefaultTextInputAdapter}
                            />
                        </GridCol>
                    </GridRow>



                    <GridRow>
                        <GridCol>
                            <div>
                                <h5>Operational details</h5>
                            </div>
                        </GridCol>
                    </GridRow>
                    <GridRow>
                        <GridCol>
                            <div className="mb-4">
                                <label>
                                    Criticality <span className="mandatory">*</span>
                                </label>
                                <Field
                                    name="criticality"
                                    required={true}
                                    errorText="Criticality is required"
                                    disabled={this.state.saving}
                                    component={DefaultTypeaheadAdapter}
                                    options={criticalityOptions}
                                    onChange={(e) => this.setState({ model: { ...this.state.model, criticality: e } })}
                                />
                            </div>
                        </GridCol>
                        <GridCol>
                            <div className="mb-4">
                                <label>
                                    Estimated application size <span className="mandatory">*</span>
                                </label>
                                <Field
                                    name="customer_number"
                                    required={true}
                                    errorText="Customer number is required"
                                    disabled={this.state.saving}
                                    component={DefaultTypeaheadAdapter}
                                    options={customerNumberOptions}
                                    onChange={(e) => this.setState({ model: { ...this.state.model, customer_number: e } })}
                                />
                            </div>
                        </GridCol>
                    </GridRow>
                    <GridRow>
                        <GridCol>
                            <div>
                                <label>
                                    Running timeframe <span className="mandatory">*</span>
                                </label>
                                <Field
                                    name="running_timeframe"
                                    required={true}
                                    errorText="Running timeframe is required"
                                    disabled={this.state.saving}
                                    component={DefaultTypeaheadAdapter}
                                    options={runningTimeFrameOptions}
                                    onChange={(e) => this.setState({ model: { ...this.state.model, running_timeframe: e } })}
                                />
                            </div>
                        </GridCol>
                    </GridRow>


                    <GridRow>
                        <GridCol>
                            <div>
                                <h5>Security configuration</h5>
                            </div>
                        </GridCol>
                    </GridRow>
                    <GridRow>
                        {this.edit && (
                            <GridCol>
                                <div>
                                    <label>
                                        Encryption level <span className="mandatory">*</span>
                                    </label>
                                    <Field
                                        name="encryption_level"
                                        required={true}
                                        errorText="Encryption level is required"
                                        disabled={this.state.saving}
                                        component={DefaultTypeaheadAdapter}
                                        options={encryptionLevelOptions}
                                        onChange={(e) => this.setState({ model: { ...this.state.model, encryption_level: e } })}
                                    />
                                </div>
                            </GridCol>
                        )}
                        <GridCol>
                            <div>
                                <label>
                                    Compliance level <span className="mandatory">*</span>
                                </label>
                                <Field
                                    name="compliance_level"
                                    required={true}
                                    errorText="Compliance level is required"
                                    disabled={this.state.saving}
                                    component={DefaultTypeaheadAdapter}
                                    options={complianceLevelOptions}
                                    onChange={(e) => this.setState({ model: { ...this.state.model, compliance_level: e } })}
                                />
                            </div>
                        </GridCol>
                    </GridRow>




                    <GridRow>
                        <GridCol>
                            <div>
                                <h5>Flags</h5>
                            </div>
                        </GridCol>
                    </GridRow>
                    <GridRow>
                        <GridCol>
                            {/* Compensate the checkbox initial padding */}
                            <div style={{ paddingLeft: '12px' }}>
                                <Field
                                    disabledLabel={"Not internet publishing"}
                                    enabledLabel={"Internet publishing"}
                                    name="internet_publishing"
                                    disabled={this.state.saving}
                                    component={DefaultCheckboxWithChangingLabelAdapter}
                                />
                            </div>
                        </GridCol>
                        <GridCol>
                            {/* Compensate the checkbox initial padding */}
                            <div style={{ paddingLeft: '12px' }}>
                                <Field
                                    disabledLabel={"Not Export/Control classified"}
                                    enabledLabel={"Export/Control classified"}
                                    name="export_control"
                                    disabled={this.state.saving || (this.edit && this.disableExportControl)}
                                    component={DefaultCheckboxWithChangingLabelAdapter}
                                />
                            </div>
                        </GridCol>                        
                    </GridRow>
                    <GridRow>                        
                        <GridCol>
                            {/* Compensate the checkbox initial padding */}
                            <div style={{ paddingLeft: '12px' }}>
                                <Field
                                    disabledLabel={"Not mixed fleet"}
                                    enabledLabel={"Mixed fleet"}
                                    name="mixed_fleet"
                                    disabled={this.state.saving}
                                    component={DefaultCheckboxWithChangingLabelAdapter}
                                />
                            </div>
                        </GridCol>                                                
                    </GridRow>
                </DialogForm>
            </span>
        );
    }
}

export default withContext(CreateApplication);
