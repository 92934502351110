import React from 'react';
import {Field, Form} from 'react-final-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Send';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import {Strategy} from 'domains/security/component/RemediationStrategies/RemediationStrategySelector';
import {useDispatch} from 'react-redux';
import {changeStrategyModalClosed, loadAllRemediationAccounts} from 'state/actions';
import {REMEDIATION_STRATEGY_MODIFIED, REMEDIATION_STRATEGY_MODIFYING} from 'state/actions-types';
import {service} from 'domains/security/api/LambdaService';

const required = fieldName => value => (!value && `You must enter a ${fieldName}`);

const PartialValidation = ({account, rules, selectedRulesAreExactlyTheSameAsPrevious}) => {
	const dispatch = useDispatch();
	console.log(selectedRulesAreExactlyTheSameAsPrevious, account);
	const handleOnSubmit = async (values) => {
		dispatch({type: REMEDIATION_STRATEGY_MODIFYING});
		await service.partialRemediation(account.aws_account_id, rules.map(r => r.complianceId), values.customerRequest, values.securityRationale)
			.then(() => dispatch({type: REMEDIATION_STRATEGY_MODIFIED}))
			.then(() => dispatch(changeStrategyModalClosed()))
			.then(() => dispatch(loadAllRemediationAccounts()));
	};

	return <>
		<Alert variant="filled" severity="warning">
			<Typography>
                The remediation will be <strong>PARTIAL</strong> on the account {account.aws_account_name}. Following rules are exempted :
				<ul>{rules.map(rule => <li>{rule.complianceId}</li>)}</ul>
			</Typography>
		</Alert>
		<div style={{marginTop: '10px'}}>
			<Form
				onSubmit={handleOnSubmit}
				initialValues={{
					customerRequest: account.remediation_strategy !== Strategy.PARTIAL && selectedRulesAreExactlyTheSameAsPrevious ? account.customer_request : '',
					securityRationale: account.remediation_strategy !== Strategy.PARTIAL && selectedRulesAreExactlyTheSameAsPrevious ? account.security_rationale : ''
				}}
				render={({
					handleSubmit,
					submitting,
					valid
				}) => (
					<form onSubmit={handleSubmit}>
						<Field name="customerRequest" validate={required('customer request')}>
							{({input, meta}) => {
								const isNotValid = (meta.touched || meta.modified) && !meta.valid;
								return <div>
									<TextField {...input} type="text"
										variant='outlined'
										fullWidth
										helperText={isNotValid && meta.error}
										error={isNotValid}
										label='Customer request*'/>
								</div>;
							}}
						</Field>
						<Field name="securityRationale" validate={required('security rationale')}>
							{({input, meta}) => {
								const isNotValid = (meta.touched || meta.modified) && !meta.valid;
								return <div>
									<TextField style={{marginTop: '10px'}} fullWidth {...input} type="text"
										variant='outlined'
										helperText={isNotValid && meta.error}
										error={isNotValid}
										label='Security rationale*'/>
								</div>;
							}}
						</Field>
						<br/>
						<Button
							size={'large'}
							type='submit'
							disabled={!valid || submitting}
							variant="contained"
							color="primary"
							startIcon={<SaveIcon/>}>
                            Save
						</Button>
					</form>
				)}
			/>
		</div>
	</>;
};

export default PartialValidation;
