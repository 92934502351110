import { Select, MenuItem } from '@material-ui/core'
import AccountIcon from '@material-ui/icons/AccountBox';
import classnames from 'classnames';
import {Button, Loading} from 'horizon-components-react';
import React, {Component} from 'react';
import {Field} from 'react-final-form';
import api from '../../../../services/ApiServices';
import {withContext} from '../../../../store/ContextStore';
import DialogForm from '../../../../components/shared/DialogForm';
import './AttachApplicationToAnAccount.scss';
import DecommissionedTooltip from 'components/tooltips/DecommissionedTooltip';

const prodTypesOptions = [
	{
		label: 'PROD',
		value: 'PROD'
	},
	{
		label: 'NON_PROD',
		value: 'NON_PROD'
	}
];

const devTypesOptions = [
	{
		label: 'DEV',
		value: 'DEV'
	}
];

const typesOptions = (process.env.REACT_APP_ENV==='prod') ? prodTypesOptions : devTypesOptions;

const typeItems = typesOptions.map(type => {
	return (
		<MenuItem value={type}>
			{type.label}
		</MenuItem>
	)
})

class AttachApplicationToAnAccount extends Component {
	constructor(props) {
		super(props);
		this.formDialog = React.createRef();

		this.state = {
			loading: false,
			saving: false,
			options: [],
			accountRequired: null,
			selectedAccount: null,
			selectedType: typesOptions[0]
		};
		this.toggle = this.toggle.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleSelectAccount = this.handleSelectAccount.bind(this)
		this.handleSelectType = this.handleSelectType.bind(this)
	}

	async toggle(event) {
		event.preventDefault();
		this.formDialog.current.open();
		this.load();
	}

	async load() {
		try {
			this.setState({loading: true});
			const response = await api.getAllAccounts();
			const accounts = response.data.filter(account => {
				return account.state === 'READY';
			});
			const options = accounts.map(account => {
				return {
					label: account.name,
					value: account.name
				};
			});
			this.setState({
				options
			});
		} finally {
			this.setState({loading: false});
		}
	}

	handleSelectType(event) {
		this.setState({
			selectedType: event.target.value
		})
	}

	handleSelectAccount(event) {		
		this.setState({
			selectedAccount: event.target.value
		})
	}

	async handleSubmit(values) {
		try {
			const aspireCode = this.props.appId;
			if (!this.state.selectedAccount) {
				this.setState({
					accountRequired: 'Account is required'
				});
			} else {
				this.setState({
					accountRequired: null
				});
			}

			const accountName = this.state.selectedAccount.value
			const type = this.state.selectedType.value

			this.setState({saving: true});
			await api.attachApplicationToAccount(aspireCode, accountName, type);
		} finally {
			this.setState({saving: false});
		}
	}

	render() {
		const accountItems = this.state.options.map((option) => {
			return (
				<MenuItem value={option}>
					{option.value}
				</MenuItem>
			)
		})

		return (
			<span className={classnames(this.props.className)}>
				<DecommissionedTooltip>
					<Button
						disabled
						primary
						size="small"
						onClick={this.toggle}
						title="Attach an account"
					>
						<AccountIcon/>
					</Button>
				</DecommissionedTooltip>
				<DialogForm
					className="dialog-with-select-input"
					ref={this.formDialog}
					dialogTitle={`Attach an account to ${this.props.appId}`}
					onSubmit={this.handleSubmit}
					onConfirm={this.props.onConfirm}
				>
					{this.state.loading ? (
						<Loading>Loading&nbsp;accounts</Loading>
					) : (
              <>
                  <div className="mb-4">
                  	<label>
                          Account&nbsp;<span className="mandatory">*</span>
                  	</label>

                  	<span className="mandatory">{this.state.accountRequired}</span>
					
					<Select
						value={this.state.selectedAccount}
						onChange={this.handleSelectAccount}
						fullWidth
					>
						{accountItems}
					</Select>
                  </div>
                  <div>
                  	<label>
                          Type&nbsp;<span className="mandatory">*</span>
                  	</label>
                  	<Select
					  value={this.state.selectedType}
					  onChange={this.handleSelectType}
					>
						{typeItems}
					</Select>
                  </div>
              </>
					)}
				</DialogForm>
			</span>
		);
	}
}

export default withContext(AttachApplicationToAnAccount);
