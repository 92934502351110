import ComputerIcon from "@material-ui/icons/Computer";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Button } from "horizon-components-react";
import React, { Component } from "react";
import ApiTable from "../../../components/tables/ApiTable";
import api from "../../../services/ApiServices";
import { withContext } from "../../../store/ContextStore";
import { getColumnFilterDirection } from "utils/Utils";

class AdminAmisPage extends Component {
  constructor(props) {
    super(props);
    this.apiTable = React.createRef();

    this.columns = [
      {
        name: "Name",
        key: "name",
        filter: true,
        sort: getColumnFilterDirection("name", "admin-ami-grid"),
      },
      {
        name: "Os Type",
        key: "osType.name",
        filter: true,
        filterType: "checkedList",
        sort: getColumnFilterDirection("osType.name", "admin-ami-grid"),
      },
      {
        name: "Build Number",
        key: "buildNumber",
        type: "number",
        filter: true,
        sort: getColumnFilterDirection("buildNumber", "admin-ami-grid"),
      },
    ];

    this.onRefresh = this.onRefresh.bind(this);
    this.load = this.load.bind(this);
    this.delete = this.delete.bind(this);
  }

  onRefresh() {
    this.apiTable.current.load();
  }

  async load() {
    return await api.getAllAmis();
  }

  async delete(item) {
    return await api.deleteAmi(item.id);
  }

  render() {
    return (
      <>
        <div className="float-right">
          <Button primary size="small" onClick={this.onRefresh}>
            <RefreshIcon /> Refresh
          </Button>
        </div>
        <h2>
          <ComputerIcon className="icon-page" /> AMI Management
        </h2>
        <hr />
        <ApiTable
          id="admin-ami-grid"
          ref={this.apiTable}
          decommissioned={false}
          columns={this.columns}
          load={this.load}
          delete={this.delete}
        />
      </>
    );
  }
}

export default withContext(AdminAmisPage);
