import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ExportControlDisclaimerText from '../components/ExportControlDisclaimerText';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from '../../../services/ApiServices';

class ExportControlDisclaimerPage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			saving: false
		}
	}

	dismissDisclaimer() {
		this.props.onDismiss();
	}

	submitDisclaimerAgreement(isAccepted) {
		this.setState({saving: true})
		api.ExportControlDisclaimer.recordDisclaimerRecordForCurrentUser(isAccepted)
			.then(() => {
				this.dismissDisclaimer();
			})
			.finally(() => this.setState({saving: false}));
	}

	render() {
		return (
			<>
				<Grid container spacing={3} style={{width: '80%', margin: 'auto'}}>
					<Grid item xs={12}>
						<h2>Export Control disclaimer</h2>
					</Grid>
					<Grid item xs={12}>
						<h4>
							You have been added in a PCP application that uses Export Control classified data. Please read the following and accept or decline.
						</h4>
						<h4>
							You will not be able to access Export Control classified AWS accounts until you accept the following disclaimer.
						</h4>
					</Grid>
					<Grid item xs={12}>
						<div style={{fontSize: '14px'}}>
							<ExportControlDisclaimerText />
						</div>
					</Grid>
					{this.state.saving &&
					<Grid item xs={12}>
						<div style={{textAlign: 'center'}}> <CircularProgress /></div>
					</Grid>
					}

					{!this.state.saving &&
					<Grid item xs={12} style={{textAlign: "center"}}>
						<Button variant="contained"
								color="primary"
								style={{marginRight: '10px'}}
								onClick={() => this.submitDisclaimerAgreement(true)}>
							I have read and accept the terms & conditions
						</Button>
						<Button variant="contained"
								onClick={() => this.submitDisclaimerAgreement(false)}>
							I decline the terms & conditions
						</Button>
					</Grid>
					}
				</Grid>
			</>
		);
	}
}

export default ExportControlDisclaimerPage;
