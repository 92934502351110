import React, {useEffect, useState} from 'react';
import api from 'services/ApiServices';
import {withRouter} from 'react-router-dom';
import RulePage from 'domains/compliance/pages/RulePage';

function LoadComplianceRulePage({ruleId, context}) {
	const [loading, setLoading] = useState(true);
	const [rule, setRule] = useState([]);
	useEffect(() => {
		api.ComplianceRules.getComplianceRule(ruleId, context).then(response => {
			setLoading(false);
			setRule(response.data);
		});
	}, [ruleId, context]);

	return <RulePage loading={loading} rule={rule}/>;
}


function LoadComplianceRulePageWithRouter(props) {
	return <LoadComplianceRulePage ruleId={props.match.params.id} context={props.context}/>;
}

export default withRouter(LoadComplianceRulePageWithRouter);
