import React, { Component } from 'react';
import api from '../../../../services/ApiServices';
import 'react-big-scheduler/lib/css/style.css'
import moment from 'moment'
import PlannedEventsCalendar from '../../../../components/shared/planned-events/PlannedEventsCalendar';
import PlannedEventsCategoryDisplay from '../../../../components/shared/planned-events/PlannedEventsCategoryDisplay';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

class ApplicationPlannedEventsCalendar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			events: [],
			resources: [],
			eventsLoading: true,
			accountsLoading: true,
		};

	}

	transformAccountsInResources(accounts) {
		const resources = [
			{
				id: 'envPROD',
				name: 'Production',
				groupOnly: true
			},
			{
				id: 'envNON_PROD',
				name: 'Non-Production',
				groupOnly: true
			}
		];

		let shouldAddUndefinedCategory = false;
		accounts.map(account => {
			let env = account.type;
			if (env !== "PROD" && env !== "NON_PROD") {
				shouldAddUndefinedCategory = true;
				env = "UNDEFINED"
			}

			resources.push({
				id: account.accountId,
				name: account.name,
				parentId: `env${env}`
			})
		});

		if (shouldAddUndefinedCategory) {
			resources.push({
				id: 'envUNDEFINED',
				name: 'Undefined environment',
				groupOnly: true
			})
		}

		return resources
	}

	transformApplicationEvents(applicationEvents) {
		const transformedEvents = applicationEvents.map(applicationEvent => {
			const category = applicationEvent.event.category;
			let displayInfo = PlannedEventsCategoryDisplay.getDisplayInfo(category)

			// Compute if the event is starts in the past and is finished
			const startDate = moment(applicationEvent.event.startDate);
			const endDate = moment(applicationEvent.event.endDate);
			const now = moment();
			const isPastEvent = startDate < now && endDate < now;

			return {
				id: applicationEvent.event.id,
				start: startDate.format('YYYY-MM-DD HH:mm:ss'),
				end: endDate.format('YYYY-MM-DD HH:mm:ss'),
				isPastEvent,
				resourceId: applicationEvent.accountId,
				title: applicationEvent.event.title,
				description: applicationEvent.event.description,
				category: applicationEvent.event.category,
				displayInfo: displayInfo,
				bgColor: displayInfo.Color,
				rawEvent: applicationEvent
			};
		});
		transformedEvents.sort((a,b) => a.startDate - b.startDate);
		return transformedEvents;
	}

	reload() {
		// Load the account list for this application
		this.setState({ accountsLoading: true });
		api.getAccounts(this.props.aspireCode)
			.then((accounts) => {
				const resources = this.transformAccountsInResources(accounts);
				this.setState({
					resources
				})
			})
			.finally(() => {
				this.setState({ accountsLoading: false });
			});

		this.setState({ eventsLoading: true });
		api.PlannedEvents.getApplicationPlannedEvents(this.props.aspireCode)
			.then((response) => {
				const applicationEvents = response.data;
				const events = this.transformApplicationEvents(applicationEvents);
				this.setState({
					events
				})
			})
			.finally(() => {
				this.setState({ eventsLoading: false });
			});
	}

	componentDidMount() {
		this.reload();
	}

	render() {
		return <Grid container spacing={3}>
			<Grid item xs={12} sm={12}>
				{!(this.state.eventsLoading || this.state.accountsLoading) &&
					<h4>Calendar</h4>
				}
				<PlannedEventsCalendar loading={this.state.eventsLoading || this.state.accountsLoading}
									   events={this.state.events}
									   resources={this.state.resources}
									   resourceName={"Account"}/>
			</Grid>

			{!(this.state.eventsLoading || this.state.accountsLoading) &&
				<>
					<Grid item xs={12} sm={12}>
						<Divider />
					</Grid>
					<Grid item xs={12} sm={12}>
						<h4>Upcoming events</h4>
					</Grid>
					<Grid item xs={12} sm={12}>
						<TableContainer component={Paper} style={{maxHeight: '500px'}}>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										<TableCell style={{minWidth: '150px'}}>Affected account</TableCell>
										<TableCell>Start</TableCell>
										<TableCell>End</TableCell>
										<TableCell>Title</TableCell>
										<TableCell>Description</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{this.state.events.map((event) => {
										if (!event.isPastEvent) {
											return <TableRow key={event.name}>
												<TableCell component="th" scope="row">
													{event.rawEvent.accountName} <br/>
													{event.rawEvent.accountId}
												</TableCell>
												<TableCell>
													{moment(event.rawEvent.event.startDate).format('YYYY/MM/DD')}
												</TableCell>
												<TableCell>
													{moment(event.rawEvent.event.endDate).format('YYYY/MM/DD')}
												</TableCell>
												<TableCell>{event.title}</TableCell>
												<TableCell>{event.description}</TableCell>
											</TableRow>
										}
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</>
			}
		</Grid>
	}
}

export default ApplicationPlannedEventsCalendar;
