import {USER_KEY} from '../services/ApiServices';
import {Authorities} from '../utils/Authorities';
import _ from 'lodash';

/**
 * Check if user has any authorities past in parameters
 *
 * @param {*} requiredAuthoritesValues String or array of authority
 */
export const hasAnyAuthority = requiredAuthoritesValues => {
	if (!Array.isArray(requiredAuthoritesValues)) {
		requiredAuthoritesValues = [requiredAuthoritesValues];
	}
	const user = localStorage.getItem(USER_KEY)
		? JSON.parse(localStorage.getItem(USER_KEY))
		: null;
	if (user === null) {
		return false;
	}
	const authorities = user.authorities;
	if (authorities && authorities.length !== 0) {
		if (requiredAuthoritesValues.length === 0) {
			return true;
		}
		const requiredAuthoritiesKeys = requiredAuthoritesValues.map(authority => _.findKey(Authorities, a => a === authority));
		return requiredAuthoritiesKeys.some(requiredAuthority => authorities.includes(requiredAuthority));
	}
	return false;
};

export const hasAnyAuthorityOnThisApplication = (requiredAuthoritesValues, currentApplicationAuthorities) => {
	if (!Array.isArray(requiredAuthoritesValues)) {
		requiredAuthoritesValues = [requiredAuthoritesValues];
	}
	const requiredAuthoritiesKeys = requiredAuthoritesValues.map(authority => _.findKey(Authorities, a => a === authority));
	return requiredAuthoritiesKeys.some(requiredAuthority => currentApplicationAuthorities.includes(requiredAuthority));
};

/**
 * Get the logged in user email
 */
export const getCurrentUserEmail = () => {
	const user = localStorage.getItem(USER_KEY)
		? JSON.parse(localStorage.getItem(USER_KEY))
		: null;

	if (user) {
		return user.email;
	} else {
		return null;
	}
};

export const logout = () => {
	localStorage.clear();
	window.location.reload();
};
