import axios from 'axios';
import aws4 from 'aws4';
export const host = process.env.REACT_APP_API_HOST;
export const apiUrl = `https://${host}`;


export class AbstractApiService {

	async _makeRequest(request) {

		if (request.method === 'POST' || request.method === 'PUT') {
			request.body = JSON.stringify(request.data);
			request.headers = {
				'content-type': 'application/json'
			};
		}

		const signedRequest = this._sign(request);
		return axios(signedRequest);
	}

	_sign(request) {
		if (!request.method) {
			request.method = 'GET';
		}
		if (!request.url) {
			request.url = `${apiUrl}${request.path}`;
		}

		request.region = 'eu-west-1';
		request.service = 'execute-api';
		request.host = host;

		const credentials = JSON.parse(localStorage.getItem('credentials'));
		let signedRequest = aws4.sign(request, {
			accessKeyId: credentials.accessKeyId,
			secretAccessKey: credentials.secretKey,
			sessionToken: credentials.sessionToken
		});

		delete signedRequest.headers.Host;
		delete signedRequest.headers['Content-Length'];

		return signedRequest;
	}
}

export default AbstractApiService;