import React, { Component } from 'react';
import './ProgressBar.scss';

const Filler = props => {
	const classnames = `custom-filler ${props.className}`;
	return (
		<div className={classnames} style={{ width: `${props.percentage}%` }} />
	);
};

export default class ProgressBar extends Component {
	render() {
		const color = this.props.color ? this.props.color : 'primary';
		const fillerColor = `custom-filler-${color}`;
		const progressContentColor = `custom-progress-content custom-progress-content-${color}`;

		const innerHeight = this.props.height ? Number(this.props.height) : 20;
		const height = innerHeight + 4; //padding
		const fontSize = height - height * 0.1;

		let percentage = this.props.percentage;
		if (percentage > 100) {
			percentage = 100;
		}
		if (percentage < 0) {
			percentage = 0;
		}

		const percentageLabel = <div key="percentageLabel">{percentage}%</div>;

		let justifyContent = this.props.justify
			? this.props.justify
			: 'space-between';
		const content = [];
		if (this.props.children) {
			content.push(this.props.children);
		}
		content.push(percentageLabel);

		return (
			<div
				className="custom-progress-bar"
				style={{
					height: `${height}px`,
					fontSize: `${fontSize}px`,
					lineHeight: `${innerHeight}px`
				}}
			>
				<div
					className={progressContentColor}
					style={{ justifyContent: justifyContent }}
				>
					{content}
				</div>
				<Filler percentage={percentage} className={fillerColor} />
			</div>
		);
	}
}
