import React, {Component} from 'react';
import {withContext} from 'store/ContextStore';
import AccountIcon from '@material-ui/icons/AccountBox';
import Tooltip from '@material-ui/core/Tooltip';
import BackIcon from '@material-ui/icons/Reply';
import {Button} from 'horizon-components-react';
import { Redirect } from 'react-router-dom';

class AccountsListOnMenu extends Component {

	constructor(props) {
		super(props);
		this.state = {
			redirectToAccounts: false
		};
		this.changeAccount = this.changeAccount.bind(this);
	}

	changeAccount() {
		this.setState({
			redirectToAccounts: true
		});
	}

	render() {
		if (this.state.redirectToAccounts) {
			return <Redirect to={`/applications/${this.props.appId}/accounts`}/>;
		}

		return (
			<div>
				<div style={{display: 'flex', marginBottom: '3px'}}>
					<Tooltip title={'Click to choose another account for this application'}>
						<Button primary style={{width: '30px'}} onClick={this.changeAccount}>
							<BackIcon/>
						</Button>
					</Tooltip>
					<div style={{
						fontWeight: 'bold',
						fontSize: 'large',
						color: '#0085ad',
						textAlign: 'center',
						float: 'right',
						width: '100%'
					}}>
						<div className="applicationName">
							<AccountIcon/>
							{this.props.accountName}
						</div>
					</div>
				</div>
			</div>
		);
	}

}

export default withContext(AccountsListOnMenu);
