import {Route, Switch} from 'react-router-dom';
import React from 'react';
import BlueprintsPage from 'domains/application/pages/BlueprintsPage';
import PrivateRoute from 'components/shared/PrivateRoute';
import ApplicationPage from 'domains/application/pages/ApplicationPage';
import UsersPage from 'domains/application/pages/UsersPage';
import {Authorities} from 'utils/Authorities';
import UsersRoles from 'domains/application/components/user/UsersRoles';
import AccountsInApplicationPage from 'domains/application/pages/AccountsInApplicationPage';
import NotAllowed, {NotAllowedUrl} from 'components/shared/NotAllowed';
import NotFound from 'components/shared/NotFound';
import ApplicationPlannedEventsPage from '../pages/ApplicationPlannedEventsPage';

function RoutesForApplicationSettings() {
	return <Switch>
		<PrivateRoute
			exact
			path="/applications/:id"
			component={ApplicationPage}
			hasAnyAuthorities={[
				Authorities.ROLE_USER,
				Authorities.ROLE_ADMIN,
				Authorities.ROLE_ADMIN_READONLY
			]}
		/>
		<PrivateRoute
			exact
			path="/applications/:id/planned-events"
			component={ApplicationPlannedEventsPage}
			hasAnyAuthorities={[
				Authorities.ROLE_USER,
				Authorities.ROLE_ADMIN,
				Authorities.ROLE_ADMIN_READONLY
			]}
		/>
		<PrivateRoute
			exact
			path="/applications/:id/users"
			component={UsersPage}
			hasAnyAuthorities={[
				Authorities.ROLE_USER,
				Authorities.ROLE_ADMIN,
				Authorities.ROLE_ADMIN_READONLY
			]}
		/>
		<PrivateRoute
			exact
			path="/applications/:id/users/:userId"
			component={UsersRoles}
			hasAnyAuthorities={[
				Authorities.ROLE_USER,
				Authorities.ROLE_ADMIN,
				Authorities.ROLE_ADMIN_READONLY
			]}
		/>
		<PrivateRoute
			exact
			path="/applications/:id/accounts"
			component={AccountsInApplicationPage}
			hasAnyAuthorities={[
				Authorities.ROLE_USER,
				Authorities.ROLE_ADMIN,
				Authorities.ROLE_ADMIN_READONLY
			]}
		/>
		<PrivateRoute
			exact
			path="/applications/:id/blueprints"
			component={BlueprintsPage}
			hasAnyAuthorities={[
				Authorities.ROLE_USER,
				Authorities.ROLE_ADMIN,
				Authorities.ROLE_ADMIN_READONLY
			]}
		/>
		<Route exact path={NotAllowedUrl} component={NotAllowed}/>
		<Route component={NotFound}/>
	</Switch>;
}

export default RoutesForApplicationSettings;
