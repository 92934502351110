import {
	Checkbox,
	Loading,
	Pagination,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeaderCell,
	TableRow
} from 'horizon-components-react';
import _ from 'lodash';
import moment from 'moment';
import React, {Component} from 'react';
import ConfirmRemove from './ConfirmRemove';
import {emptyTable} from '../shared/Empty';
import {hasAnyAuthority} from '../../store/UserStore';
import {Authorities} from '../../utils/Authorities';
import './cells.scss';
import CheckboxFilter, {BOOLEAN_FILTER} from './filters/CheckboxFilter';
import CheckListFilter, {CHECKLIST_FILTER} from './filters/CheckedlistFilter';
import EmptyFilter from './filters/EmptyFilter';
import StringFilter from './filters/StringFilter';
import StringSorter, {NumberSorter} from './sorters/DefaultSorters';
import DecommissionedTooltip from 'components/tooltips/DecommissionedTooltip';

class ApiTable extends Component {
    defaultRowsPerPage = 10;
    dataPage = [];
    begin = 0;
    end = 0;

    constructor(props) {
    	super(props);

    	this.filterRefs = [];

    	let sort = {
    		column: null,
    		direction: null
    	};

    	this.props.columns.forEach(column => {
    		if (column.sort) {
    			sort = {
    				column,
    				direction: column.sort
    			};
    		}
    	});

    	if (this.props.initialSortColumn) {
    		sort.column = this.props.initialSortColumn;
		}
		if (this.props.initialSortDirection) {
			sort.direction = this.props.initialSortDirection;
		}

    	this.state = {
    		rowsPerPage: props.rowsPerPage
    			? props.rowsPerPage
    			: this.defaultRowsPerPage,
    		loading: false,
    		page: 1,
    		data: [],
			filter: props.filter,
    		filteredData: [],
    		sort
    	};

    	this.load = this.load.bind(this);
    	this.sortData = this.sortData.bind(this);
    	this.delete = this.delete.bind(this);
    	this.sort = this.sort.bind(this);
    	this.filterData = this.filterData.bind(this);
    	this.saveState = this.saveState.bind(this);
    }

    componentDidMount() {
    	this.load();
    }

    async load() {
    	this.setState({
    		loading: true
    	});
    	try {
    		console.log("Loading data")
    		const response = await this.props.load();
    		let filteredData = response.data;
    		if (this.state.sort.column) {
    			filteredData = this.sort(
    				response.data,
    				this.state.sort.column,
    				this.state.sort.direction
    			);
    		}

    		this.setState(
    			{
    				data: response.data,
    				filteredData,
    				loading: false
    			},
    			this.loadState
    		);
    	} catch {
    		this.setState({
    			loading: false
    		});
    	}
    }

    sortData(column) {
    	return () => {
    		const direction = this.state.sort.column &&
            this.state.sort.column.key === column.key &&
            this.state.sort.direction === 'asc'
    			? 'desc'
    			: 'asc';

    		const sortedFilteredData = this.sort(this.state.filteredData, column);
    		this.setState({
    			filteredData: sortedFilteredData,
    			sort: {
    				column,
    				direction
    			}
    		});
    	};
    }

    sort(data, column, direction) {
    	if (!direction) {
    		direction = this.state.sort.column &&
            this.state.sort.column.key === column.key &&
            this.state.sort.direction === 'asc'
    			? 'desc'
    			: 'asc';
    	}

    	let sorter;
    	switch (column.type) {
    	case 'number':
    		sorter = NumberSorter;
    		break;
    	default: {
    		sorter = StringSorter;
    	}
    	}
    	localStorage.setItem(this.props.id, JSON.stringify({column: column.key, direction: direction}));
    	return sorter(data, column.key, direction);
    }

    filterData() {
    	let filteredData = this.state.data;

    	this.filterRefs.forEach(ref => {
    		const filterInstance = ref.current;
    		if (filterInstance.hasValue()) {
    			filteredData = filterInstance.filter(filteredData);
    		}
    	});

    	this.setState({
    		filteredData,
    		page: 1
    	});
    	this.saveState();
    }

    async delete(itemId) {
    	await this.props.delete(itemId);
    	this.load();
    }

    hasDeleteAction() {
    	let readOnly;
    	if (this.props.isAllowedToDelete !== undefined) {
    		readOnly = !this.props.isAllowedToDelete;
    	} else {
    		readOnly = hasAnyAuthority(Authorities.ROLE_ADMIN_READONLY);
    	}

    	return this.props.delete && !readOnly;
    }

    hasAnyAction() {
    	return this.props.children || this.hasDeleteAction();
    }

    saveState() {
    	if (this.props.id) {
    		//save filter state
    		const state = {
    			filters: []
    		};
    		this.filterRefs.forEach(ref => {
    			const filterInstance = ref.current;
    			const filterState = filterInstance.saveFilter();
    			state.filters.push(filterState);
    		});

			localStorage.setItem(
    			`${this.props.id}-apiTable`,
    			JSON.stringify(state)
    		);
    	}
    }

    loadState() {
    	if (this.props.id) {
    		const configStr = localStorage.getItem(`${this.props.id}-apiTable`);
    		if (configStr) {
    			const config = JSON.parse(configStr);
    			if (config.filters) {
    				config.filters.forEach(filterConfig => {
    					this.filterRefs.forEach(ref => {
    						const filterInstance = ref.current;
    						if (
    							filterInstance.props.column.filter === true &&
                                filterInstance.props.column.key === filterConfig.column.key
    						) {
    							filterInstance.loadFilter(filterConfig.value);
    						}
    					});
    				});
    			}
    		}
    	}
    }

    renderItem(data) {
    	let cloned = null;
    	if (typeof this.props.dataAdapter === 'function') {
    		cloned = this.props.dataAdapter(data);
    	} else {
    		cloned = _.cloneDeep(data);
    	}

    	const deleteButton = (
    		<ConfirmRemove
				disabled={!this.hasDeleteAction() || this.props.decommissioned}
				model={cloned}
				buttonSize={this.props.deleteButtonSize}
				onConfirm={this.delete}
    			className="ml-1"
			>
                Are you sure you want to delete <b>{data.name}</b> ?
    		</ConfirmRemove>
    	);

    	const {children} = this.props;

    	let childrenWithProps = null;
    	if (children) {
    		childrenWithProps = React.Children.map(children, child =>
    			React.cloneElement(child, {model: cloned})
    		);
    	}

    	let key = data.id;
    	if (key == null) {
    		if (data.aspireCode) {
    			key = data.aspireCode;
    		} else if (data.name) {
    			key = data.name;
    		}
    	}

    	return (
    		<TableRow key={key}>
    			{this.props.columns.map(column => {
    				let value = _.get(data, column.key);
    				let className = '';
    				if (column.addClass) {
    					className = `cell_${column.key}_${value}`;
    				}

    				let renderedValue = value;
    				if (typeof column.cellRenderer === 'function') {
    					var render = column.cellRenderer;
    					renderedValue = render(value, column, data);
    				} else if (column.type === 'date') {
    					renderedValue = moment(value).toString();
    				} else if (column.type === 'boolean') {
    					if (value) {
    						renderedValue = <Checkbox defaultChecked label="&nbsp;"/>;
    					} else {
    						renderedValue = <Checkbox label="&nbsp;"/>;
    					}
    				}
    				return (
    					<TableCell key={column.key} className={className}>
    						{renderedValue}
    					</TableCell>
    				);
    			})}
    			{this.hasAnyAction() && (
    				<TableCell>
    					{childrenWithProps}
						<DecommissionedTooltip
							decommissioned={this.props.decommissioned}
							wrapper={children => children}
						>
							{deleteButton}
						</DecommissionedTooltip>
    				</TableCell>
    			)}
    		</TableRow>
    	);
    }

    renderFilterBar() {
    	this.filterRefs = [];

    	const filteredColumn = this.props.columns.filter(
    		column => column.filter === true
    	);

    	if (filteredColumn.length === 0) {
    		return null;
    	}

    	return (
    		<TableRow className="row-filter">
    			{this.props.columns.map(column => {
    				if (column.filter) {
    					const ref = React.createRef();
    					this.filterRefs.push(ref);
    					switch (column.filterType) {
    					case CHECKLIST_FILTER:
    						return (
    							<CheckListFilter
    								ref={ref}
    								key={column.name}
    								column={column}
    								updateData={this.filterData}
    								data={this.state.data}
    							/>
    						);
    					case BOOLEAN_FILTER:
    						return (
    							<CheckboxFilter
    								ref={ref}
    								key={column.name}
    								column={column}
    								updateData={this.filterData}
    							/>
    						);
    					default:
    						return (
    							<StringFilter
    								ref={ref}
    								key={column.name}
    								column={column}
    								updateData={this.filterData}
    							/>
    						);
    					}
    				} else {
    					return <EmptyFilter key={column.name}/>;
    				}
    			})}
    			{this.hasAnyAction() && <EmptyFilter/>}
    		</TableRow>
    	);
    }

    renderItems() {
    	let render = [];

    	this.begin = (this.state.page - 1) * this.state.rowsPerPage;
    	this.end = this.begin + this.state.rowsPerPage;
    	this.end =
            this.state.filteredData.length < this.end
            	? this.state.filteredData.length
            	: this.end;
    	this.end = Number(this.end);
    	this.dataPage = _.clone(this.state.filteredData).splice(
    		this.begin,
    		this.state.rowsPerPage
    	);

    	this.dataPage.forEach(aData => {
    		render.push(this.renderItem(aData));
    	});
    	if (render.length === 0) {
    		render.push(emptyTable(this.props.emptyTableMessage, this.props.columns.length + 1));
    	}
    	return render;
    }

    render() {
    	return (
    		<div className="api-table">
    			{this.state.loading ? (
    				<Loading>Loading&nbsp;...</Loading>
    			) : (
                    <>
                        <Table>
                        	<TableHead>
                        		<TableRow>
                        			{this.props.columns.map(column => {
                        				return (
                        					<TableHeaderCell
                        						key={column.key}
                        						sortDirection={this.state.sort.column && this.state.sort.column.key === column.key ? this.state.sort.direction : null}
                        						onSort={this.sortData(column)}
                        					>
                        						{column.name}
                        					</TableHeaderCell>
                        				);
                        			})}
                        			{this.hasAnyAction() && (
                        				<TableHeaderCell
                        					className="actions-cell"
                        					style={{
                        						width: this.props.actionsWidth,
                        						minWidth: this.props.actionsMinWidth
                        					}}
                        				>
                                            Actions
                        				</TableHeaderCell>
                        			)}
                        		</TableRow>
                        	</TableHead>
                        	<TableBody>
                        		{this.renderFilterBar()}
                        		{this.renderItems()}
                        	</TableBody>
                        </Table>
                        {this.state.filteredData.length > 0 && (
                        	<Pagination
                        		page={this.state.page}
                        		count={this.state.filteredData.length}
                        		rowsPerPage={this.state.rowsPerPage}
                        		onPageChange={(_, page) => this.setState({page})}
                        		onRowsPerPageChange={ev =>
                        			this.setState({rowsPerPage: ev.target.value})
                        		}
                        		legend={`Showing ${this.begin + 1} to ${this.end} of ${
                        			this.state.filteredData.length
                        		} entries`}
                        	/>
                        )}
                    </>
    			)}
    		</div>
    	);
    }
}

const apiTableWithRef = React.forwardRef((props, ref) => (
	<ApiTable ref={ref} {...props} />
));

export default apiTableWithRef;
