import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import classnames from 'classnames';
import {
	Button,
	GridCol,
	GridContainer,
	GridRow,
	Loading
} from 'horizon-components-react';
import _ from 'lodash';
import React, { Component } from 'react';
import { Field } from 'react-final-form';
import api from '../../../../services/ApiServices';
import DialogForm from '../../../../components/shared/DialogForm';
import {
	DefaultTextAreaAdapter,
	DefaultTextInputAdapter,
	DefaultTypeaheadAdapter
} from '../../../../components/shared/form/DefaultAdapter';
import './CreateRule.scss';
import {RulesStatus} from '../../../../utils/StatusOfAComplianceRule';
import {Switch} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const severitiesOptions = [
	{
		label: 'LOW',
		value: 'LOW'
	},
	{
		label: 'MEDIUM',
		value: 'MEDIUM'
	},
	{
		label: 'HIGH',
		value: 'HIGH'
	},
	{
		label: 'CRITICAL',
		value: 'CRITICAL'
	}
];

class CreateRule extends Component {
	constructor(props) {
		super(props);
		this.formDialog = React.createRef();
		this.edit = !!props.model;

		const creationModel = {
			severity: severitiesOptions[0],
			status: RulesStatus[0]
		};

		const updateModel = props.model;
		if (updateModel) {
			if (updateModel.severity) {
				updateModel.severity = severitiesOptions.find(
					option => option.value === updateModel.severity
				);
			}
			if (updateModel.status) {
				updateModel.status = RulesStatus.find(
					option => option.value === updateModel.status
				);
			}
		}

		this.state = {
			saving: false,
			loading: false,
			hasAutomatedRemediation: false,
			awsOptions: [],
			model: this.edit ? updateModel : creationModel
		};
		this.toggle = this.toggle.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	toggle(event) {
		event.preventDefault();
		this.formDialog.current.open();
	}

	async handleSubmit(values) {
		const toSend = _.cloneDeep(values);
		toSend.severity = toSend.severity.value;
		toSend.status = toSend.status.value;
		toSend.hasAutomatedRemediation = this.state.hasAutomatedRemediation;

		if (this.edit) {
			await api.ComplianceRules.updateComplianceRule(toSend);
		} else {
			await api.ComplianceRules.createComplianceRule(toSend);
		}
	}

	render() {
		let buttonLabel = 'Add';
		if (this.edit) {
			buttonLabel = 'Edit';
		}

		if (this.props.noLabel) {
			buttonLabel = '';
		}

		let buttonIcon = <AddIcon />;
		if (this.edit) {
			buttonIcon = <EditIcon />;
		}
		if (this.props.icon) {
			buttonIcon = this.props.icon;
		}

		return (
			<span className={classnames(this.props.className)}>
				<Button
					primary
					id='create-rule-button'
					disabled={this.props.disabled}
					title={this.props.title}
					onClick={this.toggle}
				>
					{buttonIcon}
					{buttonLabel}
				</Button>

				<DialogForm
					className="create-compliance-rule-dialog dialog-with-select-input"
					ref={this.formDialog}
					model={this.state.model}
					dialogTitle="Create a rule"
					onSubmit={this.handleSubmit}
					onConfirm={this.props.onConfirm}
				>
					{this.state.loading ? (
						<Loading>Loading&nbsp;services</Loading>
					) : (
						<GridContainer>
							<GridRow>
								<GridCol>
									<Field
										name="complianceId"
										component={DefaultTextInputAdapter}
										label="Compliance ID"
										placeholder="Compliance identifier"
										required={true}
										errorText="Compliance id is required"
										disabled={this.state.saving}
										autoFocus
									/>
								</GridCol>
								<GridCol>
									<div className="mb-4">
										<label>
                      Service<span className="mandatory">&nbsp;*</span>
										</label>
										<Field
											name="service"
											placeholder="Service"
											required={true}
											errorText="Service Name is required"
											disabled={this.state.saving}
											component={DefaultTextInputAdapter}
										/>
									</div>
								</GridCol>
							</GridRow>
							<GridRow>
								<GridCol>
									<Field
										name="resourceType"
										component={DefaultTextInputAdapter}
										label="Type"
										placeholder="AWS::Lambda::Function"
										required={true}
										errorText="Type is required"
										disabled={this.state.saving}
									/>
								</GridCol>
								<GridCol>
									<Field
										name="riskType"
										component={DefaultTextInputAdapter}
										label="Risk Type"
										placeholder="Confidentiality, Integrity, Security..."
										disabled={this.state.saving}
									/>
								</GridCol>
							</GridRow>
							<GridRow>
								<GridCol>
									<Field
										name="description"
										label="Description"
										placeholder="Ensure that XXXX resources have..."
										disabled={this.state.saving}
										component={DefaultTextAreaAdapter}
									/>
								</GridCol>
								<GridCol>
									<Field
										name="rationale"
										label="Rationale"
										placeholder="The non-respect of that rule implies..."
										disabled={this.state.saving}
										component={DefaultTextAreaAdapter}
									/>
								</GridCol>
							</GridRow>
							<GridRow>
								<GridCol sm={6}>
									<div className="mb-4">
										<label>
                      Severity<span className="mandatory">&nbsp;*</span>
										</label>
										<Field
											name="severity"
											placeholder="Select a severity level"
											required={true}
											errorText="Severity is required"
											disabled={this.state.saving}
											options={severitiesOptions}
											component={DefaultTypeaheadAdapter}
											maxMenuHeight={200}
										/>
									</div>
								</GridCol>
								<GridCol sm={6}>
									<div className="mb-4">
										<label>
                      Status<span className="mandatory">&nbsp;*</span>
										</label>
										<Field
											name="status"
											placeholder="Select a status"
											required={true}
											errorText="Status is required"
											disabled={this.state.saving}
											options={RulesStatus}
											component={DefaultTypeaheadAdapter}
											maxMenuHeight={200}
										/>
									</div>
								</GridCol>
								<GridCol>
									<Field
										name="remarks"
										label="Remarks"
										placeholder="This rule has been rejected because..."
										disabled={this.state.saving}
										component={DefaultTextAreaAdapter}
									/>
								</GridCol>
							</GridRow>
							<GridRow>
								<GridCol>
									<FormControlLabel
										control={<Switch onChange={((event, checked) => this.setState({"hasAutomatedRemediation": checked}))} />}
										label="Remediation is automated"/>
								</GridCol>
							</GridRow>
							<GridRow>
								<GridCol>
									<Field
										name="remediation"
										label="Remediation procedure"
										disabled={this.state.saving}
										component={DefaultTextAreaAdapter}
									/>
								</GridCol>
							</GridRow>
						</GridContainer>
					)}
				</DialogForm>
			</span>
		);
	}
}

export default CreateRule;
