import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './TeamCard.scss';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import AppIcon from '@material-ui/icons/Web';
import {withContext} from '../../../../store/ContextStore';

class TeamCard extends Component {
	render() {
		const encodedTeamId = encodeURIComponent(this.props.team.id);
		return (
			<Card>
				<Link to={`/teams/${encodedTeamId}`}>
					<Button className='teamButton'>
						<AppIcon className='teamIcon'/>
						<CardContent className="text-center">
							<div className='teamContent'>
								<div className='teamName'>{this.props.team.name}</div>
							</div>
						</CardContent>
					</Button>
				</Link>
			</Card>
		);
	}
}

export default withContext(TeamCard);
