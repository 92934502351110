import PeopleIcon from '@material-ui/icons/People';
import React, {Component} from 'react';
import CreateOrEditUser from '../components/user/CreateOrEditUserIntoPcp';
import ApiTable from '../../../components/tables/ApiTable';
import api from '../../../services/ApiServices';
import {hasAnyAuthority} from '../../../store/UserStore';
import {Authorities} from '../../../utils/Authorities';
import ContactAllUsers from '../components/user/ContactAllUsers';
import {getColumnFilterDirection} from 'utils/Utils';
import UserViews from 'domains/administration/pages/UserViews';

class AdminUsersPage extends Component {
    constructor(props) {
        super(props);
        this.apiTable = React.createRef();

        this.columns = [
            {
                name: '',
                key: '',
                cellRenderer: (undefinedVariable, column, data) => <UserViews userUUID={encodeURIComponent(data.id)}/>
            },
            {
                name: 'Email',
                key: 'email',
                filter: true,
                sort: getColumnFilterDirection('email', 'admin-users-grid')
            },
            {
                name: 'Authorities',
                key: 'authorities',
                cellRenderer: authorities => {
                    return this.renderAuthorities(authorities);
                },
                filter: true,
                filterType: 'checkedList',
                sort: getColumnFilterDirection('authorities', 'admin-users-grid')
            }
        ];

        this.load = this.load.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    renderAuthorities(authorities) {
        const result = [];
        authorities.forEach(authority => {
            result.push(this.renderAuthority(authority));
        });
        return result;
    }

    renderAuthority(authority) {
        return <div key={authority}>{authority}</div>;
    }


    async load() {
        return await api.getAllUsers();
    }

    async delete(user) {
        await api.deleteUser(user.id);
        this.load();
    }

    refresh() {
        this.apiTable.current.load();
    }

    render() {
        const readOnly = hasAnyAuthority(Authorities.ROLE_ADMIN_READONLY);

        return (
            <>
                <div className="float-right">
                    {!readOnly && (
                        <CreateOrEditUser admin onConfirm={this.refresh} className="mr-1"/>
                    )}
                    <ContactAllUsers/>
                </div>
                <h2>
                    <PeopleIcon className="icon-page"/>
                    User Management
                </h2>
                <hr/>
                <ApiTable
                    id="admin-users-grid"
                    ref={this.apiTable}
                    columns={this.columns}
                    load={this.load}
                    delete={this.delete}
                    decommissioned={true}
                >
                    {!readOnly && <CreateOrEditUser admin onConfirm={this.refresh}/>}
                </ApiTable>
            </>
        );
    }
}

export default AdminUsersPage;
