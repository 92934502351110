import {ApiServicesClient} from 'services/ApiServicesClient';

export const CREDENTIALS_KEY = 'credentials';
export const USER_KEY = 'user';
export const EXPIRATION_DATE_KEY = 'expirationDate';
export const DEVICES = 'devices';
export const STATE_TOKEN = 'stateToken';

let api = new ApiServicesClient();
export default api;
