import React, {useEffect, useState} from 'react';
import {CardContent} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import {Button} from 'horizon-components-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from 'services/ApiServices';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';


const circularLoaderInButtonStyle = makeStyles(() => ({
    colorPrimary: {
        color: '#FFFFFF'
    }
}));

const MachinesSecurityMaintenanceCampaignCreation = ({isEmergency, onCreate}) => {
    const [loading, setLoading] = useState(true);
    const [creating, setCreating] = useState(false);
    const [campaignSimulation, setCampaignSimulation] = useState(null);

    const circularLoaderStyle = circularLoaderInButtonStyle();

    const campaignConfigurationByType = {
        standard: {
            name: "Standard",
            campaignDurationInDays: 8*7
        },
        emergency: {
            name: "Emergency",
            campaignDurationInDays: 9
        }
    }

    const [config] = useState(campaignConfigurationByType[isEmergency ? "emergency" : "standard"]);

    const buildTitle = () => {
        return `${config.name} (${config.campaignDurationInDays} days)`
    }

    const createMachinesSecurityMaintenanceCampaign = (isEmergency) => {
        setCreating(true);
        api.MachinesSecurityMaintenance.createMachinesSecurityMaintenanceCampaign(isEmergency, false)
            .then(() => onCreate())
            .finally(() => setCreating(false));
    }

    useEffect(() => {
        api.MachinesSecurityMaintenance.createMachinesSecurityMaintenanceCampaign(isEmergency, true)
            .then(r => r.data)
            .then(data => {
                setCampaignSimulation(data);
            })
            .finally(() => setLoading(false));
    }, [isEmergency])

    return <Card>
        <CardHeader title={buildTitle()} />
        <CardContent>
            <p>
                Here is a simulation if you create the campaign right now
            </p>
            {loading &&
            <div style={{textAlign: 'center'}}><CircularProgress/></div>
            }
            {!loading &&
                <>
                    {campaignSimulation &&
                        <TableContainer component={Paper} style={{marginBottom: '1rem'}}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Env</TableCell>
                                        <TableCell>Start date</TableCell>
                                        <TableCell>End date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Prod</TableCell>
                                        <TableCell>{campaignSimulation.prod.start_date}</TableCell>
                                        <TableCell>{campaignSimulation.prod.end_date}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Non Prod</TableCell>
                                        <TableCell>{campaignSimulation.non_prod.start_date}</TableCell>
                                        <TableCell>{campaignSimulation.non_prod.end_date}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Others</TableCell>
                                        <TableCell>{campaignSimulation.undefined.start_date}</TableCell>
                                        <TableCell>{campaignSimulation.undefined.end_date}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {isEmergency &&
                        <p style={{textAlign: 'center'}}>
                            <Button primary
                                    size="medium"
                                    disabled={creating}
                                    onClick={() => createMachinesSecurityMaintenanceCampaign(true)}>
                                {creating &&
                                    <div style={{textAlign: 'center'}}><CircularProgress className={circularLoaderStyle.colorPrimary} size={20} /></div>
                                }
                                {!creating &&
                                    <div>Create</div>
                                }
                            </Button>
                        </p>
                    }
                    {!isEmergency &&
                    <p style={{textAlign: 'center', minHeight: '40px'}}>
                        Standard campaigns are created automatically and should never be created by a human.
                    </p>
                    }
                </>
            }
        </CardContent>
    </Card>;
};
export default MachinesSecurityMaintenanceCampaignCreation;
