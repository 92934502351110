import { TableCell, TextInput } from 'horizon-components-react';
import _ from 'lodash';
import React, { Component } from 'react';

class StringFilter extends Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.filter = this.filter.bind(this);
		this.state = {
			value: ''
		};
	}

	filter(data) {
		const column = this.props.column;
		const filter = this.state.value.toLowerCase();
		return data.filter(aData => {
			let value = _.get(aData, column.key);
			if (typeof value === 'string') {
				value = value.toLocaleLowerCase();
				if (value.includes(filter)) {
					return true;
				}
			}
			return false;
		});
	}

	onChange(e, value) {
		this.setState(
			{
				value: value.toLocaleLowerCase()
			},
			this.props.updateData
		);
	}

	hasValue() {
		return this.state.value.length > 0;
	}

	saveFilter() {
		return {
			column: this.props.column,
			value: this.state.value
		};
	}

	loadFilter(value) {
		this.setState(
			{
				value
			},
			this.props.updateData
		);
	}

	render() {
		const column = this.props.column;
		return (
			<TableCell className="cell-filter" key={column.name}>
				<TextInput
					value={this.state.value}
					onChange={this.onChange}
					placeholder="Filter..."
				/>
			</TableCell>
		);
	}
}

const StringFilterWithRef = React.forwardRef((props, ref) => (
	<StringFilter ref={ref} {...props} />
));

export default StringFilterWithRef;
